import React, { useState, useEffect } from "react";
import "./Filter.scss";
import { Card, Checkbox, Collapse, Rate, Slider, Skeleton,Input } from "antd";

const { Search } = Input;

const { Panel } = Collapse;

const Filter = ({ count, data, setListOfHotels, isHotelSearchLoad, UniqueCities }) => {
  const [priceRange, setPriceRange] = useState([]);
  const [filters, setFilters] = useState({});
  const listOfHotels = data;
  const [resultCount, setResultCount] = useState(count);
  const [selectedCities, setSelectedCities] = useState([]);

  const filtersObj = {
    amenities: [],
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    property: [],
    roomAmenities: [],
    rating: [],
    amendment: [],
    tdRating: [],
  };

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("list-container")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    for (let i = 0; i < listOfHotels.length; i++) {
      let HotelTotPrice = Math.floor(Number(listOfHotels[i].hotelMinPrice));

      if (i === 0) {
        filtersObj.price.minPrice = HotelTotPrice;
      }
      let minFare = HotelTotPrice;
      if (HotelTotPrice > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
          HotelTotPrice;
      }
      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;

      //Setting Amenities Filters
      // const { hotelFacility } = listOfHotels[i];
      // if (hotelFacility && hotelFacility.length > 1) {
      //   hotelFacility.map((facility) => {
      //     checkIfExist(filtersObj.amenities, facility) &&
      //       filtersObj.amenities.push({
      //         id: facility,
      //         label: facility,
      //         isChecked: false,
      //       });
      //   });
      // }

      //Setting Properties Filters
      const { propertyName } = listOfHotels[i];
      checkIfExist(filtersObj.property, propertyName) &&
        filtersObj.property.push({
          id: propertyName,
          label: propertyName,
          isChecked: false,
        });

      //Setting Room Amenities Filters
      const { roomAmenities } = listOfHotels[i];
      if (roomAmenities && roomAmenities.length > 1) {
        roomAmenities.map((facility) => {
          checkIfExist(filtersObj.roomAmenities, facility) &&
            filtersObj.roomAmenities.push({
              id: facility,
              label: facility,
              isChecked: false,
            });
        });
      }
      const { isAmendmentAllowed } = listOfHotels[i];
      if (isAmendmentAllowed) {
        filtersObj.amendment[0] = {
          id: 1,
          label: "Allowed",
          isChecked: false,
        };
      } else {
        filtersObj.amendment[1] = {
          id: 0,
          label: "Not Allowed",
          isChecked: false,
        };
      }

      //Setting Star Rating Filters
      const { starRating } = listOfHotels[i];
      if (starRating)
        checkIfExist(filtersObj.rating, starRating) &&
          filtersObj.rating.push({
            id: starRating,
            label: `${starRating} Star`,
            isChecked: false,
          });

      const { tripAdvisorRating } = listOfHotels[i];
      if (tripAdvisorRating)
        checkIfExist(filtersObj.tdRating, tripAdvisorRating) &&
          filtersObj.tdRating.push({
            id: tripAdvisorRating,
            label: `${tripAdvisorRating}`,
            isChecked: false,
          });
    }

    //Sort the Rating Filter
    filtersObj.rating.sort((a, b) => a.id - b.id);

    //Sort the tripAdvisorRating Filter by karthik 25-December-2023
    filtersObj.tdRating.sort((a, b) => a.id - b.id);

    setFilters(filtersObj);
    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;
    const propertyChecked = checkedFilters("property");
    const amendment = checkedFilters("amendment");
    const ratingChecked = checkedFilters("rating");
    const tdRatingChecked = checkedFilters("tdRating");

    let data = listOfHotels
      .map((hotel) => {
        let isVisible = true;
        const startingFare = Math.floor(Number(hotel.hotelMinPrice));
        if (
          !(
            startingFare >= filters.price.minPrice &&
            startingFare <= filters.price.maxPrice
          )
        ) {
          isVisible = false;
        }

        // const amenitiesChecked = checkedFilters("amenities");
        // if (amenitiesChecked.length) {
        //   const { hotelFacility } = hotel;
        //   if (hotelFacility) {
        //     if (
        //       !amenitiesChecked
        //         .map((amenity) => amenity.id)
        //         .some((hotelAmenity) => hotelFacility.includes(hotelAmenity))
        //     ) {
        //       isVisible = false;
        //     }
        //   } else isVisible = false;
        // }

        if (
          propertyChecked.length &&
          !propertyChecked.map((prop) => prop.id).includes(hotel.propertyName)
        ) {
          isVisible = false;
        }

        let id = hotel.isAmendmentAllowed ? 1 : 0;
        if (
          amendment.length &&
          !amendment.map((prop) => prop.id).includes(id)
        ) {
          isVisible = false;
        }

        if (
          ratingChecked.length &&
          !ratingChecked.map((ratg) => ratg.id).includes(hotel.starRating)
        ) {
          isVisible = false;
        }

        if (
          tdRatingChecked.length &&
          !tdRatingChecked
            .map((ratg) => ratg.id)
            .includes(hotel.tripAdvisorRating)
        ) {
          isVisible = false;
        }

        isVisible && visibleCount++;

        return { ...hotel, isVisible: isVisible };
      })
      .filter((item) => item.isVisible);

    setListOfHotels(data);
    setResultCount(visibleCount);
  };

  useEffect(() => {
    setDynamicFilters();
  }, [count, isHotelSearchLoad]);

  useEffect(() => {
    if (!isHotelSearchLoad) {
      Object.keys(filters).length && applyFilters();
    }
  }, [filters, isHotelSearchLoad]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );
  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  function getCount(key, val) {
    switch (key) {
      // case "amenities":
      //   return listOfHotels.filter((item) => {
      //     if (item.hotelFacility) {
      //       if (item.hotelFacility.includes(val)) {
      //         return item;
      //       }
      //     }
      //   }).length;
      case "tripRating":
        return listOfHotels.filter((item) => item.tripAdvisorRating === val)
          .length;
      case "starRating":
        return listOfHotels.filter((item) => item.starRating === val).length;
      case "amendment":
        val = val === 1 ? true : false;
        return listOfHotels.filter((item) => item.isAmendmentAllowed === val)
          .length;
      default:
        return 0;
    }
  }
  // hotels filters depend on city by karthi 27-December-2023
  const handleCheckboxChange = (event) => {
    const selectedCity = event.target.value;
    let updatedSelectedCities = [...selectedCities];
    let filterData;
  
    if (event.target.checked) {
      updatedSelectedCities.push(selectedCity);
    } else {
      updatedSelectedCities = updatedSelectedCities.filter(
        (city) => city !== selectedCity
      );
    }
    setSelectedCities(updatedSelectedCities);
  
    if (updatedSelectedCities.length > 0) {
      filterData = listOfHotels.filter((hotel) =>
        updatedSelectedCities.includes(hotel.addresses[0].cityName)
      );
    } else {
      filterData = listOfHotels; // If no cities selected, display all hotels
    }
    setListOfHotels(filterData);
  };
  
  

  return (
    <>
      <div className="side-bar e-hide">
        <div className="filter-elements">
          <div className="filter-top-bar">
            <span className="results-count">{resultCount} results</span>
            <br />
            <br />
            <span>Filters</span>
            <span className="clearall">Clear all</span>
          </div>
        </div>
      </div>
      <div>
        <Card>
          <div className="flight-filters slider-icon-1">
            <div className="flight-result-indicator">
              {isHotelSearchLoad ? (
                <Skeleton paragraph={{ rows: 0 }} active />
              ) : (
                // <p className="hotelfiltertopnoofresultSec">
                //   {/* Showing {resultCount} Of {count} Hotels */}
                //   {resultCount} Hotels & Holiday rentals found
                // </p>
                <div className="hotelfiltertopnoofresultSec">
                  {resultCount} Hotels & Holiday rentals found
                </div>
              )}
            </div>
            <div className="overall-filter-container">
              {/* shkhr cmntd <div className="overall-filter-header">
                <p className="filter-text">Filters</p>
                <p className="clear-text" onClick={setDynamicFilters}>
                  Clear all
                </p>
              </div> */}
              <div className="overall-filter-body">

                <div className="searchByHotelandcitynamecont">
                   <p className="searchByHotelandcitynametext">Search by Hotel Name / City</p>
                   <div className="hotelfltrsearchbarcont"> <Search
                    placeholder="Enter Hotel name/city name"
                    enterButton="Go"
                    size="medium"
                    onSearch={value => console.log(value)}
                   /></div>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Price per a night" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Less than &euro; 70
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">32</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          From &euro; 70 to 125
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">65</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          From &euro; 125 to 200
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">213</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          From &euro; 200 to 299
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">47</div>
                        </div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                {/* shkhr cmntd <div className="stops-filter">
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel header="Price" key="1" extra={extraPanel("price")}>
                      {isHotelSearchLoad ? (
                        <Skeleton paragraph={{ rows: 0 }} active />
                      ) : (
                        <>
                          {filters.price && (
                            <Slider
                              range
                              step={1}
                              defaultValue={[
                                filters.price.minPrice,
                                filters.price.maxPrice,
                              ]}
                              value={priceRange}
                              min={filters.price.minPriceRange}
                              max={filters.price.maxPriceRange}
                              onChange={priceChangeHandler}
                              onAfterChange={priceChangeCompleteHandler}
                            />
                          )}
                          <div className="slider-label">
                            <span>₹ {priceRange[0]}</span>
                            <span>₹ {priceRange[1]}</span>
                          </div>
                        </>
                      )}
                    </Panel>
                  </Collapse>
                </div> */}
                {/* <div className="stops-filter star-filters">
                  <Collapse
                    defaultActiveKey={["1"]}
                   
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={"Amenities"}
                      key="1"
                      extra={extraPanel("amenities")}
                    >
                      {filters.amenities && (
                        <>
                          {filters.amenities.map((amenity, index) => (
                            <p>
                              {((showMoreObj.amenities && index < 6) ||
                                !showMoreObj.amenities) && (
                                <Checkbox
                                  checked={amenity.isChecked}
                                  onChange={(e) =>
                                    onChange(e, "amenities", amenity.id)
                                  }
                                >
                                  {amenity.label}
                                  <span className="count">
                                    ({getCount("amenities", amenity.id)})
                                  </span>
                                </Checkbox>
                              )}
                            </p>
                          ))}
                          <p
                            className="show-more-btn-new"
                            onClick={() => handleShowMore("amenities")}
                          >
                            {showMoreObj.amenities
                              ? "+ Show more"
                              : "- Show less"}
                          </p>
                        </>
                      )}
                    </Panel>
                  </Collapse>
                </div> */}
                {/* <div className="stops-filter">
                  <Collapse
                    defaultActiveKey={["1"]}
                  
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={"Properties"}
                      key="1"
                      extra={extraPanel("property")}
                    >
                      {filters.property && (
                        <>
                          {filters.property.map((prop, index) => (
                            <p>
                              {((showMoreObj.property && index < 6) ||
                                !showMoreObj.property) && (
                                <Checkbox
                                  checked={prop.isChecked}
                                  onChange={(e) =>
                                    onChange(e, "property", prop.id)
                                  }
                                >
                                  {prop.label}
                                </Checkbox>
                              )}
                            </p>
                          ))}
                          <p
                            className="show-more-btn-new"
                            onClick={() => handleShowMore("property")}
                          >
                            {showMoreObj.property
                              ? "+ Show more"
                              : "- Show less"}
                          </p>
                        </>
                      )}
                    </Panel>
                  </Collapse>
                </div> */}

                {/* <div className="stops-filter">
                  <Collapse
                    defaultActiveKey={["1"]}
               
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={"Room Amenities"}
                      key="1"
                      extra={extraPanel("roomAmenities")}
                    >
                      {filters.roomAmenities && filters.roomAmenities.length ? (
                        filters.roomAmenities.map((amenity) => (
                          <p>
                            <Checkbox
                              checked={amenity.isChecked}
                              onChange={(e) =>
                                onChange(e, "roomAmenities", amenity.id)
                              }
                            >
                              {amenity.label}
                            </Checkbox>
                          </p>
                        ))
                      ) : (
                        <p>No data found</p>
                      )}
                    </Panel>
                  </Collapse>
                </div> */}
                {/* <div className="stops-filter star-filters">
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={"Amendment"}
                      key="1"
                      extra={extraPanel("amendment")}
                    >
                      {filters.amendment &&
                        filters.amendment.map((ratg) => (
                          <p key={ratg.id + "ratg"}>
                            <Checkbox
                              checked={ratg.isChecked}
                              onChange={(e) =>
                                onChange(e, "amendment", ratg.id)
                              }
                            >
                              {ratg.label}
                              <span className="count">
                                ({getCount("amendment", ratg.id)})
                              </span>
                            </Checkbox>
                          </p>
                        ))}
                    </Panel>
                  </Collapse>
                </div> */}
                
               

                <div className="star-filters hotelfltreachfltrcontainer">
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel 
                    className="hotelfltrpanelheader"
                      header={"Star Rating"}
                      key="1"
                      // extra={extraPanel("rating")}
                    >
                      {filters.rating &&
                        filters.rating.map((ratg) => (
                          <p>
                            <Checkbox
                              checked={ratg.isChecked}
                              onChange={(e) => onChange(e, "rating", ratg.id)}
                            >
                              {/* {ratg.label} */}
                              <Rate
                                className="starRating"
                                disabled
                                value={Number(ratg.id)}
                                allowHalf={true}
                              />
                              <span className="count">
                                ({getCount("starRating", ratg.id)})
                              </span>
                            </Checkbox>
                          </p>
                        ))}
                    </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Property Type" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Aksara
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Aksara
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Asian side
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Atasehir
                        </Checkbox>
                        </div>
                        <div className="htlfltrshowmorecontainer">Show More</div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Meal plan" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Breakfast
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">127</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Half Board
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">127</div>
                        </div> 
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Facility Requirements" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Free Internet/ WiFi
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">120</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Air conditioning
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">128</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Swimming pool
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">54</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Free Air transportation
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">71</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Free breakfast
                        </Checkbox>
                        <div className="noofitemsaftrhtlfltr">242</div>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Free parking
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Kitchen
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Pets friendly
                        </Checkbox>
                        </div>
                        <div className="htlfltrshowmorecontainer">Show More</div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Property Location" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Hotel
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Homes & Apartments
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Studio Flats
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Chalet
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Cottages
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Villas
                        </Checkbox>
                        </div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Accessibility" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Accessible bathroom
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Inner-room accessibility
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Roll-in Shower
                        </Checkbox>
                        </div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Popular location" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Mixer
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Hagia Sophia
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Blue Mosque
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Hippodrome
                        </Checkbox>
                        </div>
                        <div className="htlfltrshowmorecontainer">Show More</div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                <div className="hotelfltreachfltrcontainer">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel className="hotelfltrpanelheader" header="Neighbourhood" key="1">
                  {/* {filters.stops && */}
                    {/* filters.stops.map((stop, i) => ( */}
                      {/* <p> */}
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          European side
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Asian side
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                          Istanbul City centre
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Beyazit
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Sisili
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Old City Sultanahmet
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Fatih
                        </Checkbox>
                        </div>
                        <div className="htlfltrpricecheckboxParent">
                        <Checkbox className="htlfltrpricecheckbox"
                          // checked={stop.isChecked}
                          // onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {/* {stop.label} */}
                           Taksim
                        </Checkbox>
                        </div>
                        <div className="htlfltrshowmorecontainer">Show More</div>
                      {/* </p> */}
                    {/* ))} */}
                </Panel>
                  </Collapse>
                </div>

                {/* shkhr cmntd {filters.tdRating && filters?.tdRating?.length > 0 ? (
                  <div className="stops-filter tripAd-filters">
                    <Collapse
                      defaultActiveKey={["1"]}
                      expandIconPosition={"right"}
                    >
                      <Panel
                        header={
                          <span>
                            <i
                              className="fa fa-tripadvisor"
                              aria-hidden="true"
                            ></i>{" "}
                            TripAdvisor Rating
                          </span>
                        }
                        key="1"
                        extra={extraPanel("tdRating")}
                      >
                        {filters.tdRating.map((ratg) => (
                          <p>
                            <Checkbox
                              checked={ratg.isChecked}
                              onChange={(e) => onChange(e, "tdRating", ratg.id)}
                            >
                              <Rate
                                className="tripRating"
                                disabled
                                character={
                                  <i
                                    className="fa fa-circle"
                                    aria-hidden="true"
                                  ></i>
                                }
                                value={Number(ratg.id)}
                                allowHalf={true}
                              />
                              <span className="count">
                                ({getCount("tripRating", ratg.id)})
                              </span>
                            </Checkbox>
                          </p>
                        ))}
                      </Panel>
                    </Collapse>
                  </div>
                ) : null} */}

                {/* code for location as per country by karthik 26-Dec-23 */}
                {/* shkhr cmntd {UniqueCities && UniqueCities?.length > 0 ? (
                  <div className="stops-filter tripAd-filters">
                    <Collapse
                      defaultActiveKey={["1"]}
                      expandIconPosition={"right"}
                    >
                      <Panel
                        header={
                          <span>
                            <i
                              className="fa fa-map-marker mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            Location
                          </span>
                        }
                        key="1"
                        shkhr uncmnted to cmnt whole stops-filter section extra={extraPanel("tdRating")}
                      >
                        {UniqueCities.map((ratg) => (
                          <p>
                            <Checkbox onChange={handleCheckboxChange} checked={selectedCities.includes(ratg)} value={ratg} >{ratg}</Checkbox>
                            shkhr uncmnted to cmnt whole stops-filter section <Checkbox.Group options={UniqueCities} onChange={getHotelByLocation} />
                          </p>
                        ))} 
                      </Panel>
                    </Collapse>
                  </div>
                ) : null} */}
                {/* code for location as per country by karthik 26-Dec-23 */}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Filter;
