import React from 'react'
import './affordablehoteldeals.css'
import hotel1 from '../../assets/images/affordablehotelimg/hotel1.png'
import hotel2 from '../../assets/images/affordablehotelimg/hotel2.png'
import hotel3 from '../../assets/images/affordablehotelimg/hotel3.png'
import houseboat from '../../assets/images/affordablehotelimg/houseboat.png'

import { Dropdown, Space, Row, Col, Card, Rate } from 'antd'
import { DownOutlined } from '@ant-design/icons';
const { Meta } = Card;




const AffordableHotelDeals = () => {
    return (
        <div className='affordableHotelSection'>
            <div className='affhotelheadingscont'>
                <h4 className='headAfford'> Affordable Hotel Deals Around the World</h4>
                <div className='affordsubhead'>
                    <span className='descAfford '> We have teamed up with the leading hotel providers from cheap to five start luxury hotels to meet our customers expectation</span>
                </div>
            </div>
            <div style={{ padding: '20px' }}>
                <Row gutter={[16, 8]} justify={'center'}>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                        <Card className='affordCard'
                            hoverable
                            style={{
                                // width: 250,
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel1} />}
                        >
                            <Meta className='affordCardTitle' title="Rotana Hotel" />
                            <Rate className='affordcardrating' />
                            {/* <p className="starcard4"><img src="./images/star_rating_4.png" alt="" /></p> */}
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard'
                            hoverable
                            style={{
                                // width: 250,
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel2} />}
                        >
                            <Meta className='affordCardTitle' title="GRAND MILLENNIUM HOTEL" />
                            <Rate className='affordcardrating' />
                            {/* <p className="starcard4"><img src="./images/star_rating_4.png" alt="" /></p> */}
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard'
                            hoverable
                            style={{
                                // width: 250,
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel3} />}
                        >
                            <Meta className='affordCardTitle' title="DIVEN ERBIL HOTEL" />
                            <Rate className='affordcardrating' />
                            {/* <p className="starcard4"><img src="./images/star_rating_4.png" alt="" /></p> */}
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard'
                            hoverable
                            style={{
                                // width: 250,
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={houseboat} />}
                        >
                            <Meta className='affordCardTitle' title="House Boat" />
                            <Rate className='affordcardrating' />
                            {/* <p className="starcard4"><img src="./images/star_rating_4.png" alt="" /></p> */}
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* <Dropdown            
                trigger={['click']}
                className='viewallhoteldrop'
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <div className='seeoffer'>View All Hotel Offers</div>
                        <DownOutlined className='iconoutlined' />
                    </Space>
                </a>
            </Dropdown> */}
          <div class="vl-offers-link morehotellink"><a href="#">View all Hotel offers</a></div>

        </div>
    )
}

export default AffordableHotelDeals
