import React from 'react'
import './findaffordablehoteldeals.css'
import hotel1 from '../../assets/images/affordablehotelimg/hotel1.png'
import hotel2 from '../../assets/images/affordablehotelimg/hotel2.png'
import hotel3 from '../../assets/images/affordablehotelimg/hotel3.png'
import houseboat from '../../assets/images/affordablehotelimg/houseboat.png'

import { Dropdown, Space, Row, Col, Card, Rate } from 'antd'
import { DownOutlined } from '@ant-design/icons';
const { Meta } = Card;




const FindAffordableHotelDeals = () => {
    return (
        <div className='affordableHotelSection findafforddealseverywheresection'>
            <div className='affhotelheadingscont cheaphoteldesthead'>
            <h4 className='headAfford headcheaphoteldest'>Find affordable hotel deals wherever you go</h4>
                <div className='affordsubhead cheaphoteldestsubhead'>
                    <span className='desccheaphoteldest'>
                       As the ultimate package <a className='cheaphoteldealstext'>holiday destination</a>, Spain has everyone covered from family to couples
                       and groups. Its cities ooze architectural style while its costal towns offers beaches of all shapes and sizes. You can reach mainland Spain 
                       in around two hours and the Balearics are just as convenient. Prefer to travel during the winter? Then make the most of the year-round warmth 
                       in the Canary Islands with holiday to <a className='cheaphoteldealstext'>Tenerife, Lanzarote</a> and <a className='cheaphoteldealstext'>Gran Canaria</a> 
                    </span>
                </div>
            </div>
            <div style={{ padding: '20px' }}>
                {/* <Row gutter={[16, 8]} justify={'center'}>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                        <Card className='affordCard cheaphoteldealscard'
                            hoverable
                            style={{
                               
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel1} />}
                        >
                            <Meta className='affordCardTitle' title="Rotana Hotel" />
                            <Rate className='affordcardrating' />
                            
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard cheaphoteldealscard'
                            hoverable
                            style={{
                               
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel2} />}
                        >
                            <Meta className='affordCardTitle' title="GRAND MILLENNIUM HOTEL" />
                            <Rate className='affordcardrating' />
                            
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard cheaphoteldealscard'
                            hoverable
                            style={{
                               
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={hotel3} />}
                        >
                            <Meta className='affordCardTitle' title="DIVEN ERBIL HOTEL" />
                            <Rate className='affordcardrating' />
                            
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard cheaphoteldealscard'
                            hoverable
                            style={{
                                borderBottom: `2px solid #00c8f5`
                            }}
                            cover={<img alt="example" style={{ height: '210px' }} src={houseboat} />}
                        >
                            <Meta className='affordCardTitle' title="House Boat" />
                            <Rate className='affordcardrating' />
                            <div className='affordhoteldatenight'><div className='datespan'>07, Mar - 17, Mar</div><span className='mrgnstrtmightspan'>10 Nights</span></div>
                            <div className='cardpp'>FROM 478 PP</div>
                            <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                            <div className='cardcity'>Greece</div>
                            <div className='hotelcardsave'>Save up <div>to 950</div></div>
                        </Card>
                    </Col>
                </Row> */}
                <div className="container containerforHotelPageLinks">
            <Row gutter={[32, 16]}>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list hotelpagefindaffordhotellinks" style={{listStyle:'none'}}>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Thailand Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Egypt Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Madrid Holidays & City Break</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Catalonia Holidays 2020</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Erbil City Break</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Thailand Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Egypt Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Madrid Holidays & City Break</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Catalonia Holidays 2020</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Erbil City Break</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list hotelpagefindaffordhotellinks">
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Erbil, Iraq Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Sulimaniyah Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Edinburgh City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Morocco Holidays 2020</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Sulimaniyah City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Erbil, Iraq Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Sulimaniyah Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Edinburgh City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Morocco Holidays 2020</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Sulimaniyah City Breaks</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list hotelpagefindaffordhotellinks">
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Dubai Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Vietnam Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Paris Short City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Greece Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Tehran City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Dubai Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Vietnam Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Paris Short City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Greece Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Tehran City Breaks</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list hotelpagefindaffordhotellinks">
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Istanbul Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Barcelona Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Budapest Holidays & City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Antalya Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Baghdad City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Istanbul Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Barcelona Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Budapest Holidays & City Breaks</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Antalya Holidays</a></li>
                  <li><span><i className="fa fa-building-o" /></span><a href="#">Baghdad City Breaks</a></li>
                </ul>
              </Col>
            </Row>
          </div>
            </div>
           

        </div>
    )
}

export default FindAffordableHotelDeals
