import React from 'react'
import './cheapflightmiddleeast.css'
import flightOne from '../../assets/images/cheapFlightImg/flightOne.png'
import flightTwo from '../../assets/images/cheapFlightImg/flightTwo.png'
import flightThree from '../../assets/images/cheapFlightImg/flightThree.png'

import { Card, Col, Dropdown,  Row, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
const {Meta} = Card


const CheapFlightMiddleEast = () => {
  return (
    <div>
        <div class="home-section-heading-wrap home-section-heading-wrap-normal">
            <div class="container">
              <div className="home-section-heading-bottom">
                <h3 class="home-section-heading">Cheap Flights Middle East</h3>
                <p class="home-section-subheading">We have more than 10 years of experience providing cheap flights to middle east</p>
              </div>
            </div>
          </div>
          <div className="home-section-content">
            <div className='container'>
                <Row className='chcardrow' gutter={[12]}>
                    <Col lg={8} sm={12} xs={24}>
                        <div>
                            <div 
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightOne}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                          <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={12} xs={24}>
                    <div>
                    <div>
                    <div
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightTwo}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                          <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col lg={8} sm={12} xs={24}>
                    <div>
                    <div 
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightThree}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                        <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={12} xs={24}>
                    <div>
                    <div 
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightOne}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                        <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={12} xs={24}>
                    <div>
                    <div>
                    <div
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightTwo}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                        <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col lg={8} sm={12} xs={24}>
                    <div>
                    <div 
                                hoverable
                                styles={{
                                    body: {
                                        padding: 0,
                                        overflow: 'hidden',
                                        borderRadius:'5px'
                                    },
                                }}
                                className='cheapflightcard'
                            >
                                <div className='cheapflightcardallseccont'>
                                    <div className='chflightcardleftcont'>
                                    <img
                                        alt="avatar"
                                        src={flightThree}
                                    />
                                    </div>
                                    <div
                                    className='chflightcardmidcont'
                                    >
                                    <div className='cheapflightcardheadflex'>
                                        <div className='cheapFlightCrdMidHead1'>Bahgdad Flights</div>
                                        <div className='cheapFlightCrdMidHead2'>Return</div>
                                        </div>
                                    <div className='cheapfightcrdmidsec'>LON (London  Baghdad) BGW</div>
                                    <div className='cheapfightcrdlowersec'>Gain $20 discount on your next booking</div>
                                    </div>
                                    <div className='chflightcardrightseccont'>
                                        <div className='chflightcardrightseccontBox'>
                                              <div className='cheapflightcardrightsectop'>Avg PP</div>
                                              <div className='cheapflightcardrightsecmid'>$465.00</div>
                                              <div className='cheapflightcardrightseclower'>Search</div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="vl-offers-link">
                    <Dropdown
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                        <div>View All Flight Offers</div>
                            <DownOutlined/>
                        </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CheapFlightMiddleEast
