import { Col, Row, Form } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../banner/Banner";
import Homesearch from "../search-box/SearchBox";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import "./flight.scss";
import TopFlights from "./TopFlights/TopFlights";
import TopCities from "./TopCities/TopCities";
import { useSytContext } from "../../common/providers/SytProvider";
import * as ANTD from "antd";
import { Carousel } from "antd";
// import Blog from "../Blog/Blog";
import BlogGrid from "../Blog/BlogGrid";
import AgentHomePage from "../Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import { Link } from "react-router-dom";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import HomeSlider from "./HomeSlider/HomeSlider";
import SeoMetaData from "../../common/Seo/SeoMetaData";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  StarFilled,
  StarOutlined
} from "@ant-design/icons";
import AffordableHotelDeals from "../AffordableHotelDelas/AffordableHotelDeals";
import WhyGlobalTravels from "../WhyGlobaltravels/WhyGlobaltravels";
// import { useSelector, useDispatch } from 'react-redux'
// import { setSeoDetails, clearSeoDetails } from "../../ReduxContainer/SeoSlice"

const Flight = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
  } = useSytContext();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);
  // const ReduxContainer = useSelector(state => state);
  // const dispatch = useDispatch()

  const getDeals = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };

  // const getSeoMataData = () => {
  //   ApiClient.get('admin/seodata').then((res) => {
  //     if(res.statusCode == 200) {
  //       if(res.data.length > 0) {
  //         dispatch(setSeoDetails(res?.data));
  //       }else{
  //         dispatch(setSeoDetails([]));
  //       }
  //     }
  //   }).catch((e) => {
  //     console.log(e);
  //   })
  // }

  useEffect(() => {
    getDeals();
  }, []);

  // useLayoutEffect(() => {
  //   getSeoMataData();
  // }, [])

  return (
    <React.Fragment>

    {/* <section>
      <div id="banner" className="">
        <Carousel slidesToShow={1} slidesToScroll={1} autoplay={true} dots={true}>
          <img
            src={require("../../assets/images/flight-index-slider/1600x534 Explore Sulaymaniyah 1.webp").default}
            alt=""
          />

          <img
            src={require("../../assets/images/flight-index-slider/Discover tehran.webp").default}
            alt=""
          />

          <img
            src={require("../../assets/images/flight-index-slider/Index Slider image 4.webp").default}
            alt=""
          />
        </Carousel>
      </div>
    </section> */}

      <SeoMetaData path={window.location.pathname} />
    <div id="home-page">
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <AgentHomePage />
      ) : (
        <>
     
        <section className="hero-banner">
          <Banner banner={flightBanners} />
        </section>

        <section className="hero-search">
          <Homesearch/>
        </section>
        </>
      )}
     
      
      
      <section className="home-best-24">
        <div className="container">
          <div className="home-best-24-inner">
            <Row gutter={[16, 16]}>
              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-price-guarantee">
                  <img
                    className="deals-coloums-24"
                    src={require("../../assets/images/icon-fp-best-price-guarantee.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>BEST PRICE GUARANTEED</p>
                    <p>Secure your flight from just &pound; 99</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-travel-cheaper">
                  <img
                    className="deals-coloums-24"
                    src={require("../../assets/images/icon-fp-travel-cheaper.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>TRAVEL UPTO 30% CHEAPER</p>
                    <p>Book Flight  + Hotel, Save upto 30%</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-safe-booking">
                  <img
                    className="deals-coloums-24"
                    src={require("../../assets/images/icon-fp-safe-booking.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>SAFE BOOKING</p>
                    <p>We protect your personal data</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-customer-reviews">
                  <img
                    className="deals-coloums-24"
                    src={require("../../assets/images/google-icon.png").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>Customer Rated Us</p>
                    <div className="fpcr-inner">
                      <div className="fpcr-rating">
                        <span>5.0</span>
                        <ul className="star-rating-list">
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                        </ul>
                      </div>
                      <div>67 Google Reviews</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="home-section" style={{zIndex: '3'}}>
          <div class="home-section-heading-wrap home-section-heading-wrap-normal">
            <div class="container">
              <div className="home-section-heading-bottom">
                <h3 class="home-section-heading">Cheap Flights Middle East</h3>
                <p class="home-section-subheading">We have more than 10 years of experience providing cheap flights to middle east</p>
              </div>
            </div>
          </div>
          <div className="home-section-content">
            <div className="home_carousel blog_grid blog_grid_carousel">
              <Carousel
                slidesToShow={4}
                slidesToScroll={4}
                centerMode={true} 
                arrows={true} 
                infinite={true} 
                autoplaySpeed={2000}
                responsive={[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                
                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-iran-airline.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>Middle East Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Sulaymaniyahhhh (ISU) Iraq</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-3576x2384.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>East Africa Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Agadir (AGA) Morocco</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-300x199.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>United State Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Chicago (ORD) Ollonois</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-300x200.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>East Europe Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Sulaymaniyah (ISU) Iraq</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-iran-airline.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>Middle Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Agadir (AGA) Morocco</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-iran-airline.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>Middle East Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Sulaymaniyahhhh (ISU) Iraq</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-3576x2384.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>East Africa Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Agadir (AGA) Morocco</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-300x199.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>United State Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Chicago (ORD) Ollonois</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-300x200.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>East Europe Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Sulaymaniyah (ISU) Iraq</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_slide">
                  <div className="ant-card ant-card-bordered card">
                    <div class="blog-thumbnail-wrap">
                      <figure className="blog-thumbnail-image-wrap">
                        <img
                          src={require("../../assets/images/flight-iran-airline.webp").default}
                          alt=""
                        />
                        <div className="blog-thumbnail-overlay">
                          <p>Middle Flights</p>
                        </div>
                      </figure>
                      <div className="ant-card-body">
                        <p className="title">Agadir (AGA) Morocco</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
              <div className="vl-offers-link"><a href="#">View all flight offers</a></div>
            </div>
          </div>
      </section>   

      <section className="container">
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>

      <section className="img-ft-img-sec e-hide">
        {bigHomeSliderBanners.length > 0 ? (
          <div className="container">
            <Row>
              <Col md={14} xs={24}>
                <Carousel effect="fade" autoplay={true}>
                  {bigHomeSliderBanners.map((banner, i) => (
                    <div key={i}>
                      <img
                        className="step-img-value"
                        src={
                          process.env.REACT_APP_BASE_URL +
                          banner.path.substring(1)
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={10} xs={24}>
                {smallHomeSliderBanners.length > 0 && (
                  <HomeSlider images={smallHomeSliderBanners} />
                )}
              </Col>
              {/* <img
                  className="step-img-tai"
                  src={require("../../assets/images/tai-1.jpg").default}
                  alt=""
                /> */}

              {/* <Col md={5} xs={24}>
                <img
                  className="step-img-tai"
                  src={require("../../assets/images/tai-2.jpg").default}
                  alt=""
                />

                <div className="rate-btn-from">
                  <div>
                    <ANTD.Button className="book-sg-country">
                      Book Now
                    </ANTD.Button>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        ) : null}
      </section>

      <section className="home-section" style={{zIndex: '2'}}>
          <HomeCarousel dealsList={dealsList} />
      </section>
      {/* <section className="home-section">
        <div className="container">
          <BlogGrid blog={blog} />
        </div>
      </section> */}
      <section className="home-section top-route-bb e-hide">
        <div className="container">
          <TopCities length={3} />
        </div>
      </section>
      
      <section className="top-route-bb">
        <TopFlights dealsList={dealsList} />
      </section>

      <section className="home-section home-why-us" style={{zIndex: '1'}}>
        <div class="home-section-heading-wrap">
          <div class="container">
            <div className="home-section-heading-bottom">
              <h3 class="home-section-heading">Why Book with Shop Yourtrip</h3>
              <p class="home-section-subheading">Hot flight offers for you! around the world, around the clock</p>
            </div>
          </div>
        </div>

        <div className="home-section-content">
          <div className="container">
            <Row gutter={[32, 16]}>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list">
                  <li><a href="#">Cheap Flights from London to Baghdad</a></li>
                  <li><a href="#">Cheap Flights From Birmingham to Erbil</a></li>
                  <li><a href="#">London to Erbil Cheap Flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                  <li><a href="#">Manchester to Sulaymaniyah Flights</a></li>
                  <li><a href="#">Cheap Flights from Manchester to Erbil</a></li>
                  <li><a href="#">London to Istanbul Cheap Flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list">
                  <li><a href="#">london (LON) to Tehran (IKA)</a></li>
                  <li><a href="#">Tehran (IKA) to London (LON)</a></li>
                  <li><a href="#">Tehran (IKA) to Manchester (MAN)</a></li>
                  <li><a href="#">London (LON) to Sulaymaniyah (ISU)</a></li>
                  <li><a href="#">Birmingham To Sulaymaniyah flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                  <li><a href="#">London To Ankara Cheap Flights £165</a></li>
                  <li><a href="#">Manchester to Ataturk Airport</a></li>
                  <li><a href="#">Cheap Flights From London To Dubai</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list">
                  <li><a href="#">Cheap Flights from London to Baghdad</a></li>
                  <li><a href="#">Cheap Flights From Birmingham to Erbil</a></li>
                  <li><a href="#">London to Erbil Cheap Flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                  <li><a href="#">Cheap Flights from London to Baghdad</a></li>
                  <li><a href="#">Cheap Flights From Birmingham to Erbil</a></li>
                  <li><a href="#">London to Erbil Cheap Flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                </ul>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <ul className="home-why-us-list">
                  <li><a href="#">Cheap Flights from London to Baghdad</a></li>
                  <li><a href="#">Cheap Flights From Birmingham to Erbil</a></li>
                  <li><a href="#">London to Erbil Cheap Flights</a></li>
                  <li><a href="#">London To Tabriz Flights From £377</a></li>
                  <li><a href="#">London To Erbil Flights From £287</a></li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="home-section home-best-trip text-justify e-hide">
        <div className="container">
          <Row>
            <Col md={24} xs={24}>
              <h3 className="home-section-heading">Why HoldTrip?</h3>
              <p>
                Our competitive rates and exclusive offers are what gives us a
                top notch over our competitors. We promise 'Unbeatable' services
                both in pricing and quality. Here is the one stop destination
                for your Dream Destination. HoldTrip provide you the best
                travel packages at the lowest possible pricing that gives the
                best value for your each penny. We are your Travel Companion and
                works for you so that can get the best travel experience and
                live some memorable moments.
              </p>
              <p>
                We give you the pros and cons for all the different travel
                products and allow you to decide what works best for you and
                your family. We combine first-hand knowledge with exceptional
                special offers, and we take care of every detail to create a
                holiday as unique as you are. You will no more need to worry
                about coordinating flight bookings, hotel reservations, visa
                stamps or tours as all your needs are taken care of under one
                roof.
              </p>
              <p>
                HoldTrip can satisfy all your travel needs. Here, You can
                book flight tickets, hotels, bus tickets, activities and holiday
                packages at a cost-effective price. So, why go anywhere else?
                Visit us for a memorable travel experience in your budget.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>
      
      <section className="save-upto">
        <div className="container">
          <div className="save-upto-inner">
            <img
              src={require("../../assets/images/mobile-app-screenshot.webp").default}
              className="mbl-app-screenshot"
              alt=""
            />
            
            <p className="save-upto-text">
              save up to 10% when you book with our friendly Mobile APP</p>
            
            <div className="mbl-app-store-logos">
                <a class="btn-storelogo blue">
                  <i class="fa fa-apple" aria-hidden="true"></i>
                  <span>Download from<br /><em>apple store</em></span>
                </a>
                
                <a class="btn-storelogo yellow">
                  <i class="fa fa-android" aria-hidden="true"></i>
                  <span>Download from<br /><em>google play</em></span>
                </a>
                {/* <img
                  src={require("../../assets/images/download-apple-store.webp").default}
                  alt=""
                />
                <img
                  src={require("../../assets/images/download-google-play.webp").default}
                  alt=""
                /> */}
            </div>
          </div>
        </div>
      </section>

      <section>{/* <FlightDeal /> */}</section>
      <Subscribe />
      <section class="paysocial-wrap">
        <div className="container">
          <div className="paysocial-inner">
              <div className="paysocial-col paysocial-col-left">
                <div>
                  <p>We accept most of major<br></br>debit and credit cards</p>
                  <img
                    src={require("../../assets/images/paycards-img.webp").default}
                    alt=""
                  />
                  <ul className="footer-visa-images e-hide">
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../assets/images/visa-footer-1.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../assets/images/visa-footer-2.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../assets/images/visa-footer-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../assets/images/visa-footer-4.png")
                            .default
                        }
                        alt=""
                      />
                    </li>
                  </ul>
                </div>

                <div>
                  <p>Your data and online<br></br>purchase protected</p>
                  <img
                    src={require("../../assets/images/geotrust-logo.webp").default}
                    alt=""
                  />
                </div>
              </div>

              <div className="paysocial-col paysocial-col-right">
                <ul className="trust-protection-list">
                  <li>
                    <p>We are ATOL Protected</p>
                    <img
                        src={require("../../assets/images/atol-protected1.webp").default}
                        alt=""
                      />
                  </li>
                  <li>
                    <p>We are financially Protected</p>
                    <img
                        src={require("../../assets/images/financially-protected.webp").default}
                        alt=""
                      />
                  </li>
                  <li>
                    <p>We are ATOL Protected</p>
                    <img
                        src={require("../../assets/images/atol-protected2.webp").default}
                        alt=""
                      />
                  </li>
                </ul>
                <div>
                  <p>Follow is on social media for more travel news</p>
                  <div className="social_icons">
                    <a href="#" target="_blank">
                      <img
                        src={require("../../assets/images/icon-facebook.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../assets/images/icon-linkedin.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../assets/images/icon-google.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../assets/images/icon-instagram.webp").default}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="banner-links">
        <div className="banner-links-wrapper">
          <ul>
            <li>
              <p className="banner-icon">
                <i className="fa fa-info" aria-hidden="true"></i>
              </p>
              <div className="banner-box">
                <label>Before you contact us</label>
                <a href="/checkInfo">Check information&gt;&gt;</a>
              </div>
            </li>
            <li>
              <p className="icon-info">
                BLOG
                 <i className="fa fa-info" aria-hidden="true"></i> 
              </p>
              <div className="banner-box">
                <label>BLOG</label>
                <a href="/blog">Articles and inspirations&gt;&gt;</a>
              </div>
            </li>
          </ul>
        </div>
      </section> */}
    </div>
    </React.Fragment>
  );
};

export default Flight;
