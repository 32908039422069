import React, { useState, useEffect } from 'react';
import { Input, Select, Checkbox, Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Card } from 'react-bootstrap';

const RoomSort = ({ openRoomsDetails, setRoomsDetailsOpen, fixRoomsDetails, setRoomsDetailsFix, roomsDetails, setRoomsDetails, handleCheckout, isCheckoutButtonActive = false,
    handlePriceSortClick = () => {},
    sorting,
    sortDirection,
    setSorting,
    setSortDirection
 }) => {
    // const [sorting, setSorting] = useState('price');
    // const [sortDirection, setSortDirection] = useState('asc');
    const [mealTypeFilter, setMealTypeFilter] = useState(null);
    const [searchString, setSearchString] = useState('');
    const [freeCancellation, setFreeCancellation] = useState(false);
    // { roomsDetails, setRoomsDetails, CopyRoomsDetails ,handleCheckout, setRoomsDetailsFix, setRoomsDetailsOpen,isCheckoutButtonActive=false}

    let mealTypesObj = [
        {
            value: 'Breakfast',
            label: 'Breakfast',
        },
        {
            value: 'Half board',
            label: 'Half Board',
        },
        {
            value: 'Full board',
            label: 'Full Board',
        },
    ]

    // useEffect(() => {
    //     sortRoom(sorting, sortDirection);
    // }, [sorting, sortDirection]);

    // const sortRoom = (selSorting, dir) => {
    //     const OpenRoomDetails = roomsDetails[0];
    //     const FixRoomDetails = roomsDetails[1];

    //     if(OpenRoomDetails.roomList.length > 0){
    //         let OpenPriceFilterList = OpenRoomDetails?.roomList?.slice().sort((roomA, roomB) => {
    //             if (selSorting === 'price') {
    //                 const priceA = Math.round(roomA?.ratePlans[0]?.price?.total);
    //                 const priceB = Math.round(roomB?.ratePlans[0]?.price?.total);
    //                 return dir === 'asc' ? priceA - priceB : priceB - priceA;
    //             }
    //             return 0;
    //         });
    
    //         setRoomsDetailsOpen({ roomList: OpenPriceFilterList, type: "Open" })
    //     }
        
    //     if(FixRoomDetails?.roomList.length > 0){
    //         let FixPriceFilterList = FixRoomDetails?.roomList?.slice().sort((roomA, roomB) => {
    //             if (selSorting === 'price') {
    //                 const priceA = Math.round(roomA?.rooms[0]?.ratePlans[0]?.price?.total);
    //                 const priceB = Math.round(roomB?.rooms[0]?.ratePlans[0]?.price?.total);
    //                 return dir === 'asc' ? priceA - priceB : priceB - priceA;
    //             }
    //             return 0;
    //         });
    //     setRoomsDetailsFix({roomList: FixPriceFilterList, type: "Fixed"})
    // }
    // };

    const applyFilters = () => {
        const OpenRoomDetails = roomsDetails[0];
        const FixRoomDetails = roomsDetails[1];

        if(OpenRoomDetails.roomList.length > 0){
            let OpenfilteredList = OpenRoomDetails?.roomList.filter((room) => {
                const nameMatches = room?.roomName?.toLowerCase().includes(searchString.toLowerCase());
                const meetsFreeCancellation = !freeCancellation || (
                    room?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount && room?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount !== undefined &&
                    room?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount <= 0
                );
                const meetsMealTypeFilter = !mealTypeFilter || room?.ratePlans[0]?.inclusions.some(item =>
                    item?.toUpperCase().includes(mealTypeFilter?.toUpperCase())
                );
                return nameMatches && meetsFreeCancellation && meetsMealTypeFilter;
            });
            setRoomsDetailsOpen({ roomList: OpenfilteredList, type: "Open" })
        }

        if(FixRoomDetails?.roomList.length > 0){
            let FixfilteredList = FixRoomDetails?.roomList.filter((room) => {
                const nameMatches = room?.rooms[0]?.roomName?.toLowerCase().includes(searchString.toLowerCase());
                const meetsFreeCancellation = !freeCancellation || (
                    room?.rooms[0]?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount && room?.rooms[0]?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount !== undefined &&
                    room?.rooms[0]?.ratePlans[0]?.cancellationPolicy[0]?.penaltyAmount <= 0
                );
                const meetsMealTypeFilter = !mealTypeFilter || room?.rooms[0]?.ratePlans[0]?.inclusions.some(item =>
                    item?.toUpperCase().includes(mealTypeFilter?.toUpperCase())
                );
                return nameMatches && meetsFreeCancellation && meetsMealTypeFilter;
            });
            setRoomsDetailsFix({roomList: FixfilteredList, type: "Fixed"})
        }
    };

    const handleFreeCancellationChange = (e) => {
        setFreeCancellation(e.target.checked);
    };

    const handleMealTypeChange = (value) => {
        setMealTypeFilter(value);
    };

    const handleSearch = (e) => {
        setSearchString(e.target.value);
    };

    const clearAllFilters = () => {
        setSearchString('');
        setFreeCancellation(false);
        setMealTypeFilter(null);
        setSorting('price');
        setSortDirection('asc');
        setRoomsDetailsOpen(roomsDetails[0]);
        setRoomsDetailsFix(roomsDetails[1]);
    };

    useEffect(() => {
        applyFilters();
    }, [searchString, freeCancellation, mealTypeFilter]);

    return (
        <Card className="mb-3 sticky-top hoteldtl-rooms-sort-card">
            <div className="hoteldtl-rooms-filter-card">
                <div className="rooms-filterby-search">
                    <Input placeholder="Search Room Type" value={searchString} onChange={handleSearch} />
                </div>

                <div className="rooms-sortby-price">
                    <div onClick={handlePriceSortClick}>
                        Price {sorting === 'price' && (sortDirection === 'asc' ? <CaretUpOutlined /> : <CaretDownOutlined />)}
                    </div>
                </div>

                <div className="rooms-filterby-cancellation">
                    <Checkbox checked={freeCancellation} onChange={handleFreeCancellationChange}>Free cancellation</Checkbox>
                </div>

                <div className="rooms-filterby-meals-types">
                    <Select
                        placeholder="Select a Meal Type"
                        value={mealTypeFilter}
                        onChange={handleMealTypeChange}
                        options={mealTypesObj}
                    >
                    </Select>
                </div>

                <div className="clearAllFilter">
                    <Button onClick={clearAllFilters} >Clear Filters</Button>
                </div>
                {isCheckoutButtonActive&&
                    <div className="button-wrapper d-flex align-items-end flex-column">
                        <Button className='checkoutButton'
                            // disabled={ratePlan.soldOut === true}
                            onClick={handleCheckout}
                        >
                            Proceed to Checkout
                        </Button>
                    </div>}
            </div>
        </Card>
    );
};

export default RoomSort;
