import React, { useState, useEffect } from "react";
import { Card, Carousel, Col, Rate, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import Hotels from "../../../components/Hotels/Hotels";
import * as ReactBoostrap from "react-bootstrap";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import Homesearch from '../../../components/search-box/SearchBox'
import moment from "moment";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import { useSelector } from "react-redux";
import SeoMetaData from "../../../common/Seo/SeoMetaData";
import { StarFilled } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import CheapHotelDestinations from "../../../components/CheapHotelDestinations/CheaphotelDestinations";
import FindAffordableHotelDeals from "../../../components/FindAffordableHotelDeals/FindAffordableHotelDeals";


const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const ReduxContainer = useSelector(state => state);

  const getCityHotelList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 2
          );
          let result = data.reduce(function (obj, key) {
            obj[`${key.CityName}, ${key.CountryName}`] =
              obj[`${key.CityName}, ${key.CountryName}`] || [];
            obj[`${key.CityName}, ${key.CountryName}`].push(key);
            return obj;
          }, {});

          setCityHotelData(result);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  useEffect(() => {
    getCityHotelList();
  }, []);

  const getHotelUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.checkInDate = moment().add(1, "days").format("YYYY-MM-DD");
      params.checkOutDate = moment().add(2, "days").format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/hotels/listing?${params}`;
    } else {
      return "";
    }
  };

  return (
    <div className="hotels_search_container">
      <SeoMetaData path={`${window.location.pathname}`} />
      <section className="hotel_banner">
        <div>
          <Banner banner={hotelBanners} />
        </div>

        <div className="heading_text">
          <h2>
            Hotels and accommodation. Book safely with the option of
            cancellation.
          </h2>
        </div>
        {/* <div className="hotel_search">
          <Hotels hotelSearch={props.hotelContext} />
        </div> */}
      </section>

      <section className="hero-search">
          <Homesearch/>
        </section>

        <section className="home-best-24">
        <div className="container">
          <div className="home-best-24-inner">
            <Row gutter={[16, 16]}>
              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-price-guarantee">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/icon-fp-best-price-guarantee.webp").default}
                    // src={require("../../assets/images/icon-fp-best-price-guarantee.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>BEST PRICE GUARANTEED</p>
                    <p>Secure your flight from just &pound; 99</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-travel-cheaper">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/icon-fp-travel-cheaper.webp").default}
                    // src={require("../../assets/images/icon-fp-travel-cheaper.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>TRAVEL UPTO 30% CHEAPER</p>
                    <p>Book Flight  + Hotel, Save upto 30%</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-safe-booking">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/icon-fp-safe-booking.webp").default}
                    // src={require("../../assets/images/icon-fp-safe-booking.webp").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>SAFE BOOKING</p>
                    <p>We protect your personal data</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} xs={24}>
                <div className="fp-col fp-customer-reviews">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/google-icon.png").default}
                    // src={require("../../assets/images/google-icon.png").default}
                    alt=""
                  />
                  <div className="fp-inner-col">
                    <p>Customer Rated Us</p>
                    <div className="fpcr-inner">
                      <div className="fpcr-rating">
                        <span>5.0</span>
                        <ul className="star-rating-list">
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                          <li><StarFilled /></li>
                        </ul>
                      </div>
                      <div>67 Google Reviews</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt="icon"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  shkhr uncmntd this para for cmnt full section <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt="icon hotel"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  shkhr uncmntd this para for cmnt full section <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      <div className=" ">
        {/*--start----end--*/}

        {/* <Row className="heading-hotel">
          <Col md={24} xs={24}>
            <h3>Only here! New, larger accommodation base</h3>
            <p>
              Hotels, apartments, bungalows &amp; homestays - just the way you
              like it
            </p>
          </Col>
        </Row> */}

        <section className="home-section">
          <div class="home-section-heading-wrap home-section-heading-wrap-normal">
            <div class="container">
              <div className="home-section-heading-bottom">
                <h3 class="home-section-heading">Affordable Hotel Deals around the world</h3>
                <p class="home-section-subheading">We have teamed up with the leading hotel providers from cheap to five star luxury hotels to meet our customers expectations</p>
              </div>
              <div className="populardiscountdivscontainer">
                <div className="populardivcontainer">Popular Hotel Deals</div>
                <div className="discountdivcontainer">Discounted Hotel Deals</div>
                <div className="populardisborderbottomdiv"></div>
              </div>
            </div>
          </div>
          <div className="home-section-content">
            <div className="home_carousel blog_grid blog_grid_carousel">
              <Carousel
                slidesToShow={1}
                slidesToScroll={1}
                centerMode={true} 
                arrows={true} 
                infinite={true} 
                autoplaySpeed={2000}
                responsive={[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                
                {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
              <div className="hotelpagesliderhotels" key={index}>
                <Row gutter={[16, 6]}>
                  {/* <Col md={24} xs={24}>
                    <p className="text-center font-weight-bold">{cityHotel}</p>
                  </Col> */}
                  <div className="htlpagehtlcardsparent">
                  {cityHotelData[cityHotel].map((item, i) => (
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard affordCard1htl'
                        hoverable
                        style={{
                            // width: 250,
                            borderBottom: `2px solid #00c8f5`
                        }}
                        cover={<img alt="example" style={{ height: '210px' }} 
                        src={item.HotelImage1
                                  ? `${BASE}${item.HotelImage1.substring(1)}`
                                  : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                      .default
                               }   
                              //  alt="Hotel Image"
                               
                        />}
                    >
                        <Meta className='affordCardTitle' title={item.Title} />
                        <p className="affordCard1htlnights"><span><i className="fa fa-building-o" /></span> 22 Nights</p>
                        {/* <p className="affordCard1htlnights">22 Nights</p> */}
                        <div className='affordCard1htldates'><div className='datespan'>04, Mar 2020</div><div className=''>26, Mar 2020</div></div>                       
                        {/* <div className='cardpp'>FROM 478 PP</div> */}

                        <Rate className='affordcardrating affordCard1htlrating' />
                        <div className="affordCard1htlfromcont"><span className="affordCard1htlfromtext">From</span></div>
                        <div className="affordCard1htlperperson"><span className="affordCard1htlperpersonprice"> &euro; 1500</span> Per Person</div>
                        {/* <div style={{marginBottom:'20px'}}></div> */}
                        <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                        <div className='cardcity'>{cityHotel}</div>
                        {/* <div className='hotelcardsave'>Save up <div>to 950</div></div> */}
                    </Card>
                    <div className="htlpageaffordhtldlsviewmoredetails"><a className="htlpageaffordhtldlsviewmoredetailsa">View More details</a></div>
                </Col>
                    // <div md={6} xs={24} key={i}>
                    //   <Link to={getHotelUrl(item.SearchURL)} className="d-block">
                    //     <img
                    //       className="cart-shopping-img"
                    //       src={
                    //         item.HotelImage1
                    //           ? `${BASE}${item.HotelImage1.substring(1)}`
                    //           : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                    //               .default
                    //       }
                    //       alt="Hotel Image"
                    //     />
                    //   </Link>
                    //   <p className="text-center font-weight-bold mb-0">
                    //     {item.Title}
                    //   </p>
                    // </div>
                  ))}
                  </div>
                </Row>{" "}
              </div>
            ))
          : null}

                
              </Carousel>
              <div className="vl-offers-link"><a href="#">View all flight offers</a></div>
            </div>
          </div>
      </section> 



      {/* <section className="home-section">
          <div class="home-section-heading-wrap home-section-heading-wrap-normal">
            <div class="container">
              <div className="home-section-heading-bottom">
                <h3 class="home-section-heading">Affordable Hotel Deals around the world</h3>
                <p class="home-section-subheading">We have teamed up with the leading hotel providers from cheap to five star luxury hotels to meet our customers expectations</p>
              </div>
              <div className="populardiscountdivscontainer">
                <div className="populardivcontainer">Popular Hotel Deals</div>
                <div className="discountdivcontainer">Discounted Hotel Deals</div>
                <div className="populardisborderbottomdiv"></div>
              </div>
            </div>
          </div>
          <div className="home-section-content">
            <div className="home_carousel blog_grid blog_grid_carousel">
              <Carousel
                slidesToShow={1}
                slidesToScroll={1}
                centerMode={true} 
                arrows={true} 
                infinite={true} 
                autoplaySpeed={2000}
                responsive={[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                
                {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
              <div key={index}>
                <Row gutter={[16, 6]}>
                 
                  {cityHotelData[cityHotel].map((item, i) => (
                    <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard affordCard1htl'
                        hoverable
                        style={{
                            width: 250,
                            borderBottom: `2px solid #00c8f5`
                        }}
                        cover={<img alt="example" style={{ height: '210px' }} 
                        src={item.HotelImage1
                                  ? `${BASE}${item.HotelImage1.substring(1)}`
                                  : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                      .default
                               }   
                               
                        />}
                    >
                        <Meta className='affordCardTitle' title={item.Title} />
                        <p className="affordCard1htlnights"><span><i className="fa fa-building-o" /></span> 22 Nights</p>
                        <div className='affordCard1htldates'><div className='datespan'>04, Mar 2020</div><div className=''>26, Mar 2020</div></div>                       

                        <Rate className='affordcardrating affordCard1htlrating' />
                        <div className="affordCard1htlfromcont"><span className="affordCard1htlfromtext">From</span></div>
                        <div className="affordCard1htlperperson"><span className="affordCard1htlperpersonprice"> &euro; 1500</span> Per Person</div>
                        <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                        <div className='cardcity'>{cityHotel}</div>
                    </Card>
                    <div className="htlpageaffordhtldlsviewmoredetails"><a className="htlpageaffordhtldlsviewmoredetailsa">View More details</a></div>
                </Col>
                   
                  ))}
                 
                </Row>{" "}
              </div>
            ))
          : null}

                
              </Carousel>
              <div className="vl-offers-link"><a href="#">View all flight offers</a></div>
            </div>
          </div>
      </section>  */}





      {/* <section className="home-section">
          <div class="home-section-heading-wrap home-section-heading-wrap-normal">
            <div class="container">
              <div className="home-section-heading-bottom">
                <h3 class="home-section-heading">Cheap Flights Middle East</h3>
                <p class="home-section-subheading">We have more than 10 years of experience providing cheap flights to middle east</p>
              </div>
            </div>
          </div>
          <div className="home-section-content">
            <div className="home_carousel blog_grid blog_grid_carousel">
            <Row>

            {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
            <>
            {cityHotelData[cityHotel].map((item, i) => (
              <Carousel
                slidesToShow={4}
                slidesToScroll={1}
                centerMode={true} 
                arrows={true} 
                infinite={true} 
                autoplaySpeed={2000}
                responsive={[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                
                <Col className="gutter-row" xs={13} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Card className='affordCard affordCard1htl'
                        hoverable
                        style={{
                            // width: 250,
                            borderBottom: `2px solid #00c8f5`
                        }}
                        cover={<img alt="example" style={{ height: '210px' }} 
                        src={item.HotelImage1
                                  ? `${BASE}${item.HotelImage1.substring(1)}`
                                  : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                      .default
                               }   
                              //  alt="Hotel Image"
                               
                        />}
                    >
                        <Meta className='affordCardTitle' title={item.Title} />
                        <p className="affordCard1htlnights"><span><i className="fa fa-building-o" /></span> 22 Nights</p>
                        <div className='affordCard1htldates'><div className='datespan'>04, Mar 2020</div><div className=''>26, Mar 2020</div></div>                       

                        <Rate className='affordcardrating affordCard1htlrating' />
                        <div className="affordCard1htlfromcont"><span className="affordCard1htlfromtext">From</span></div>
                        <div className="affordCard1htlperperson"><span className="affordCard1htlperpersonprice"> &euro; 1500</span> Per Person</div>
                        <div className=''><span className='cardRating'>8.0 out of 10</span></div>
                        <div className='cardcity'>{cityHotel}</div>
                    </Card>
                    <div className="htlpageaffordhtldlsviewmoredetails"><a className="htlpageaffordhtldlsviewmoredetailsa">View More details</a></div>
                </Col>
  
              </Carousel>
               ))}
               </>
                 ))
                 : null}
               </Row>

              <div className="vl-offers-link"><a href="#">View all flight offers</a></div>
            </div>
          </div>
      </section>    */}


     


      


      <section className="home-section">
        <CheapHotelDestinations />
      </section>

      <section className="home-section">
        <FindAffordableHotelDeals />
      </section>

      <section className="save-upto">
        <div className="container">
          <div className="save-upto-inner">
            <img
              src={require("../../../assets/images/mobile-app-screenshot.webp").default}
              className="mbl-app-screenshot"
              alt=""
            />
            
            <p className="save-upto-text">
              save up to 10% when you book with our friendly Mobile APP</p>
            
            <div className="mbl-app-store-logos">
                <img
                  src={require("../../../assets/images/download-apple-store.webp").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/download-google-play.webp").default}
                  alt=""
                />
            </div>
          </div>
        </div>
      </section>
      <section class="paysocial-wrap">
        <div className="container">
          <div className="paysocial-inner">
              <div className="paysocial-col paysocial-col-left">
                <div>
                  <p>We accept most of major<br></br>debit and credit cards</p>
                  <img
                    src={require("../../../assets/images/paycards-img.webp").default}
                    alt=""
                  />
                  <ul className="footer-visa-images e-hide">
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../../assets/images/visa-footer-1.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../../assets/images/visa-footer-2.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../../assets/images/visa-footer-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      {" "}
                      <img
                        className=""
                        src={
                          require("../../../assets/images/visa-footer-4.png")
                            .default
                        }
                        alt=""
                      />
                    </li>
                  </ul>
                </div>

                <div>
                  <p>Your data and online<br></br>purchase protected</p>
                  <img
                    src={require("../../../assets/images/geotrust-logo.webp").default}
                    alt=""
                  />
                </div>
              </div>

              <div className="paysocial-col paysocial-col-right">
                <ul className="trust-protection-list">
                  <li>
                    <p>We are ATOL Protected</p>
                    <img
                        src={require("../../../assets/images/atol-protected1.webp").default}
                        alt=""
                      />
                  </li>
                  <li>
                    <p>We are financially Protected</p>
                    <img
                        src={require("../../../assets/images/financially-protected.webp").default}
                        alt=""
                      />
                  </li>
                  <li>
                    <p>We are ATOL Protected</p>
                    <img
                        src={require("../../../assets/images/atol-protected2.webp").default}
                        alt=""
                      />
                  </li>
                </ul>
                <div>
                  <p>Follow is on social media for more travel news</p>
                  <div className="social_icons">
                    <a href="#" target="_blank">
                      <img
                        src={require("../../../assets/images/icon-facebook.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../../assets/images/icon-linkedin.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../../assets/images/icon-google.webp").default}
                        alt=""
                      />
                    </a>
                    <a href="#" target="_blank">
                      <img
                        src={require("../../../assets/images/icon-instagram.webp").default}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

        {/* {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
              <div key={index}>
                <Row gutter={[6, 6]}>
                  <Col md={24} xs={24}>
                    <p className="text-center font-weight-bold">{cityHotel}</p>
                  </Col>
                  {cityHotelData[cityHotel].map((item, i) => (
                    <Col md={6} xs={24} key={i}>
                      <Link to={getHotelUrl(item.SearchURL)} className="d-block">
                        <img
                          className="cart-shopping-img"
                          src={
                            item.HotelImage1
                              ? `${BASE}${item.HotelImage1.substring(1)}`
                              : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                  .default
                          }
                          alt="Hotel Image"
                        />
                      </Link>
                      <p className="text-center font-weight-bold mb-0">
                        {item.Title}
                      </p>
                    </Col>
                  ))}
                </Row>{" "}
              </div>
            ))
          : null} */}
      </div>

      {/* <section className="hotels_advantages">
        <div className="hotels-advantages-wrapper">
          <div className="hotels-adv-box left-box">
            <img
              src={
                require("../../../assets/images/hotels/save_up_to.png").default
              }
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> SAVE UP TO 30%!</p>
              <small>
                We've made deals with over 1.3 M hotels so you can book up to
                30% cheaper
              </small>
            </div>
          </div>
          <div className="hotels-adv-box right-box">
            <img
              src={require("../../../assets/images/hotels/no_tf.png").default}
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> NO HIDDEN CHARGES</p>
              <small>
                We tell you exactly what you'll pay from the very beginning -
                transparent reservation policies mean no nasty surprises
              </small>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="queries_about_hotels">
        <div className="query-container">
          <div className="query-hotels-heading">
            <h2>Any questions about hotel or your booking?</h2>
            <p>You will find the answers in our Travel Guide</p>
          </div>
          <div className="query-hotels-body">
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-1.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">What do hotel stars mean?</a>
                  <p>
                    The number of stars indicates the standard and amenities
                    that you can expect in a hotel. The more, the more luxurious
                    the conditions are. However,..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-2.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Different types of hotel rooms</a>
                  <p>
                    Hotel rooms may be divided due to the number and type of
                    beds. Single room (SGL) – a room for one guest, with a
                    single bed. Depending on the..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-3.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Types of breakfast</a>
                  <p>
                    In different hotels around the world you can find various
                    kinds of breakfast. It's worth checking the breakfast offer
                    during the booking process. We..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">
                    Check-in procedures for hotels, apartments, guest houses
                  </a>
                  <p>
                    All hotels or similar establishments usually require guests
                    to..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">Types of beds</a>
                  <p>There are several basic types of hotel beds: single..</p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">
                    Additional services available and offered in hotels
                  </a>
                  <p>
                    Many hotels offer additional services such as: wake-up
                    calls, laundry..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
          </div>
        </div>
      </section> */}
      <section className="top-route-bb">
        <Reviews serviceType={2} />
      </section>
      <section className="discounts_container">
        <Subscribe />
      </section>
    </div>
  );
};

export default HotelSearch;
