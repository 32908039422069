import React from "react";
import { Helmet } from "react-helmet";
import "../BusSearch/BusSearch.scss";
import Banner from "../../../components/banner/Banner";
import Buses from "../../../components/buses-search/Buses";
import * as ANTD from "antd";
import TopBuses from "../TopBuses/TopBuses";
import { useSytContext } from "../../../common/providers/SytProvider";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
function BusSearch() {
  const { busBanners } = useSytContext();
  return (
    <div>
      <Helmet>
        <title>Book Bus Tickets Online- {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="Book bus ticket with HoldTrip and get huge discounts. Save more on your online bus booking. Volvo bus booking, sleeper, AC, NON AC, Business Class, Semi Sleeper."
        />
      </Helmet>
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={busBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Book bus tickets with cancellation option</h2>
        </div>
        <div className="buses-search">
          <Buses />
        </div>
      </div>

      <section className="bus-support-24">
        <div className="container">
          <ANTD.Row>
            <ANTD.Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  {/* <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p> */}
                </div>
              </div>
            </ANTD.Col>
            <ANTD.Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  {/* <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p> */}
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </section>

      <div className="container bus-inner-trvels">
        <ANTD.Card>
          <ANTD.Row>
            <ANTD.Col md={24} xs={24} className="">
              <h5 className="tickets-book-trv">
                Book SRTC (State Road Transport Corporation) Bus Tickets
              </h5>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-1.png").default}
                alt=""
              />
              <p>APSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-2.png").default}
                alt=""
              />
              <p>TSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-3.png").default}
                alt=""
              />
              <p>KERELA RTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-4.png").default}
                alt=""
              />
              <p>HRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-5.png").default}
                alt=""
              />
              <p>GSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-6.png").default}
                alt=""
              />
              <p>OSRTC</p>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-7.png").default}
                alt=""
              />
              <p>MSRTC</p>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-8.png").default}
                alt=""
              />
              <p>RSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-9.png").default}
                alt=""
              />
              <p>OSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-10.png").default}
                alt=""
              />
              <p>BSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-11.png").default}
                alt=""
              />
              <p>UPSRTC</p>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Card>

        <ANTD.Row className="travel-locations">
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-1.png").default}
              alt=""
            />
            <p>100000</p>
            <p className="text-case-font">ROUTES</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-2.png").default}
              alt=""
            />
            <p>2500</p>
            <p className="text-case-font">BUS OPERATORS</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-3.png").default}
              alt=""
            />

            <p className="text-case-font">INSTANT E-TICKET & CANCELLATION </p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-4.png").default}
              alt=""
            />

            <p className="text-case-font">HAPPY CUSTOMERS</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-5.png").default}
              alt=""
            />
            <p>24/7</p>
            <p className="text-case-font">CUSTOMER CARE</p>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Card className="bdr-trv-bus">
          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <h3 className="booking-seletion">
                Why HoldTrip for Bus Ticket Booking
              </h3>
              <h5>Affordable travel</h5>
              <p className="mt-3-p">
                The bus is an excellent low-cost choice. On average, you can
                find the best bus ticket prices by booking in advance; the
                earlier you book your bus trip generally the cheaper your
                tickets will be!
              </p>
              <h5>Hassle-free travel</h5>
              <p className="mt-3-p">
                The bus is one of the easiest modes of transportation for
                city-to-city travel, enjoy the hassle-free travel experience the
                bus offers. It’s simplicity means that you can pick the best
                route for your trip and travel at your own pace.
              </p>

              <h5>Sustainability</h5>
              <p className="mt-3-p">
                Buses have the lowest CO2 emissions of any mode of city-to-city
                transportation. This makes the bus a very sustainable way to
                travel! Each time you take the bus instead of flying you are
                making the planet a greener place
              </p>

              <h3 className="booking-seletion">
                Benefits of Booking Bus Tickets Online
              </h3>
              <p className="mt-3-p">
                Booking bus tickets online with HoldTrip has solved many
                problems that people face while booking their tickets at offline
                counters or travel agents.
              </p>
              <ul className="offline-bus-bokk">
                <li>
                  Avoid standing in long queues at offline bus ticket counters
                </li>
                <li>Avoid travel agents</li>
                <li>Choose from multiple bus operators</li>
                <li>Book both Private and SRTC bus tickets online</li>
                <li>Check bus ticket availability online</li>
                <li>
                  Get Bus timings, ticket price, boarding & dropping point
                  details online
                </li>
                <li>Access to payment partner discounts and cashback offers</li>
                <li>Free cancellation feature</li>
                <li>24/7 customer support</li>
                <li>Each and every transaction is simple, safe and secure.</li>
              </ul>

              <h3 className="booking-seletion">
                How to book bus tickets online on HoldTrip
              </h3>
              <p className="mt-3-p">
                Enter your departure city, arrival city, and travel dates.{" "}
                <b>Click Search!</b>
              </p>
              <p>
                Compare bus schedules and companies. Use the filters (cheapest
                bus, fastest, earliest, latest) to sort your results.
              </p>
              <p>Select the bus trip that fits your travel needs.</p>
              <p>Pay securely with your preferred method of payment.</p>
              <p>Download your mobile ticket or print your paper ticket.</p>
              {/* <p>
                <b>Happy travels!</b>
              </p> */}
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Card>
      </div>
      <section className="top-route-bb new-bus-top">
        <TopBuses />
      </section>
      <section className="top-route-bb new-bus-top">
        <Reviews serviceType={3} />
      </section>
    </div>
  );
}

export default BusSearch;
