import React, { useState, useEffect, useRef } from "react";
import { Button, Col, DatePicker, Dropdown, Form, Input, Menu, Radio, Rate, Row, Select } from "antd";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import { useHistory } from "react-router-dom";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import CountryList from "../../common/CountryList";
import moment from "moment";
import queryString from "query-string";
import { CalendarOutlined, DownOutlined, EnvironmentOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import "../Hotels/Hotels.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Hotels = (props) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";

  const returnDateBox = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [checkInDate, setCheckInDate] = useState(moment());
  const [checkOutDate, setCheckOutDate] = useState(moment());

  const city = useRef(null);
  const dateBox = useRef(null);
  const ccode = useRef(null);

  const defHotelPaxInfo = [
    {
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    },
  ];

  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];
  const validateMessages = {
    required: "",
  };

  const [rooms, setRooms] = useState(false);
  const searchBtn = useRef();
  const toggleCount = () => {
    setRooms((prev) => !prev);
  };

  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    });

    setHotelPaxInfo(paxInfo);
  };

  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };

  const submitForm = (values) => {
    let formData = {
      checkInDate: moment(values.checkInDate).format(oriDateFormat),
      checkOutDate: moment(values.checkOutDate).format(oriDateFormat),
      hotelCityCode: values.hotelCityCode,
      roomGuests: JSON.stringify(hotelPaxInfo),
      nationality: values.nationality,
      countryCode: values.nationality,
      currency: "INR",
      traceId: "string",
    };

    const query = queryString.stringify(formData);
    history.push("/hotels/listing?" + query);

    if (props.modifySearch) {
      props.hotelSearchAPI();
    }
  };

  useEffect(() => {
    if (props.modifySearch) {
      const hotelSearchParams = queryString.parse(window.location.search);
      let roomDetails = JSON.parse(hotelSearchParams.roomGuests);

      form.setFieldsValue({
        hotelCityCode: hotelSearchParams.hotelCityCode,
        checkInDate: moment(hotelSearchParams.checkInDate, "YYYY-MM-DD"),
        checkOutDate: moment(hotelSearchParams.checkOutDate, "YYYY-MM-DD"),
        nationality: hotelSearchParams.nationality,
      });
      setHotelPaxInfo(roomDetails);
    }
  }, []);

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(checkOutDate).startOf("day");
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = "";

    if (momentdate) {
      originDate = momentdate ? moment(momentdate).startOf("day") : "";
      setCheckInDate(momentdate);
    }
    momentdate=  moment(momentdate).add(1, "days");
    setCheckOutDate(momentdate);
    const toDate = form.getFieldValue("checkOutDate");
    if (toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          checkOutDate: newTodate,
        });
      }
    }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };

  return (
    <div className="hotels_search_box">
       <h2 className="lowest-ne-p">BOOK A HOTEL</h2>
        <div className="class-container hero-searchtabs">
          <div>
            <Radio.Group
              // onChange={(e) => updateTravelType(e.target.value)}
              // value={travel_type}
            >
              <Radio
                // className={`bdr-icon-f ${
                //   travel_type === "oneWay" ? "tripTypeActive" : ""
                // }`}
                value={"oneWay"}
              >
                All inclusive
              </Radio>
              <Radio
                // className={`bdr-icon-f ${
                //   travel_type === "roundTrip" ? "tripTypeActive" : ""
                // }`}
                value={"roundTrip"}
              >
                Room only
              </Radio>
              {/* <Radio value={"multidestination"}>Multi-city</Radio> */}
            </Radio.Group>
          </div>
          {/* <div className="select_wrapper">
            <span className="class-type">Class :</span>
            <Select
              value={cabinClass}
              className="classTypeSelect"
              onChange={updateCablinClass}
              style={{ width: "150px" }}
            >
              <Option value="Economy">Economy</Option>
              <Option value="premiumEconomy">Economy Premium</Option>
              <Option value="Business">Business</Option>
              <Option value="first">First Class</Option>
              <Option value="premiumFirst  ">Premium First Class</Option>
            </Select>
          </div> */}
        </div>
      <div className="hotels-wrapper hero-searchcontent">
        <Form
          form={form}
          initialValues={{
            nationality: "IN",
          }}
          className="hotel-search-form hotel-bg-panel-all"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row className="search-row">
            <Col md={6} xs={24} className="from-to-inputs hotel-select-jun">
            <div className="hero-searchcol hotelsearchformitemheight">
            <EnvironmentOutlined />
            <label className="hero-searchlabels">Destination</label>
              <HotelAutoSelect                
                formItemProps={{
                  name: "hotelCityCode",
                  rules: [
                    { required: true, message: "Please Specify The City" },
                  ],
                }}
                selectProps={{ size: "large", placeholder: "Enter Hotel name/City or Country" }}
                api={"hotels-v2/searchhotelcity/"}
                refName={city}
                focusRef={dateBox}
                handleOnSubmit={handleOnSubmit}
                modifySearch={props.modifySearch ? props.modifySearch : false}
              />
              </div>
            </Col>
            {/* 
            <Col
              md={8}
              xs={24}
              className="from-to-inputs hotel-select-jun right-line-bdr"
            >
              <Form.Item
                className="checin-cheout-mob"
                name="checkin_checkout"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Dates",
                  },
                ]}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  placeholder={["Check-In", "Check-Out"]}
                  size="large"
                  format={dateFormat}
                  disabledDate={disabledDate}
                  // onChange={(_, val) => {
                  //   if (val[1]) {
                  //     ccode.current.focus();
                  //   }
                  // }}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => setShowDate((prev) => !prev)}
                />
              </Form.Item>
            </Col> */}

            <Col md={4} xs={24} className="">
            <div className="hero-searchcol hotelsearchformitemheight">
              <CalendarOutlined />
            <label className="hero-searchlabels">Check-in</label>
              <Form.Item
                name="checkInDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                {/* <CalendarOutlined /> */}
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledOriginDate}
                  onChange={(date, dateString) => {
                    setShowDate((prev) => !prev);
                    if (date) {
                      onChangeOriginDate(date, dateString);
                      setShowReturnDate(true);
                      returnDateBox.current.focus();
                    }
                  }}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => {
                    setShowDate((prev) => !prev);
                  }}
                  placeholder="DD/MM/YY"
                />
              </Form.Item>
              </div>
            </Col>

            <Col md={4} xs={24} className="">
            <div className="hero-searchcol hotelsearchformitemheight">
              <CalendarOutlined />
            <label className="hero-searchlabels">Check-out</label>
              <Form.Item
                className="returnDate"
                name="checkOutDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                {/* <CalendarOutlined /> */}
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={false}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledDestDate}
                  ref={returnDateBox}
                  open={showReturnDate}
                  defaultPickerValue={checkInDate}
                  onOpenChange={() => {
                    setShowReturnDate((prev) => !prev);
                  }}
                  placeholder="DD/MM/YY"
                />
              </Form.Item>
              </div>
            </Col>

            <Col md={3} xs={24} className="from-to-inputs hotel-select-jun">
            <div className="hero-searchcol hotelsearchformitemheight">   
              <EnvironmentOutlined />
            <label className="hero-searchlabels">Country</label>
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "Please Select Nationality",
                  },
                ]}
              >
                <Select
                  ref={ccode}
                  showSearch
                  size="large"
                  placeholder="Nationality"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {CountryList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              </div>
            </Col>

            <Col md={4} xs={24} className="from-to-inputs hotel-select-jun">
            <div className="hero-searchcol hotelsearchformitemheight">
              <UserOutlined />
            <label className="hero-searchlabels">Passenger</label>
              <Form.Item>
                <Input
                  value={
                    "Rooms: " + paxInfo.length + ", guests: " + getPaxCount()
                  }
                  size="large"
                  onClick={toggleCount}
                />
                <div className="add-room-block">
                  <div
                    className="pax-modal"
                    id="pax-modal"
                    style={{
                      display: rooms ? "block" : "none",
                    }}
                  >
                    <div className="pax-modal-wrapper">
                      <div className="pax-modal-arrow"></div>
                      <ul className="first-item">
                        {paxInfo.map((pax, index) => (
                          <HotelPassengers
                            pax={pax}
                            index={index}
                            updatePaxInfoFromChild={updatePaxInfoFromChild}
                            paxInfo={paxInfo}
                          />
                        ))}

                        <li className="add-room-btn" onClick={addRoom}>
                          Add room
                        </li>
                      </ul>

                      <div>
                        <Button
                          block
                          className="pax-ready-btn"
                          onClick={() => {
                            toggleCount();
                            if (rooms) searchBtn.current.focus();
                          }}
                        >
                          Ready
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
               </div>
            </Col>
            <Col md={3} xs={24}>
              <Button
                size="large"
                className="primary-btn hero-searchbtn"
                ref={searchBtn}
                htmlType="submit"
              >
                {/* shkhr cmntd <SearchOutlined /> */}
                 Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="hero-searchextra">
          <Row gutter={[12, 12]}>
              <Col md={12} xs={24}>
                <ul className="hero-searchextra-left">
                  <li>
                    <Dropdown 
                      overlay={( 
                        <Menu> 
                          <Menu.Item key="0"><p className="htlsrchstartsdropdown">One <Rate value={1} count={1} /></p></Menu.Item>
                          <Menu.Item key="0"><p className="htlsrchstartsdropdown">Two <Rate value={1} count={1} /></p></Menu.Item>
                          <Menu.Item key="0"><p className="htlsrchstartsdropdown">Three <Rate value={1} count={1} /></p></Menu.Item>
                          <Menu.Item key="0"><p className="htlsrchstartsdropdown">Four <Rate value={1} count={1} /></p></Menu.Item>
                          <Menu.Item key="0"><p className="htlsrchstartsdropdown">Five <Rate value={1} count={1} /></p></Menu.Item>
                        </Menu> 
                      )} 
                      trigger={['click']}> 
                      <a onClick={e => e.preventDefault()}> 
                        Star Rating <DownOutlined />
                      </a> 
                    </Dropdown> 
                  </li>

                  <li>
                    <Dropdown 
                      overlay={( 
                        <Menu> 
                          <Menu.Item key="0"><p className="htlsrchdropdownprice">$100 &nbsp; to &nbsp; $199</p></Menu.Item>
                          <Menu.Item key="1"><p className="htlsrchdropdownprice">$200 &nbsp; to &nbsp; $249</p></Menu.Item>
                          <Menu.Item key="2"><p className="htlsrchdropdownprice">$250 &nbsp; to &nbsp; $299</p></Menu.Item>
                          <Menu.Item key="3"><p className="htlsrchdropdownprice">$300 &nbsp; to &nbsp; $349</p></Menu.Item>
                          {/* <Menu.Item key="4"><p>$350 to $399</p></Menu.Item>
                          <Menu.Item key="5"><p>$400 to $449</p></Menu.Item> */}
                        </Menu> 
                      )} 
                      trigger={['click']}> 
                      <a onClick={e => e.preventDefault()}> 
                        Price range <DownOutlined />
                      </a> 
                    </Dropdown> 
                  </li>

                  {/* <li>
                    <Dropdown 
                      overlay={( 
                        <Menu> 
                          <Menu.Item key="0">Jan 09, 2025</Menu.Item>
                          <Menu.Item key="1">Jan 14, 2025</Menu.Item>
                          <Menu.Item key="2">Jan 25, 2025</Menu.Item>
                          <Menu.Item key="3">Feb 03, 2025</Menu.Item>
                          <Menu.Item key="4">Feb 24, 2025</Menu.Item>
                        </Menu> 
                      )} 
                      trigger={['click']}> 
                      <a onClick={e => e.preventDefault()}> 
                        Flexible Dates <DownOutlined />
                      </a> 
                    </Dropdown> 
                  </li> */}
                </ul>
              </Col>
              <Col md={12} xs={24}>
                <div className="hero-searchextra-right">
                  24 seven customer service available
                </div>
              </Col>
          </Row>
        </div>
    </div>
  );
};

export default Hotels;
