import React from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useHistory } from "react-router-dom";
import Flight, { FlightSelection } from "../Flight/Flight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";
import { useEffect, useState } from "react";
import "../SeperatedView/SeperatedView.scss";
import { useSytContext } from "../../../common/providers/SytProvider";
import { RightCircleOutlined } from "@ant-design/icons";

const SeperatedView = ({
  flightSearchObj,
  selectedFlight,
  combinedOnewayFlightList,
  changeFlightResultsView,
  setOnwardFlights,
  changeReturnFlights,
  setFlightFareRules,
  journeyType,
  showNetFare,
  promoData,
}) => {
  // console.log(combinedOnewayFlightList, "flight details");
  // console.log(flightSearchObj);
  const { flightSearchBanners } = useSytContext();
  const PromoData = promoData;
  // useEffect(() => {
  //   let visibleData = flightDetails.filter((item) => item.isVisible);
  //   setFlightList(visibleData);
  // }, [flightDetails]);
  let fareid = sessionStorage.getItem('FareId');
  let fareid1 = sessionStorage.getItem('FareId1');
 let  selectedFareid =fareid;
  const [flightList, setFlightList] = useState([]);
  useEffect(() => {
    let visibleData = combinedOnewayFlightList.filter((item) => item.isVisible);
    setFlightList(visibleData);
  }, [combinedOnewayFlightList]);
  let bannreIndex = -1;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const history = useHistory();
  const editOnclick = (type) => {
    if (type == 1) {
      setOnwardFlights();
    } else {
      changeReturnFlights();
    }
  };

  const goToCheckoutPage = () => {
    history.push("/flight/checkout");
  };

  const toolBreakCity = (breakCityDest) => {
    return (
      <div className="tooltip-data">
        <h4>Change</h4>
        <p>{breakCityDest}</p>
      </div>
    );
  };

  const getSelectedTotalFare = (selectedflightsArray) => {
    let totalAmount = 0;

    if (selectedflightsArray.length > 1) {
      totalAmount = selectedflightsArray.reduce((prev, cur) => {
        if(cur.isRoundTrip)
        {
          selectedFareid =fareid1;
        }
        return prev + Number(cur.fareFamilies.fareFamilies.filter(x=>x.fareId==selectedFareid)[0].totalFare);
      }, 0);
    }
    return Number(totalAmount).toFixed(2);
  };

  return (
    <div>
      {selectedFlight.length > 0 ? (
        <>
          <div className="edit-onward">
            <div className="card-results">
              <Row gutter={[0, 8]}>
                <Col md={18} sm={10} xs={24} className="flight-border-right">
                  <div className="card-results-travel-wrapper">
                    {/* <p className="title"> Onward Selection</p> cmntd by shkhr */}
                    <div className="card-results-travel-details">
                      <div className="itinary_wrapper">
                        <Row className="ticketTopSect">
                          <Col span={12}>
                            <div className="ticketTopSectContLeft">
                              <span className="ticketTopSectContLeftContry1"> DUBAI</span>  <span className="tcktTopsecIcon"><RightCircleOutlined className="topsecOrgicon" /></span> <span className="ticketTopSectContLeftContry2">LONDON</span>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="ticketTopSectContRight">
                              <span className="textFlighttimetoptcktsec">FLIGHT TIME</span>  <span className="textTimetoptcktsec">12H 10M</span> <span className="ticketTopSectContLeftContry1">RETURN FLIFGT</span>  <span className="tcktTopsecIcon"><RightCircleOutlined className="topsecOrgicon" /></span>
                            </div>
                          </Col>
                        </Row>
                        <div className="itinary-details">
                          <div className="itinary-flight-name">
                            <img
                              className="airline-img"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            />
                            {/*shkhr cmntd <p className="name">
                              {selectedFlight[0].airLineName}
                            </p> */}
                          </div>
                          <div className="fromTo-wrapper">
                            {/* flightTime added by shkhr */}
                            <div className="FlightTime">
                              <span>17:5</span>
                              <span>21:05</span>
                            </div>
                            <div className="cities-from-to">
                              <div className="loc_wrapper">
                                <span className="code">
                                  {selectedFlight[0].flightSegments[0].origin}
                                </span>

                                <div className="break-journey-city">
                                  <div className="inner_wrapper">
                                    {selectedFlight[0].flightSegments.map(
                                      (flightSegInfo, index) =>
                                        index !== 0 ? (
                                          <div
                                            className="stop_points"
                                            key={flightSegInfo.segId}
                                          >
                                            <Tooltip
                                              placement="top"
                                              title={toolBreakCity(
                                                flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName
                                              )}
                                              className="citynames-tooltip"
                                            >
                                              <span className="break-city-name pointer_cursor">
                                                {flightSegInfo.origin}
                                              </span>
                                            </Tooltip>
                                          </div>
                                        ) : null
                                    )}
                                  </div>
                                </div>

                                <span className="code">
                                  {
                                    selectedFlight[0].flightSegments[
                                      selectedFlight[0].flightSegments.length -
                                      1
                                    ].destination
                                  }
                                </span>
                              </div>
                            </div>
                            {/* arrive dept date shekhar added */}
                            <div className="arrive-dept-date">
                              <span className="dateDay">
                                {/* {dateFormat(
                                  flightInfo.flightSegments[0].departureDateTime,
                                  "dd mmm (ddd)"
                                )} */}
                                28 Nov (Thu)
                              </span>
                              <span className="dateDay">
                                {/* {dateFormat(
                                  flightInfo.flightSegments[0].departureDateTime,
                                  "dd mmm (ddd)"
                                )} */}
                                28 Nov (Thu)
                              </span>
                            </div>
                            {/* shkhr cmntd <div className="flight-stops-wrapper">
                              <span className="stops-wrapper">
                                {selectedFlight[0].flightSegments.length - 1 ===
                                0 ? (
                                  <>Direct</>
                                ) : (
                                  <>
                                    {selectedFlight[0].flightSegments.length -
                                      1}{" "}
                                    stops{" "}
                                  </>
                                )}{" "}
                                | {flightSearchObj.cabinClass}
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*shkhr cmntd <div className="btncontainer">
                      <p className="price">
                        <span> {activeCurrency}</span>
                        {currencyValue(
                          selectedFlight[0].fareFamilies.fareFamilies.filter(x=>x.fareId==fareid)[0]
                            .totalFare
                        )
                       }
                      </p>
                      <button
                        onClick={() => editOnclick(1)}
                        className="tg-primary-btn"
                      >
                        Change
                      </button>
                    </div> */}
                  </div>
                  {selectedFlight.length > 1 ? (
                    <div className="card-results-travel-wrapper">
                      {/* <p className="title"> Return Selection</p> shkhr cmntd */}
                      <div className="card-results-travel-details">
                        <div className="itinary_wrapper">
                        <Row className="ticketTopSect">
                          <Col span={12}>
                            <div className="ticketTopSectContLeft">
                              <span className="ticketTopSectContLeftContry1"> DUBAI</span>  <span className="tcktTopsecIcon"><RightCircleOutlined className="topsecOrgicon" /></span> <span className="ticketTopSectContLeftContry2">LONDON</span>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="ticketTopSectContRight">
                              <span className="textFlighttimetoptcktsec">FLIGHT TIME</span>  <span className="textTimetoptcktsec">12H 10M</span> <span className="ticketTopSectContLeftContry1">RETURN FLIFGT</span>  <span className="tcktTopsecIcon"><RightCircleOutlined className="topsecOrgicon" /></span>
                            </div>
                          </Col>
                        </Row>
                          <div className="itinary-details">
                            <div className="itinary-flight-name">
                              <img
                                className="airline-img"
                                src={selectedFlight[1].airLineLogo}
                                alt={selectedFlight[1].airLineName}
                              />
                              {/* <p className="name">
                                  {selectedFlight[1].airLineName}
                                </p> shkhr cmntd */}
                            </div>
                            <div className="fromTo-wrapper">
                              {/* flightTime added by shkhr */}
                              <div className="FlightTime">
                                <span>17:5</span>
                                <span>21:05</span>
                              </div>
                              <div className="cities-from-to">
                                <div className="loc_wrapper">
                                  <span className="code">
                                    {
                                      selectedFlight[1].flightSegments[0]
                                        .origin
                                    }
                                  </span>

                                  <div className="break-journey-city">
                                    <div className="inner_wrapper">
                                      {selectedFlight[1].flightSegments.map(
                                        (flightSegInfo, index) =>
                                          index !== 0 ? (
                                            <div
                                              className="stop_points"
                                              key={flightSegInfo.segId}
                                            >
                                              <Tooltip
                                                placement="top"
                                                title={toolBreakCity(
                                                  flightSegInfo.origin +
                                                  " " +
                                                  flightSegInfo.originName
                                                )}
                                                className="citynames-tooltip"
                                              >
                                                <span className="break-city-name pointer_cursor">
                                                  {flightSegInfo.origin}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : null
                                      )}
                                    </div>
                                  </div>

                                  <span className="code">
                                    {
                                      selectedFlight[1].flightSegments[
                                        selectedFlight[1].flightSegments
                                          .length - 1
                                      ].destination
                                    }
                                  </span>
                                </div>
                              </div>
                              {/* arrive dept date shekhar added */}
                              <div className="arrive-dept-date">
                                <span className="dateDay">
                                  {/* {dateFormat(
                                    flightInfo.flightSegments[0].departureDateTime,
                                    "dd mmm (ddd)"
                                    )} */}
                                  28 Nov (Thu)
                                </span>
                                <span className="dateDay">
                                  {/* {dateFormat(
                                    flightInfo.flightSegments[0].departureDateTime,
                                    "dd mmm (ddd)"
                                    )} */}
                                  28 Nov (Thu)
                                </span>
                              </div>

                              {/* shkhr cmntd <div className="flight-stops-wrapper">
                                  <span className="stops-wrapper">
                                    {selectedFlight[1].flightSegments.length -
                                      1 ===
                                    0 ? (
                                      <>Direct</>
                                    ) : (
                                      <>
                                        {selectedFlight[1].flightSegments
                                          .length - 1}{" "}
                                        stops{" "}
                                      </>
                                    )}{" "}
                                    | {flightSearchObj.cabinClass}
                                  </span>
                                </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*shkhr cmntd <div className="btncontainer">
                          <p className="price">
                            <span> {activeCurrency}</span>
                            {currencyValue(
                              selectedFlight[1].fareFamilies.fareFamilies.filter(x=>x.fareId==fareid1)[0].totalFare
                            )}
                          </p>
                          <button
                            onClick={() => editOnclick(2)}
                            className="tg-primary-btn"
                          >
                            Change
                          </button>
                        </div> */}
                    </div>
                  ) : null}
                </Col>{" "}
                {/* cmntd by shkhr
                 {selectedFlight.length > 1 ? (
                  <>
                    <Col
                      md={10}
                      sm={10}
                      xs={24}
                      className="flight-border-right"
                    >
                      <div className="card-results-travel-wrapper">
                        <p className="title"> Return Selection</p>
                        <div className="card-results-travel-details">
                          <div className="itinary_wrapper">
                            <div className="itinary-details">
                              <div className="itinary-flight-name">
                                <img
                                  className="airline-img"
                                  src={selectedFlight[1].airLineLogo}
                                  alt={selectedFlight[1].airLineName}
                                />
                                <p className="name">
                                  {selectedFlight[1].airLineName}
                                </p>
                              </div>
                              <div className="fromTo-wrapper">
                                <div className="cities-from-to">
                                  <div className="loc_wrapper">
                                    <span className="code">
                                      {
                                        selectedFlight[1].flightSegments[0]
                                          .origin
                                      }
                                    </span>

                                    <div className="break-journey-city">
                                      <div className="inner_wrapper">
                                        {selectedFlight[1].flightSegments.map(
                                          (flightSegInfo, index) =>
                                            index !== 0 ? (
                                              <div
                                                className="stop_points"
                                                key={flightSegInfo.segId}
                                              >
                                                <Tooltip
                                                  placement="top"
                                                  title={toolBreakCity(
                                                    flightSegInfo.origin +
                                                      " " +
                                                      flightSegInfo.originName
                                                  )}
                                                  className="citynames-tooltip"
                                                >
                                                  <span className="break-city-name pointer_cursor">
                                                    {flightSegInfo.origin}
                                                  </span>
                                                </Tooltip>
                                              </div>
                                            ) : null
                                        )}
                                      </div>
                                    </div>

                                    <span className="code">
                                      {
                                        selectedFlight[1].flightSegments[
                                          selectedFlight[1].flightSegments
                                            .length - 1
                                        ].destination
                                      }
                                    </span>
                                  </div>
                                </div>

                                <div className="flight-stops-wrapper">
                                  <span className="stops-wrapper">
                                    {selectedFlight[1].flightSegments.length -
                                      1 ===
                                    0 ? (
                                      <>Direct</>
                                    ) : (
                                      <>
                                        {selectedFlight[1].flightSegments
                                          .length - 1}{" "}
                                        stops{" "}
                                      </>
                                    )}{" "}
                                    | {flightSearchObj.cabinClass}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btncontainer">
                          <p className="price">
                            <span> {activeCurrency}</span>
                            {currencyValue(
                              selectedFlight[1].fareFamilies.fareFamilies.filter(x=>x.fareId==fareid1)[0].totalFare
                            )}
                          </p>
                          <button
                            onClick={() => editOnclick(2)}
                            className="tg-primary-btn"
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </Col>
                   </>
                ):null} */}
                {selectedFlight.length > 1 ? (
                  <>
                    <Col md={6} sm={4} xs={24}>
                      <div className="selected-flights-price-wrapper">
                        <div className="selected-flights-price">
                          <div className="btnBlockTotalPriceText">Total Price</div>
                          <div className="price-box">
                            <p className="totalFare currencyType">
                              INR {getSelectedTotalFare(selectedFlight)}
                            </p>
                          </div>

                          <Button className="choose-button2" onClick={goToCheckoutPage}>
                            {/* Book */}
                            SELECT
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>

          <div className="edit-onward">
            <p className="returntext">
              Select For {journeyType === "return" ? "Return" : "Onward"}{" "}
            </p>
          </div>
        </>
      ) : null}
      {flightList.length > 0 ? (
        flightList.map((flight, i) => {
          let filteredPromoData =
            PromoData.length > 0
              ? PromoData?.filter((item) => item.airlineCode === flight.airLine)
                  .length > 0
                ? promoData?.filter(
                    (item) =>
                      item.airlineCode?.toUpperCase() ===
                      flight.airLine?.toUpperCase()
                  )
                : promoData?.filter((item) => item.airlineCode === "All")
              : null;
          return (
            <>
              {i != 0 && i % 3 == 0 && flightSearchBanners[++bannreIndex] && (
                <div className="combine-roundtrip-view">
                  <img
                    style={{
                      width: "100%",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      flightSearchBanners[bannreIndex].path.substring(1)
                    }
                    data={
                      flightSearchBanners[bannreIndex + 1]
                        ? null
                        : (bannreIndex = -1)
                    }
                  />{" "}
                </div>
              )}
              <div className="combine-roundtrip-view">
                <Row>
                  <Col md={18} xs={24}>
                    <Flight
                      flightInfo={flight}
                      key={flight.flightId}
                      flightSearchObj={flightSearchObj}
                      setFlightFareRules={setFlightFareRules}
                      fareFamilies={flight.fareFamilies}
                      promoData={filteredPromoData}
                    />
                  </Col>
                  <Col md={6} xs={24}>
                    <div className="choose-btn-wrapper">
                      <FlightSelection
                        flightInfo={flight}
                        changeFlightResultsView={changeFlightResultsView}
                        flightSearchObj={flightSearchObj}
                        selectedFlight={selectedFlight}
                        showNetFare={showNetFare}
                        journeyType={journeyType}
                      />
                    </div>
                  </Col>
                </Row>
              </div>{" "}
            </>
          );
        })
      ) : (
        <CustomNoResultFound title={"No Flights Available"} />
      )}
    </div>
  );
};

export default SeperatedView;
