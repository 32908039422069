import React from "react";
import FlightSearch from "../flight-search/FlightSearch";
import Hotels from "../Hotels/Hotels";
import "../search-box/SearchBox.scss";
import { Tabs } from "antd";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

import ActivitiesSearchBox from "../Activities/ActivitiesSearchbox/ActivitiesSearchbox";
import BuilpackageSearchBox from "../BuildYourPackage/Buildpackagesearchbox/BuildPackageBox";
import Buses from "../buses-search/Buses";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";



const SearchBox = () => {

  let location=useLocation()
  let selectedTab=  location.pathname.split("/").filter(Boolean)[0]
  let activeKey=selectedTab=="Flights"?"1":selectedTab=="hotels"?"3":"1"
  // console.log(selectedTab)

  const { TabPane } = Tabs;
  return (
    <div className="global_search_panel">
      <div className="search-wrapper">
      <Tabs defaultActiveKey={activeKey}>

        {/* <Tabs defaultActiveKey="1"> */}
          <TabPane
            tab={
              <span>
                <i className="fa fa-plane"></i> Flights
              </span>
            }
            key="1"
          >
            <FlightSearch />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-bus" /> Buses
              </span>
            }
            key="2"
          >
            <Buses />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-building-o" /> Hotels
              </span>
            }
            key="3"
          >
            <Hotels />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-binoculars" /> Activities
              </span>
            }
            key="4"
          >
            <ActivitiesSearchBox />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-th-large" /> Build Package
              </span>
            }
            key="5"
          >
            <BuilpackageSearchBox />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SearchBox;
