import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  Col,
  Radio,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Skeleton,
  Checkbox,
  Collapse,
  Button,

} from "antd";

import { Link, useHistory } from "react-router-dom";

import { GlobalStatesContext } from "../../common/providers";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import { getPassengerData } from "../../helpers/PassegerData";
import FlightDetailsCard from "./Flight-Details-Card";
import FareSummaryCard from "./FareSummaryCard";
import BookPayCard from "./Book-Pay-Card";
import ApiClient from "../../helpers/ApiClient";

import moment from "moment";

import FlightGrandTotalCard from "./FlightGrandTotalCard";
import CountryList from "../../common/CountryList";

import { PaxAgeValidator } from "../../helpers/CustomValidators";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
  CheckOutlined,
  QuestionCircleFilled
} from "@ant-design/icons";

import "./FlightsCheckout.scss";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";

const { Option } = Select;
const { Panel } = Collapse;
let dateformat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const validateMessages = {
  required: "",
};
const abbrevations = [
  "MR",
  "Mr",
  "mr",
  "mR",
  "MRS",
  "Mrs",
  "mrs",
  "mrS",
  "mRs",
  "MS",
  "ms",
  "Ms",
  "mS",
  "Mstr",
  "mstr",
  "Miss",
  "miss",
];
let OutbondMealArray = [];
let InbondMealArray = [];
let OutbondBaggeArray = []
let InbondBaggeArray = []
const FlightCheckout = () => {

  const {
    isLogin: { flag },
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { insuranceData, selectedInsuranceData,selectedoutMeal,SelectedoutbondBagges,SelectedInbondMeal,SelectedInbondBagges,isInternational },
    },
    setSelectedInsuranceData,
    RemovePromoConvFee,
    setSelectedoutbondMeal,
    setSelectedoutbondBagges,
    setSelectedInbondMeal,
    setSelectedInbondBagges,
    setIsInternational
  } = useContext(GlobalStatesContext);

  const {
    state: { selectedFlight, flightSearchObj },
    updateFlightAirPrice,
    updateAirBookState,
  } = useFlightContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();

  let history = useHistory();

  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  const [contact_form] = Form.useForm();
  const [passenger_form] = Form.useForm();
  const [gst_form] = Form.useForm();
  const [airPriceResp, setAirPriceResp] = useState({ flightDetails: [] });
  const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGstRequired, setIsGstRequired] = useState(false);
 // const [isInternational, setIsInternational] = useState(0);
  const [passengerRequiredFields, setpassengerRequiredFields] = useState({});
  const [additional_services, setAdditional_services] = useState([]);
  const [SelectedServices, setSelcetedServices] = useState({
    0: { keys: [], services: [] },
    2: { keys: [], services: [] },
    1: { keys: [], services: [] },
    3: { keys: [], services: [] },
    4: { keys: [], services: [] },
    5: { keys: [], services: [] },
    6: { keys: [], services: [] },
    7: { keys: [], services: [] },
    8: { keys: [], services: [] },
  });

  const [travellersListResp, setTravellersListResp] = useState({
    Travellers: [],
  });

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };


  const handleMealChange = (val,type) => {
    if(val !=="")
    {
   let values= JSON.parse(val)
   if(type=="outbound"){
   OutbondMealArray = OutbondMealArray.filter(item => item.paxNo !== values.paxNo);
   
    OutbondMealArray.push({"paxNo" :values.paxNo, "Amount" : parseFloat(values.amount)  })
   let total = OutbondMealArray.reduce( function(a, b){
      return a + b.Amount;
      }, 0);
    setSelectedoutbondMeal(total);
   }
   else{
    InbondMealArray = InbondMealArray.filter(item => item.paxNo !== values.paxNo);
   
    InbondMealArray.push({"paxNo" :values.paxNo, "Amount" : parseFloat(values.amount)  })
   let total = InbondMealArray.reduce( function(a, b){
      return a + b.Amount;
      }, 0);
      setSelectedInbondMeal(total);
    
   }
    }else{
      setSelectedoutbondMeal(0);
      setSelectedInbondMeal(0);
    }
  };
  const handleBaggesChange = (val,type) => {
    if (val !== ""){
    let values= JSON.parse(val)
    if (type === "outbound") {
      OutbondBaggeArray = OutbondBaggeArray.filter(item => item.paxNo !== values.paxNo);
   
      OutbondBaggeArray.push({"paxNo" :values.paxNo, "Amount" : parseFloat(values.amount)  })
     let total = OutbondBaggeArray.reduce( function(a, b){
        return a + b.Amount;
        }, 0);
        setSelectedoutbondBagges(total);
      //setSelectedoutbondBagges(values.amount);
    } else {
      InbondBaggeArray = InbondBaggeArray.filter(item => item.paxNo !== values.paxNo);
   
      InbondBaggeArray.push({"paxNo" :values.paxNo, "Amount" : parseFloat(values.amount)  })
     let total = InbondBaggeArray.reduce( function(a, b){
        return a + b.Amount;
        }, 0);
      setSelectedInbondBagges(total);
    }
  } else{
    setSelectedoutbondBagges(0);
    setSelectedInbondBagges(0);
  }
  };
  const getFlightIds = () => {
    if (flightSearchObj.airTravelType === "oneWay") {
      return [selectedFlight[0].flightId];
    } else {
      return selectedFlight.map((flightID) => flightID.flightId);
    }
  };

  const getAirPrice = () => {
    if (flightSearchObj) {
      setIsLoading(true);
      let fareid = sessionStorage.getItem('FareId');
      let roundtripfareid = sessionStorage.getItem('FareId1');
      let airPriceReqObj = {
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        traceId: flightSearchObj.traceId,
        flightIds: getFlightIds(),
        airTravelType: flightSearchObj.airTravelType,
        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
        fareId:fareid??null,
        roundtripfareid:roundtripfareid??null,
        gstDetails: {
          gstAddressLine1: "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: "",
          gstNumber: "",
          gstPhoneNo: "",
          gstCompanyName: "",
        },
      };

      ApiClient.post("flights/airPrice", airPriceReqObj)
        .then((resp) => {
          resp?.data?.flightDetails[0]?.flightSegments.map(x=>{
            if(x.arrivalCountryName !="India" || x.departureCountryName !="India"){
              setIsInternational(1);
            }
          })
        //  if(resp?.data?.flightDetails[0]?.flightSegments[0].arrivalCountryName !="India" || resp?.data?.flightDetails[0]?.flightSegments[0]?.departureCountryName !="India"){
        //   setIsInternational(1);
        //  }

          return resp;
        })
        .then((resp) => {
          setUpFromApiResponse(resp);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.message) message.error(err?.message);
        });
    } else {
      setIsLoading(false);
    }
  };

  const setUpFromApiResponse = (resp) => {
    if (resp.statusCode === 200) {
      setAirPriceResp(resp.data);
      paxInfoSections(resp.data);
      handleGstRequired(resp.data);
      updateFlightAirPrice(resp.data);
      setAdditional_services(resp.data);
      setIsGstRequired(
        resp.data.flightDetails[0].fareFamilies[0].isGstMandatory
      );
    } else if (resp.statusCode === 400) {
      if (resp?.errors) {
        let errorsArr = resp.errors.filter(
          (item) => item.errorCode === "Status204NoContent"
        );
        if (errorsArr?.length > 0) {
          message.error(errorsArr[0].errorDetail, 4);
        }
      }
    }
    setIsLoading(false);
  };

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            mobile: data.Mobile,
            email: data.Email,
            // areaCode: data?.DailingCode ? `+${data?.DailingCode}` : "",
            address: data?.Address1 ? data?.Address1 : "Manjusha Building, 806, 8th Floor, Nehru Place",
            city: data?.city ? data?.city : "Delhi",
            state: data?.state ? data?.state : "Delhi",
           
          });
        }
    
      });
    }
  };

  useEffect(() => {
    RemovePromoConvFee();
    getAirPrice();
    loadpassengerData();
    startCountdown(13);
  }, []);

  useEffect(() => {
    fetchTravellerDetails();
  }, [user]);

  const handleGstRequired = (data) => {
    let count = 0;
    if (data?.flightDetails?.length > 0) {
      data.flightDetails.forEach((details) => {
        if (details?.fareFamilies?.length > 0) {
          if (details.fareFamilies[0].isGstMandatory) {
            count++;
          }
        }
      });
    }
    if (count > 0) {
      setIsGstRequired(true);
    }
  };

  const paxInfoSections = (airPriceRespObj) => {
    let paxInfoArr = [];
    let allPaxInfoArr = [];
    for (let i = 0; i < flightSearchObj.adultCount; i++) {
      paxInfoArr.push({
        type: "ADT",
      });
    }

    for (let i = 0; i < flightSearchObj.childCount; i++) {
      paxInfoArr.push({
        type: "CHD",
      });
    }

    for (let i = 0; i < flightSearchObj.infantCount; i++) {
      paxInfoArr.push({
        type: "INF",
      });
    }
    if (airPriceRespObj.passengerRequiredFields.length > 0)
      setpassengerRequiredFields(airPriceRespObj.passengerRequiredFields[0]);

    paxInfoArr.map((obj, index) => {
      let singlePaxObj = {
        title: obj.type === "ADT" ? "MR" : "MSTR",
        firstName: "",
        lastName: "",
        paxType: "",
        id: index,
      };

      airPriceRespObj.passengerRequiredFields
        .filter((requiredFiledsObj) => requiredFiledsObj.paxType === obj.type)
        .map((paxReqFieldsObj) => {
          Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
            if (
              paxReqFieldsObj[paxReqKeys] === true &&
              paxReqKeys !== "address"
            ) {
              singlePaxObj[paxReqKeys] = "";
              if (
                paxReqKeys === "passengerNationality" ||
                paxReqKeys === "passportIssuedCountry"
              ) {
                singlePaxObj[paxReqKeys] = "Indian";
              }
            }
          });
          singlePaxObj["paxType"] = paxReqFieldsObj.paxType;
        });

      allPaxInfoArr.push(singlePaxObj);
    });

    setTotalPaxArr(allPaxInfoArr);
  };

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((contact_data) => {
        passenger_form
          .validateFields()
          .then((passegers_data) => {
            gst_form
              .validateFields()
              .then((gstDetails) => {
                if (!isGstRequired) {
                  let isallFilled =
                    gstDetails.gstCompanyName &&
                    gstDetails.gstNumber &&
                    gstDetails.gstPhoneNo &&
                    gstDetails.gstEmailId &&
                    gstDetails.gstAddressLine1;
                  let isallEmpty =
                    !gstDetails.gstCompanyName &&
                    !gstDetails.gstNumber &&
                    !gstDetails.gstPhoneNo &&
                    !gstDetails.gstEmailId &&
                    !gstDetails.gstAddressLine1;

                  if (!isallFilled && !isallEmpty) {
                    Object.entries(gstDetails).forEach(([key, value]) => {
                      if (!value) {
                        gst_form.setFields([
                          {
                            name: key,
                            errors: ["Required"],
                          },
                        ]);
                      }
                    });
                    return;
                  }
                }
                onFlicghtCheckoutValidated(
                  contact_data,
                  passegers_data,
                  gstDetails
                );
              })
              .catch((e) => {
                console.log("gst form error", e);
              });
          })
          .catch((e) => {
            passenger_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const onFlicghtCheckoutValidated = (
    contactDetails,
    passegersData,
    gstDetails
  ) => {
    try {
      sessionStorage.setItem(
        "selected_services",
        JSON.stringify(SelectedServices)
      );

      let passegers_data = passegersData.passengers;

      passegers_data.map((passengersObj, i) => {
        passengersObj["email"] = contactDetails.email;
        passengersObj["mobile"] = contactDetails.mobile;
        passengersObj["areaCode"] = contactDetails.areaCode;
        if (contactDetails.countryName) {
          let countryCode = CountryList.filter(
            (item) => item.name === contactDetails.countryName
          )[0].code;

          passengersObj["address_CountryCode"] = countryCode;
        } else {
          passengersObj["address_CountryCode"] = "";
        }
        if (passengersObj.dob) {
          passengersObj["dob"] = moment(passengersObj.dob).format(
            oriDateFormat
          );
        }
        if (passengersObj.passportDOE) {
          passengersObj["passportDOE"] = moment(
            passengersObj.passportDOE
          ).format(oriDateFormat);
        }
        if (passengersObj.passportDOI) {
          passengersObj["passportDOI"] = moment(
            passengersObj.passportDOI
          ).format(oriDateFormat);
        }

        passengersObj["address"] = contactDetails.address??"Manjusha Building, 806, 8th Floor, Nehru Place";
        passengersObj["city"] = contactDetails.city??"Delhi";
        passengersObj["countryName"] = contactDetails.countryName??"India";
        // if (contactDetails.state) {
          passengersObj["state"] = contactDetails.state??"Delhi";
        // }

        // if (contactDetails.postalCode) {
          passengersObj["postalCode"] = contactDetails.postalCode??"110019";
        // }
        let additionalService=[]
        if(passengersObj?.OutboundMeal!=undefined) //  && passengersObj?.InboundBagage==undefined && passengersObj?.InboundMeal==undefined )
        {
          additionalService.push(
          JSON.parse(passengersObj.OutboundMeal));
        }
         if (passengersObj?.OutboundBagage!=undefined){
          additionalService.push(JSON.parse(passengersObj.OutboundBagage));
        }
         if(passengersObj?.InboundBagage!==undefined)
        {// && passengersObj?.InboundMeal!==undefined ){
          additionalService.push(JSON.parse(passengersObj.InboundBagage));
         
           // JSON.parse(passengersObj.OutboundMeal),JSON.parse(passengersObj?.InboundBagage), JSON.parse(passengersObj?.InboundMeal)];
        }
        if( passengersObj?.InboundMeal!==undefined){
          additionalService.push(JSON.parse(passengersObj.InboundMeal));
        }
        // if
        // {
        //   passengersObj["additionalServicesIds"] =[]
        // }
        passengersObj["additionalServicesIds"] = additionalService;
        passengersObj["gender"] =
          passengersObj.title === "MR" || passengersObj.title === "MSTR"
            ? "m"
            : "f";
        delete passengersObj["countryCode"];
        delete passengersObj["id"];
        return passengersObj;
      });

      let airBookReqObj = {
        traceId: flightSearchObj.traceId,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        passengers: passegers_data,
        additional_services: additional_services,
        gstDetails: {
          gstAddressLine1: gstDetails.gstAddressLine1
            ? gstDetails.gstAddressLine1
            : "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: gstDetails.gstEmailId ? gstDetails.gstEmailId : "",
          gstNumber: gstDetails.gstNumber ? gstDetails.gstNumber : "",
          gstPhoneNo: gstDetails.gstPhoneNo ? gstDetails.gstPhoneNo : "",
          gstCompanyName: gstDetails.gstCompanyName
            ? gstDetails.gstCompanyName
            : "",
        },
        creditCardInfo: "",
        insuranceRequired: passegersData?.insuranceRequired === 1 ? 1 : 0,
        // insuranceData: selectedInsuranceData,
      };

      updateAirBookState(airBookReqObj);
      history.push("/flight/review");
    } catch (error) {
      console.log(error);
    }
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  /* Fetching traveller details from API */
  const fetchTravellerDetails = () => {
    if (!user) return;
    ApiClient.get("admin/user/travellers/" + user.UserID)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
          console.log("Unable to fetch Travellers List");
        }
      })
      .catch((err) => err);
  };

  /* Handling traveler details append to form   */
  const handleTravelerDetails = (val, paxIndex) => {
    let selectedTraveler = travellersListResp.Travellers.find(
      (traveler) => traveler.TravellerID === val
    );

    let { passengers } = passenger_form.getFieldValue();
    let newObj = {
      ...passengers[paxIndex],
      title: selectedTraveler.Gender === 0 ? "MR" : "Ms",
      firstName: selectedTraveler.FirstName,
      lastName: selectedTraveler.LastName,
    };
    Object.keys(newObj).map((key) => {
      if (key === "dob") {
        newObj[key] = selectedTraveler.DOB ? moment(selectedTraveler.DOB) : "";
      }
      if (key === "passengerNationality") {
        newObj[key] = "IN"// selectedTraveler.PassengerNationality ?? "In";
      }
      if (key === "passportNumber") {
        newObj[key] = selectedTraveler.PassportNumber ?? "";
      }
      if (key === "passportDOI") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.passportDOI)
          : "";
      }
      if (key === "passportDOE") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.PassportDOE)
          : "";
      }
    });
    Object.assign(passengers[paxIndex], newObj);

    passenger_form.setFieldsValue({ passengers });
  };
 let array=[];
  // const handleServicesDetails = (val, paxIndex) => {

  //   const index = array.indexOf(val);
  // if (index > -
  //   1) { // only splice array when item is found
  // array.splice(index, 1); // 2nd parameter means remove one item only
  //   }
  //   array.push(val);
  //   setSelcetedServices({
  //     SelectedServices:array
  //   });
  //  }
  // const updateKeys = (key, paxIndex) => {
  //   setSelcetedServices({
  //     ...SelectedServices,
  //     [paxIndex]: { keys: key, services: SelectedServices[paxIndex].services },
  //   });
  // };

  // const AddditionServiceHelper = ({ paxIndex, paxType }) => {
  //   // console.log("addtionalServices", SelectedServices);
  //   const addService = (e, _index, paxIndex) => {
  //     if (e.target.checked) {
  //       setSelcetedServices({
  //         ...SelectedServices,
  //         [paxIndex]: {
  //           keys: SelectedServices[paxIndex].keys,
  //           services: [...SelectedServices[paxIndex].services, _index],
  //         },
  //       });
  //     } else {
  //       setSelcetedServices({
  //         ...SelectedServices,
  //         [paxIndex]: {
  //           keys: SelectedServices[paxIndex].keys,
  //           services: SelectedServices[paxIndex].services.filter(
  //             (i) => i != _index
  //           ),
  //         },
  //       });
  //     }
  //   };

  //   return (
  //     <Row>
  //       <Col span={24}>
  //         <Collapse
  //           activeKey={SelectedServices[paxIndex].keys}
  //           onChange={(k) => updateKeys(k, paxIndex)}
  //         >
  //           <Panel header="Add baggage" collapsed>
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services.flightDetails[0].additionalServices
  //                   .filter((i) => i.additionalServiceType == "Baggage")
  //                   .map((s,index) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s.code, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.serviceDescription} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //           <Panel header="Add Meal">
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services.flightDetails[0].additionalServices
  //                   .filter((i) => i.additionalServiceType == "Meal")
  //                   .map((s,index) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s.code, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.freeText} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //           <Panel header="Add Special Services">
  //             <Row>
  //               <Col span={24}>
  //                 {additional_services.flightDetails[0].additionalServices
  //                   .filter((i) => i.additionalServiceType == "SpecialService")
  //                   .map((s, index) => {
  //                     return s.serviceDescription != 0 ? (
  //                       <Checkbox
  //                         onChange={(e) => addService(e, s.code, paxIndex)}
  //                         checked={
  //                           SelectedServices[paxIndex].services.indexOf(
  //                             index
  //                           ) != -1
  //                         }
  //                       >
  //                         {s.serviceDescription} for {activeCurrency}
  //                         {currencyValue(s.flightFares[0].amount)}
  //                       </Checkbox>
  //                     ) : null;
  //                   })}
  //               </Col>
  //             </Row>
  //           </Panel>
  //         </Collapse>
  //       </Col>
  //     </Row>
  //   );
  // };

  const startCountdown = (minutes) => {
    let minutesDisplay, secondsDisplay
    const countdownElement = document.getElementById('countdown')
    let seconds = minutes * 60;
    function updateCountdown() {
      minutesDisplay = Math.floor(seconds / 60);
      secondsDisplay = seconds % 60;
      countdownElement.textContent = `${minutesDisplay}:${secondsDisplay < 10 ? '0' : ''}${secondsDisplay}`;
    }
    updateCountdown();
    const countdownIntervalId = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(countdownIntervalId);
        // countdownElement.textContent = 'Time\'s up!';
        history.push('/');
      } else {
        seconds--;
        updateCountdown();
      }
    }, 1000);
  }

  return (
    <div className="container">
      <div className="flight-checkout-tc">
        <div className="flight-checkout-tc-head">
          The most Secure Booking System. It's Simple, Easy and Takes onlye a few minutes, We use the most secure storage system to save your personal information.
        </div>

        <div className="flight-checkout-tc-content">
          <Row gutter={[24, 16]}>
            <Col lg={8} xs={24}>
              <div className="tc-col">
                <div className="tc-col-left">
                  <h5 className="tc-col-heading">You don't have an account</h5>
                  <p className="tc-col-para">Create an account for faster checkout and save upto 20% on your first purchase</p>
                </div>
                <div className="tc-col-right">
                  <a href="#" class="btn tc-register-btn">Sign Up</a>
                </div>
              </div>
            </Col>

            <Col lg={8} xs={24}>
              <div className="tc-col">
                <div className="tc-col-left">
                  <h5 className="tc-col-heading">You have an account</h5>
                  <p className="tc-col-para">Sign in for faster checkout and you might be entitled for discount</p>
                </div>
                <div className="tc-col-right">
                  <a href="#" class="btn tc-login-btn">Sign In</a>
                  </div>
                </div>
            </Col>

            <Col lg={8} xs={24}>
              <div className="tc-col tc-col-help">
                <div className="tc-col-left">
                  <h5 className="tc-col-heading">Need Help In Booking</h5>
                  <p className="tc-col-para">Call Us: 02083936012</p>
                </div>
                <div className="tc-col-right">
                  <img
                    src={require("../../assets/images/checkout-cc.webp").default}
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="flight-checkout-tc flight-checkout-pagehead e-hide">
        <div fluid>
          <div className="flight-checkout-tc-head">
            <div className="goback">
              <Link
                onClick={() => {
                  history.go(-1);
                }}
                >
                <ArrowLeftOutlined />
                <span>Go back and select another flight</span>
              </Link>
            </div>
          </div>
          <div className="flight-checkout-tc-content">
            Fill out the form below and book your flight now!
          </div>
        </div>
      </div>

      <div className="flight-checkout-main">
        <Row gutter={[16, 16]}>
          <Col lg={16} xs={24} className="checkout-bottom-part">
            <div className="flight-checkout-card flight-checkout-details-card">
              <FlightDetailsCard
                selectedFlight={selectedFlight}
                flightSearchObj={flightSearchObj}
              />
            </div>

            <div className="flight-checkout-card flight-checkout-pax-card">
                    {isLoading ? (
                      <>
                        <div className="flight-checkout-card-head">
                          <h3 className="fcc-head-title">Travellers Personal Information</h3>
                          <p className="fcc-head-subtitle">Be aware that your names must match the Pasport/Travel Document or Photo Id Card</p>
                        </div>
                        <div className="flight-checkout-card-content">
                          <Card>
                            <Skeleton active />
                          </Card>
                        </div>
                      </>
                    ) : totalPaxArr.length > 0 ? (
                      <>
                        <div className="flight-checkout-card-head">
                          <h3 className="fcc-head-title">Travellers Personal Information</h3>
                          <p className="fcc-head-subtitle">Be aware that your names must match the Pasport/Travel Document or Photo Id Card</p>
                        </div>

                        <div className="flight-checkout-card-content">
                          <Form
                            layout="vertical"
                            className="flight-checkout-contact-form"
                            validateMessages={validateMessages}
                            form={passenger_form}
                            
                            initialValues={{ passengers: totalPaxArr }}
                          >
                            <Card>
                              <Form.List name="passengers">
                                {(fields) => {
                                  return fields.map((field, index) => (
                                    <>
                                      <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                          <div className="pax-heading">
                                            {totalPaxArr[index].paxType === "ADT"
                                              ? "Adult"
                                              : totalPaxArr[index].paxType ===
                                                "CHD"
                                              ? "Child"
                                              : "Infant"}
                                          </div>
                                        </Col>
                                        {flag && (
                                          <>
                                            <Col md={13} sm={8} xs={24}>
                                              <div className="pax-head-hr">
                                                <hr />
                                              </div>
                                            </Col>

                                            <Col md={8} sm={8} xs={24}>
                                              <Select
                                                placeholder=" Select passenger"
                                                onSelect={(val) => {
                                                  handleTravelerDetails(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                style={{ width: "100%" }}
                                              >
                                                {travellersListResp.Travellers.map(
                                                  (travelerName) => (
                                                    <Option
                                                      value={
                                                        travelerName.TravellerID
                                                      }
                                                      key={
                                                        travelerName.TravellerID
                                                      }
                                                    >
                                                      {travelerName.FirstName +
                                                        " " +
                                                        travelerName.LastName}
                                                    </Option>
                                                  )
                                                )}
                                              </Select>
                                            </Col>
                                          </>
                                        )}
                                      </Row>

                                      <Row gutter={[12, 12]}>
                                        <Col md={4} sm={6} xs={24}>
                                          <Form.Item
                                            {...field}
                                            name={[index, "title"]}
                                            label="Title"
                                          >
                                            <Select
                                              placeholder="Choose"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Required",
                                                },
                                              ]}
                                            >
                                              {totalPaxArr[index].paxType ===
                                              "ADT" ? (
                                                <>
                                                  <Select.Option value="MR">
                                                    Mr
                                                  </Select.Option>
                                                  <Select.Option value="MRS">
                                                    Mrs
                                                  </Select.Option>
                                                </>
                                              ) : (
                                                <>
                                                  <Select.Option value="MSTR">
                                                    Mstr
                                                  </Select.Option>
                                                </>
                                              )}

                                              <Select.Option value="MS">
                                                Ms
                                              </Select.Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                        <Col md={8} sm={9} xs={24}>
                                          <Form.Item
                                            {...field}
                                            name={[index, "firstName"]}
                                            label={<span><span style={{ color: "#0a74c2" }}>Guest</span> First Name</span>}
                                            rules={[
                                              {
                                                min: 2,
                                                message:
                                                  "First Name must be minimum 2 characters.",
                                              },
                                              {
                                                pattern: "^[a-zA-Z ]*$",
                                                message:
                                                  "Alphabet Characters Only",
                                              },
                                              {
                                                max: 32,
                                                message:
                                                  "First Name must be maximum 32 characters.",
                                              },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  // if (
                                                  //   !value ||
                                                  //   getFieldValue([
                                                  //     "passengers",
                                                  //     index,
                                                  //     "lastName",
                                                  //   ]) != value
                                                  // ) {
                                                    if (
                                                      !abbrevations.includes(
                                                        value
                                                      )
                                                    ) {
                                                      return Promise.resolve();
                                                    } else {
                                                      return Promise.reject(
                                                        new Error(
                                                          "Abbrevations are not accepted in beginning."
                                                        )
                                                      );
                                                    }
                                                  // }
                                                  // return Promise.reject(
                                                  //   new Error(
                                                  //     "The First and Last Name should not be same."
                                                  //   )
                                                  // );
                                                },
                                              }),
                                            ]}
                                          >
                                            <Input className="inputbg" />
                                          </Form.Item>
                                        </Col>
                                        
                                        <Col md={8} sm={9} xs={24}>
                                          <Form.Item
                                            {...field}
                                            name={[index, "lastName"]}
                                            label={<span><span style={{ color: "#0a74c2" }}>Guest</span> Last Name</span>}
                                            rules={[
                                              {
                                                min: 2,
                                                message:
                                                  "Last Name must be minimum 2 characters.",
                                              },
                                              {
                                                pattern: "^[a-zA-Z ]*$",
                                                message:
                                                  "Alphabet Characters Only",
                                              },
                                              {
                                                max: 32,
                                                message:
                                                  "Last Name must be maximum 32 characters.",
                                              },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  // if (
                                                  //   !value ||
                                                  //   getFieldValue([
                                                  //     "passengers",
                                                  //     index,
                                                  //     "firstName",
                                                  //   ]) != value
                                                  // ) {
                                                    if (
                                                      !abbrevations.includes(
                                                        value
                                                      )
                                                    ) {
                                                      return Promise.resolve();
                                                    } else {
                                                      return Promise.reject(
                                                        new Error(
                                                          "Abbrevations are not accepted in beginning."
                                                        )
                                                      );
                                                    }
                                                  // }
                                                  // return Promise.reject(
                                                  //   new Error(
                                                  //     "The First and Last Name should not be same."
                                                  //   )
                                                  // );
                                                },
                                              }),
                                            ]}
                                          >
                                            <Input className="inputbg" />
                                          </Form.Item>
                                        </Col>

                                        {/* Static Part Starts */}
                                        <Col md={16} xs={24}>
                                          <Form.Item 
                                            label="Email Address"
                                            name={[index, "EmailID"]}
                                          >
                                              
                                            <Input defaultValue="We will send your booking confirmation to your email" className="inputbg" />
                                          </Form.Item>
                                        </Col>

                                        <Col sm={16} xs={24}>
                                          <Form.Item 
                                            label="Confirm Email Address" 
                                            name={[index, "confirmEmailID"]}
                                          >
                                              
                                            <Input defaultValue="We will send your booking confirmation to your email" className="inputbg" />
                                          </Form.Item>
                                        </Col>

                                        <Col sm={8} xs={24}>
                                          <Form.Item 
                                            label="Country Code"
                                            name={[index, "countryCode"]}
                                          >
                                              
                                            <Select defaultValue="United Kingdom">
                                              <Select.Option value="uk">United Kingdom</Select.Option>
                                              <Select.Option value="india">India</Select.Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                        <Col md={8} sm={12} xs={24}>
                                          <Form.Item 
                                            label="Phone Number" 
                                            name={[index, "phoneNumber"]}
                                          >
                                              
                                            <Input className="inputbg" />
                                          </Form.Item>
                                        </Col>

                                        <Col md={8} sm={12} xs={24}>
                                          <Form.Item 
                                            label="Mobile Number" 
                                            name={[index, "mobileNumber"]}
                                          >
                                              
                                            <Input defaultValue="In Case we need to contact" className="inputbg" />
                                          </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={24}>
                                          <Form.Item 
                                            label="Passport or travel document country"
                                            name={[index, "passportCountry"]}
                                          >
                                              
                                            <Select defaultValue="United Kingdom">
                                              <Select.Option value="uk">United Kingdom</Select.Option>
                                              <Select.Option value="india">India</Select.Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                        {/* Static Part Ends */}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "dob"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                label="Date of birth"
                                                name={[index, "dob"]}
                                                rules={[
                                                  {
                                                    validator: (_, v) =>
                                                      PaxAgeValidator(
                                                        totalPaxArr[index].paxType,
                                                        v
                                                      ),
                                                  },
                                                ]}
                                              >
                                                <DatePicker
                                                  className="inputbg"
                                                  allowClear={false}
                                                  format={dateformat}
                                                  style={{ width: "100%" }}
                                                  disabledDate={disabledFutureDate}
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passengerNationality"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[
                                                  index,
                                                  "passengerNationality",
                                                ]}
                                                label="Nationality"
                                                value="teat"
                                                rules={[
                                                  {
                                                    pattern: "^[a-zA-Z ]*$",
                                                    message:
                                                      "Alphabet Characters Only",
                                                  },
                                                ]}
                                              >
                                                <Input value="teste" name="passengerNationality" className="inputbg" />
                                              </Form.Item>
                                            </Col>
                                          )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportNumber"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                name={[index, "passportNumber"]}
                                                label="Passport Number"
                                              >
                                                <Input
                                                  className="inputbg"
                                                  placeholder="Passport Number"
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportDOI"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                name={[index, "passportDOI"]}
                                                label="Passport Issue Date"
                                                className="passport-dates"
                                              >
                                                <DatePicker
                                                  format={dateformat}
                                                  style={{ width: "100%" }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportDOE"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                name={[index, "passportDOE"]}
                                                label="Passport Expiry Date"
                                                className="passport-dates"
                                              >
                                                <DatePicker
                                                  format={dateformat}
                                                  style={{ width: "100%" }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}
                                          {totalPaxArr[index].hasOwnProperty(
                                            "passportIssuedCountry"
                                          ) && (
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                name={[
                                                  index,
                                                  "passportIssuedCountry",
                                                ]}
                                                label="Passport Issue Country"
                                              >
                                                <Select
                                                  showSearch
                                                  placeholder="Select Passport Issued Country"
                                                  filterOption={(input, option) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                >
                                                  {CountryList.map((item) => (
                                                    <Option
                                                      key={item.name}
                                                      value={item.name}
                                                    >
                                                      {item.name}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                          )}
                                        
                                        { additional_services.flightDetails[0].additionalServices.length>=1 &&
                                          totalPaxArr[[index]].paxType!="INF" &&
                                        <>
                                    
                                          <Col sm={12} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[index, "OutboundBagage"]}
                                              label={additional_services.flightDetails[0].additionalServices.length>=1 &&  additional_services.flightDetails[0].additionalServices
                                                .filter((i) => i.additionalServiceType =="Baggage")[0].cityPair + "  Bagage"}
                                            >

                            
                                              <Select
                                                placeholder="Choose"
                                                onChange={(e) =>
                                                  handleBaggesChange(e,"outbound")
                                                }
                                            
                                              >
                                                  <Select.Option value="">
                                                Choose
                                                </Select.Option>
                                                {additional_services.flightDetails[0].additionalServices
                        .filter((i) => i.additionalServiceType =="Baggage")
                        .map((s,ind) => {
                                              //  {airPriceResp.flightDetails[0].baggage.map((flightDetObj, i) => (
                                              return( s.serviceDescription != 0 &&  <>
                                                    <Select.Option key={s.code} value={JSON.stringify({"code":s.code,"cityPair":s.cityPair,"amount":currencyValue(s.flightFares[0].amount),"paxNo":index})}>
                                                    {s.freeText} for {activeCurrency}
                                                  {currencyValue(s.flightFares[0].amount)}
                                                    {/* {s.weight}-kg {currencyValue(s.price)} */}
                                                    </Select.Option>
                                            </>)})}
                                              </Select>
                                            </Form.Item>
                                          </Col>

                                          <Col sm={12} xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[index, "OutboundMeal"]}
                                              label={additional_services.flightDetails[0].additionalServices.length>=1 &&  additional_services.flightDetails[0].additionalServices
                                                .filter((i) => i.additionalServiceType =="Meal")[0].cityPair + "  Meal"}
                                          
                                            
                                            >
                                              <Select
                                                placeholder="Choose"
                                                onChange={(e) =>
                                                  handleMealChange(e,"outbound")
                                                }
                                              >
                                                <Select.Option value="">
                                                    Choose                                          
                                                    </Select.Option>
                                                {additional_services.flightDetails[0].additionalServices
                        .filter((i) => i.additionalServiceType =="Meal")
                        .map((s,ind) => {   return s.serviceDescription != 0 &&  (<>
                                                    <Select.Option key={s.code} value={JSON.stringify({"code":s.code,"cityPair":s.cityPair,"amount":currencyValue(s.flightFares[0].amount),"paxNo":index}) }>
                                                    {s.freeText} for {activeCurrency}
                                                  {currencyValue(s.flightFares[0].amount)}                                                </Select.Option>
                                            </>)})}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        </>}
                                        {/* <AddditionServiceHelper paxIndex={index} paxType={"Ad"}></AddditionServiceHelper> */}
                                          {airPriceResp.flightDetails.length>1 && additional_services.flightDetails[1].additionalServices.length>=1 &&   totalPaxArr[[index]].paxType!="INF" &&<>
                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[index, "InboundBagage"]}
                                                label={additional_services.flightDetails[1].additionalServices.length>=1 &&  additional_services.flightDetails[1].additionalServices
                                                  .filter((i) => i.additionalServiceType =="Baggage")[0].cityPair + "  Baggage"}
                                            
                                              >

                              <Select.Option value=""> Choose</Select.Option>
                                                <Select
                                                  placeholder="Choose"
                                                  onChange={(e) =>
                                                    handleBaggesChange(e,"inbound")
                                                  }

                                                >
                                                  <Select.option value="">
                                                    Choose
                                                  </Select.option>
                                                {additional_services.flightDetails[1].additionalServices
                          .filter((i) => i.additionalServiceType =="Baggage")
                          .map((s,index) => {
                                                //  {airPriceResp.flightDetails[0].baggage.map((flightDetObj, i) => (
                                                return( s.serviceDescription != 0 &&  <>
                                                      <Select.Option value={JSON.stringify({"code":s.code,"cityPair":s.cityPair,"amount":currencyValue(s.flightFares[0].amount),"paxNo":index})}>
                                                      {s.freeText} for {activeCurrency}
                                                    {currencyValue(s.flightFares[0].amount)}
                                                      {/* {s.weight}-kg {currencyValue(s.price)} */}
                                                      </Select.Option>
                                              </>)})}
                                                </Select>
                                              </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[index, "InboundMeal"]}
                                                label={additional_services.flightDetails[1].additionalServices.length>=1 && additional_services.flightDetails[1].additionalServices
                                                  .filter((i) => i.additionalServiceType =="Meal")[0].cityPair + "  Meal"}
                                            
                                              
                                              >
                                                <Select
                                                  placeholder="Choose"
                                                  onChange={(e) =>
                                                    handleMealChange(e,"inbound")
                                                  }
                                                >
                                                    
                                              
                                                    <Select.Option value="">
                                                      Choose                                          
                                                      </Select.Option>
                                                    {additional_services.flightDetails[1].additionalServices
                          .filter((i) => i.additionalServiceType =="Meal")
                          .map((s,index) => {   return s.serviceDescription != 0 &&  (<>
                                                      <Select.Option value={JSON.stringify({"code":s.code,"cityPair":s.cityPair,"amount":currencyValue(s.flightFares[0].amount),"paxNo":index})}>
                                                      {s.freeText} for {activeCurrency}
                                                    {currencyValue(s.flightFares[0].amount)}                                                </Select.Option>
                                              </>)})}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                          </> }
                                      </Row>
                                    </>
                                  ));
                                }}
                              </Form.List>
                            </Card>

                            <div className="e-hide">
                            {insuranceData.status === 1 &&
                            insuranceData?.serviceType === 1 ? (
                              <Card className="insurance-section">
                                <h5>THE SMART INSURANCE COVER</h5>
                                <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                                <Row gutter={[16, 16]}
                                  align="stretch"
                                  className="mt-4"
                                >
                                  <Col sm={8} xs={24}>
                                    <div className="insurance-box">
                                      {insuranceData.description_box_1}
                                    </div>
                                  </Col>
                                  <Col sm={8} xs={24}>
                                    <div className="insurance-box">
                                      {insuranceData.description_box_2}
                                    </div>
                                  </Col>
                                  <Col sm={8} xs={24}>
                                    <div className="insurance-box">
                                      {insuranceData.description_box_3}
                                    </div>
                                  </Col>
                                </Row>

                                <div className="insurance-coverage">
                                  <SafetyCertificateOutlined />
                                  <span>
                                    Insurance Coverage Amount :{" "}
                                    {insuranceData.insuranceCoverage}
                                  </span>
                                </div>

                                <Form.Item
                                  name="insuranceRequired"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Insurance",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    buttonStyle="solid"
                                    onChange={(e) =>
                                      handleInsuranceChange(e.target.value)
                                    }
                                    className="insurance-radio-wrapper"
                                  >
                                    <Row gutter={[16, 16]}>
                                      <Col sm={12} xs={24}>
                                        <Radio
                                          className={`radio-btn btn-choose-insure btn-insure  ${
                                            insuranceRequired === 1
                                              ? "btn-insure-active"
                                              : ""
                                          }`}
                                          value={1}
                                        >
                                          Insure For ₹{insuranceData.amount}/pax
                                        </Radio>
                                      </Col>

                                      <Col sm={12} xs={24}>
                                        <Radio
                                          value={0}
                                          className={`radio-btn btn-choose-insure btn-risk ${
                                            insuranceRequired === 0
                                              ? "btn-risk-active"
                                              : ""
                                          }`}
                                        >
                                          I'll Risk it
                                        </Radio>
                                      </Col>
                                    </Row>
                                  </Radio.Group>
                                  
                                </Form.Item>

                                <Row>
                                  <Col span={24}>
                                    <a href="https://HoldTrip.co.in/api/v1/media/images/deals/InsuranceDetails.pdf" target="_blank">More detail &raquo;</a>
                                  </Col>
                                </Row>
                              </Card>
                            ) : null}
                            </div>
                          </Form>
                        </div>
                      </>
                    ) : null}
            </div>

            {/* Static Part Starts */}
            <div className="flight-checkout-card">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">Add transfers to save 10%</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  <div className="add-extra-card-inner">
                    <div className="add-extra-head">
                      <Row gutter={[12, 16]}>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-left">
                            <img
                              src={require("../../assets/images/cab-outlined-orange-35x31.webp").default}
                              alt=""
                            />
                            <div>
                              <h5>Hotel Transfer</h5>
                              <ul>
                                <li><CheckOutlined /> Hassle Free</li>
                                <li><CheckOutlined /> Avoid queuing</li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-right">
                            <p className="extra-price"><span>From only</span> <ins>INR 3170</ins> Per Person</p>
                            <p>Private Transfer</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="add-extra-content">
                      <Radio.Group direction="vertical" className="add-extra-radio-group">
                        <Radio value={1} className="not-required-option">
                          I will be making my own travel arrangements and require no transfer.
                        </Radio>
                        <Radio value={2}>
                          <ul className="add-extra-option">
                            <li>
                              <img
                                src={require("../../assets/images/shuttle-outlined-blue-35x34.webp").default}
                                alt=""
                              />
                              <div>
                                <h5>Shuttle Transfer</h5>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                        <Radio value={3}>
                          <ul className="add-extra-option">
                            <li>
                              <img
                                src={require("../../assets/images/cab-outlined-blue-35x31.webp").default}
                                alt=""
                              />
                              <div>
                                <h5>Private Transfer</h5>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="flight-checkout-card">
              <div className="flight-checkout-card-head" style={{ background: "#ed7d31" }}>
                <h3 className="fcc-head-title">Add car park to save 10%</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  <div className="add-extra-card-inner">
                    <div className="add-extra-head">
                      <Row gutter={[12, 16]}>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-left">
                            <img
                              src={require("../../assets/images/parking-sign-30x30.webp").default}
                              alt=""
                            />
                            <div>
                              <h5>Airport Parking</h5>
                              <ul>
                                <li><CheckOutlined /> Hassle Free - Secure your space</li>
                                <li><CheckOutlined /> Book early & save up to 60% off</li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-right">
                            <p className="extra-price"><span>From only</span> <ins>INR 1817</ins> Per Person</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="add-extra-content">
                      <Radio.Group direction="vertical" className="add-extra-radio-group">
                        <Radio value={1} className="not-required-option">
                          I do not require airport parking.
                        </Radio>
                        <Radio value={2}>
                          <ul className="add-extra-option">
                            <li>
                              <img
                                src={require("../../assets/images/shuttle-filled-blue-35x34.webp").default}
                                alt=""
                              />
                              <div>
                                <h5>Off airport parking</h5>
                                <p><b>From:</b> Short Stay Economy - Blue Zone</p>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                        <Radio value={3}>
                          <ul className="add-extra-option">
                            <li>
                              <img
                                src={require("../../assets/images/cab-filled-blue-35x31.webp").default}
                                alt=""
                              />
                              <div>
                                <h5>On airport parking</h5>
                                <p><b>From:</b> Short Stay Economy - Blue Zone</p>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="flight-checkout-card">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">Save massive unexpected loose for just INR 313 per persn</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  <div className="add-extra-card-inner">
                    <div className="add-extra-head">
                      <Row gutter={[12, 16]}>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-left">
                            <img
                              src={require("../../assets/images/insurance-bag-35x31.webp").default}
                              alt=""
                            />
                            <div>
                              <h5>Travel Insurance</h5>
                              <ul>
                                <li><CheckOutlined /> Most competitive price</li>
                                <li><CheckOutlined /> Wide range of activities covered</li>
                                <li><CheckOutlined /> Price includes insurance premium</li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} xs={24}>
                          <div className="add-extra-head-right">
                            <p className="extra-price"><span>From only</span> <ins>INR 1817</ins> Per Person</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="add-extra-content">
                      <Radio.Group direction="vertical" className="add-extra-radio-group">
                        <Radio value={1} className="not-required-option">
                          I do not require insurance.
                        </Radio>
                        <Radio value={2}>
                          <ul className="add-extra-option">
                            <li>
                              <div>
                                <h5>Standard Single trip individual insurance</h5>
                                <p><b>Key Benefits Includes:</b> INR 3,00, 000 medical expenses</p>
                                <p><b>Age Range:</b> 18 - 70</p>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                        <Radio value={3}>
                          <ul className="add-extra-option">
                            <li>
                              <div>
                                <h5>Standard Single trip individual insurance</h5>
                                <p><b>Key Benefits Includes:</b> INR 3,00, 000 medical expenses</p>
                                <p><b>Age Range:</b> 18 - 70</p>
                              </div>
                            </li>
                            <li>
                              <QuestionCircleFilled />
                            </li>
                            <li>
                              <ins>INR 1812</ins> PP
                            </li>
                          </ul>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="flight-checkout-card">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">Payment Details</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  
                  <Row gutter={[12, 12]}>
                    <Col sm={12} xs={24}>
                      <Radio.Group direction="vertical" className="card-option-radio-group">
                        <Radio value={1}>
                          Credit/Debit Card
                        </Radio>
                        <Radio value={2}>
                          Paypal
                        </Radio>
                      </Radio.Group>
                    </Col>
                    <Col sm={12} xs={24}>
                      <div className="dontcharge-col">
                        <p>We don't charge Credit/Debit card fees</p>
                        <img
                          src={require("../../assets/images/pay-info-icon.webp").default}
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <div className="secure-payment-option">
                        <p>Secure Payment Option</p>
                          <img
                            src={require("../../assets/images/barclaycards.webp").default}
                            alt=""
                          />
                      </div>
                    </Col>
                  </Row>

                  <Form
                      layout="vertical"
                      className="flight-checkout-contact-form"
                      name="paymentDetailsForm"
                    >
                    <Row gutter={[12, 12]}>
                      <Col md={4} sm={8} xs={24}>
                        <Form.Item 
                          label="Title"
                          name="paymentTitle"
                        >
                                                
                          <Select defaultValue="Mr">
                            <Select.Option value="Mr">Mr</Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                            <Select.Option value="Mstr">Mstr</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={12} sm={16} xs={24}>
                        <Form.Item 
                          label="Card holders full name"
                          name="cardHolderName"
                          >
                        
                          <div className="payment-info-input">
                            <Input className="inputbg" />
                            <img
                              src={require("../../assets/images/pay-info-icon.webp").default}
                              alt=""
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                      <Col md={16} xs={24}>
                        <Form.Item 
                          label="Debit/Visa or Credit card number"
                          name="cardNumber"
                          >
                                              
                          <div className="payment-info-input">
                            <Input className="inputbg" />
                            <img
                              src={require("../../assets/images/pay-info-icon.webp").default}
                              alt=""
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                      <Col md={4} sm={6} xs={24}>
                        <Form.Item 
                          label="Expiry date"
                          name="expiryDate"
                        >

                            <Select defaultValue="Month">
                              <Select.Option value="January">January</Select.Option>
                              <Select.Option value="February">February</Select.Option>
                              <Select.Option value="March">March</Select.Option>
                              <Select.Option value="April">April</Select.Option>
                              <Select.Option value="May">May</Select.Option>
                              <Select.Option value="June">June</Select.Option>
                              <Select.Option value="July">July</Select.Option>
                              <Select.Option value="August">August</Select.Option>
                              <Select.Option value="September">September</Select.Option>
                              <Select.Option value="October">October</Select.Option>
                              <Select.Option value="November">November</Select.Option>
                              <Select.Option value="December">December</Select.Option>
                            </Select>
                        </Form.Item>
                      </Col>

                      <Col md={4} sm={6} xs={24}>
                        <Form.Item 
                          label="&nbsp;"
                          className="payment-expiry-year"
                          name="expiryYear"
                          >
                                              
                            <Select defaultValue="Year">
                              <Select.Option value="2021">2021</Select.Option>
                              <Select.Option value="2022">2022</Select.Option>
                            </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item 
                          label="Security Code"
                          name="securityCode"
                          >
                                              
                          <div className="payment-info-input">
                            <Input className="inputbg" />
                            <img
                              src={require("../../assets/images/pay-info-icon.webp").default}
                              alt=""
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>

            <div className="flight-checkout-card">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">Billing Address</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  <Form
                      layout="vertical"
                      className="flight-checkout-contact-form"
                      name="billingAddressForm"
                    >
                    <Row gutter={[12, 12]}>
                      <Col md={8} sm={10} xs={24}>
                        <Form.Item 
                          label="Country"
                          name="billingCountry"
                        >
                                                
                          <Select defaultValue="United Kingdom">
                            <Select.Option value="uk">United Kingdom</Select.Option>
                            <Select.Option value="india">India</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={12} sm={14} xs={24}>
                        <Form.Item 
                          label="Post Code"
                          className="billing-post-code-form-item"
                          name="billingPostalCode"
                        >

                          <div>
                            <Input className="inputbg" />
                            <Button type="primary">Look-up</Button>
                          </div>               
                          <p><b>Example, SW19 1AU</b></p>
                        </Form.Item>
                      </Col>
                    </Row>
                    
                    <Row gutter={[12, 12]}>
                      <Col md={8} sm={10} xs={24}>
                        <Form.Item 
                          label="Property Name or Number"
                          name="propertyName"
                        >
                                              
                          <Input className="inputbg" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                      <Col md={8} sm={10} xs={24}>
                        <Form.Item 
                          label="Address line 1"
                          name="addressLine"
                        >
                                              
                          <Input className="inputbg" />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={10} xs={24}>
                        <Form.Item 
                          label="Town or City"
                          name="townCity"
                        >
                                              
                          <Input className="inputbg" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>
            {/* Static Part Ends */}

            <div className="flight-checkout-card e-hide">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">Contact Details</h3>
              </div>
              <div className="flight-checkout-card-content">
                <Card>
                  <Form
                      layout="vertical"
                      className="flight-checkout-contact-form"
                      name="contactForm"
                      form={contact_form}
                      scrollToFirstError={true}
                      initialValues={{ areaCode: agent ? "+91" : "+91" }}
                    >
                  
                    <Row gutter={[12, 12]}>
                            <Col sm={12} xs={24}>
                              <Form.Item
                                label="Phone number"
                                name="mobile"
                                className="phno"
                                rules={[
                                  {
                                    minLength: 10,
                                    maxLength: 10,
                                    pattern: "^[0-9]{10}$",
                                    message: "Must be 10 digits",
                                  },
                                ]}
                              >
                                <Input
                                  addonBefore={
                                    <Form.Item
                                      style={{ width: "35%" }}
                                      name="areaCode"
                                      className="phno"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Phone Number Code Required",
                                        },
                                      ]}
                                      noStyle
                                    >
                                      <Select
                                        showSearch
                                        // placeholder="Select Country"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {CountryList.map((item) => (
                                          <Option
                                            key={item.dial_code}
                                            value={item.dial_code}
                                          >
                                            {item.dial_code}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  }
                                  className="inputbg"
                                />
                              </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                              <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                  { type: "email", message: "Invalid Email" },
                                ]}
                              >
                                <Input type={"email"} className="inputbg" />
                              </Form.Item>{" "}
                            </Col>{user && user?.UserID ==1 &&<>
                            {passengerRequiredFields.hasOwnProperty("address")
                              ? passengerRequiredFields.address === true && (
                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      name="address"
                                      label="Address"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required",
                                        },
                                        { min: 3, message: "Invalid Address" },
                                      ]}
                                    >
                                      <Input className="inputbg" />
                                    </Form.Item>
                                  </Col>
                                )
                              : null}
                            { passengerRequiredFields.hasOwnProperty("city")
                              ? passengerRequiredFields.city === true && (
                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      name="city"
                                      label="City"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                    >
                                      <Input className="inputbg" />
                                    </Form.Item>{" "}
                                  </Col>
                                )
                              : null}
                            {passengerRequiredFields.hasOwnProperty("state")
                              ? passengerRequiredFields.state === true && (
                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      name="state"
                                      label="State"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                    >
                                      <Input className="inputbg" />
                                    </Form.Item>{" "}
                                  </Col>
                                )
                              : null}
                            {passengerRequiredFields.hasOwnProperty(
                              "countryName"
                            ) ||
                            passengerRequiredFields.hasOwnProperty(
                              "countryCode"
                            ) ? (
                              passengerRequiredFields.countryName === true ||
                              passengerRequiredFields.countryCode === true ? (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    name="countryName"
                                    label="Country"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select Country"
                                      style={{ width: "100%" }}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {CountryList.map((item) => (
                                        <Option
                                          key={item.name}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              ) : null
                            ) : null}
                            {passengerRequiredFields.hasOwnProperty(
                              "postalCode"
                            )
                              ? passengerRequiredFields.postalCode === true && (
                                  <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                      name="postalCode"
                                      label="Postal Code"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                    >
                                      <Input className="inputbg" />
                                    </Form.Item>
                                  </Col>
                                )
                              : null}</>}
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>

            <div className="flight-checkout-card e-hide">
              <div className="flight-checkout-card-head">
                <h3 className="fcc-head-title">GST Details {!isGstRequired ? "(Optional)" : null}</h3>
              </div>
              <div className="flight-checkout-card-content">
                      {isLoading ? (
                        <Card>
                          <Skeleton active />
                        </Card>
                      ) : (
                        <Card>
                          <Form
                            layout="vertical"
                            className="flight-checkout-contact-form"
                            form={gst_form}
                          >
                            <Row gutter={[12, 12]}>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Company Name"
                                  name="gstCompanyName"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input className="contacts-value-de" />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="GST Number"
                                  name="gstNumber"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                    {
                                      minLength: 15,
                                      maxLength: 15,

                                      message: "Must be 15  AlphaNumeric digits",
                                    },
                                    {
                                      pattern:
                                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                                      message: "Enter Valid GST Number",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="contacts-value-de"
                                    placeholder="GST Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Contact Number"
                                  name="gstPhoneNo"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                    {
                                      minLength: 10,
                                      maxLength: 10,
                                      pattern: "^[0-9]{10}$",
                                      message: "Must be 10 digits",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="contacts-value-de"
                                    placeholder="Contact Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Company Email"
                                  name="gstEmailId"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="contacts-value-de"
                                    placeholder="Company Email"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={16} xs={24}>
                                <Form.Item
                                  label="Company Address"
                                  name="gstAddressLine1"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="contacts-value-de"
                                    placeholder="Company Address"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Card>
                      )}
              </div>
            </div>
          </Col>

          <Col lg={8} xs={24} className="checkout-top-part">
            <div className="flight-checkout-sticky-sidebar">
              <div className="flight-checkout-card flight-checkout-price-card">
                <div className="flight-checkout-card-head">
                  <h3 className="fcc-head-title">Price Breakdown</h3>
                </div>
                <div className="flight-checkout-card-content">
                        <div className="timer" style={{ background: "red" }}> { /* style={{ background: "linear-gradient(180deg, rgb(255 0 25), transparent)" }} */}
                          <p> <strong> Timer :<span id="countdown"></span></strong></p>
                        </div>
                        {isLoading ? (
                          <>
                            <div className="pax-count-card">
                              <Card>
                                <Skeleton active />
                              </Card>
                            </div>
                            <div className="pax-count-card">
                              <Card>
                                <Skeleton active />
                              </Card>
                            </div>
                          </>
                        ) : (
                          airPriceResp && (
                            <>
                              {airPriceResp.flightDetails.map((flightDetObj, i) => (
                                <FareSummaryCard
                                  flightDetObj={flightDetObj}
                                  flightSearchObj={flightSearchObj}
                                  selectedMeal={ i === 0?selectedoutMeal==undefined?0:selectedoutMeal :SelectedInbondMeal==undefined?0:SelectedInbondMeal}
                                  SelectedBagges ={i === 0?SelectedoutbondBagges==undefined?0:SelectedoutbondBagges:SelectedInbondBagges==undefined?0:SelectedInbondBagges}
                                  title={
                                    i === 0
                                      ? "Onward Trip Fare"
                                      : "Return Trip Fare"
                                  }
                                />
                              ))}
                              {airPriceResp?.flightDetails?.length > 0 ? (
                                <div className="pax-count-card">
                                  <FlightGrandTotalCard
                                    airPriceResp={airPriceResp}
                                    flightSearchObj={flightSearchObj}
                                    isInternational={isInternational}
                                  />
                                </div>
                              ) : null}
                            </>
                          )
                        )}
                      </div>
                </div>
            </div>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <BookPayCard
              isLoading={isLoading}
              bookpaycardinfo={"flight-checkout"}
              redirectToPreview={redirectToPreview}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FlightCheckout;
