import React from "react";
import { Button, Card, Checkbox, Form } from "antd";
import PayGateway from "../../helpers/PayGateway";
import "./FlightsCheckout.scss";

const BookPayCard = (props) => {
  const [form] = Form.useForm();

  const goTo = () => {
    if (props.bookpaycardinfo === "flight-review") {
      props.processPayGateway();
    } else if (props.bookpaycardinfo === "flight-checkout") {
      props.redirectToPreview();
    }
  };
  const { Insurance } = props;

  return (
    <div className="flight-checkout-card flight-checkout-book-card">
      <div className="flight-checkout-card-content">
        <div className="book-pay-inner-card">
          <Card>
            <a className="font-15">What if my plan change?</a> You can amend and cancel your booking <a>For Free</a> any time before 9.00 am on September 22, 2017
          </Card>
        </div>

        <div className="book-pay-inner-card">
          <Card>
            <Checkbox>Check the box if you do not want to receive emails from Go Global Sky with travel deals, special offers, and other information</Checkbox>
          </Card>
        </div>

          <Card>
            <p>By confirming this booking I acknowledge that I have read and accept the <a>Rules & Restrictions</a>. <a>Terms of Use</a> and <a>Privacy Policy</a></p>
            <p>We will never sale your personal information, we use secure transmission and encrypted storage to protect your details.</p>
            {props.pgDisplay ? (
              <PayGateway
                blockApiReq={props.blockApiReq}
                loadingSpin={props.loadingSpin}
                purchasetype={props.purchasetype}
                Hold={props.Hold}
                IsDeposit={props.IsDeposit}
                OnPaymentModeSelection={props.OnPaymentModeSelection}
              />
            ) : (
              <Form form={form} onFinish={goTo}>
                <div className="book-pay-tc">
                  {props.bookpaycardinfo === "flight-checkout" ? (
                    <>
                      <Form.Item
                        name="remember"
                        rules={[
                          {
                            validator: (rule, value) => {
                              return value
                                ? Promise.resolve()
                                : Promise.reject("Accept Terms & Conditions");
                            },
                          },
                        ]}
                        valuePropName="checked" 
                      >
                        <Checkbox>
                          I Agree To All The{" "}
                          <a
                            href="/termsofconditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" Terms & Conditions"}{" "}
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacypolicy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Privacy Policy.
                          </a>
                        </Checkbox>
                      </Form.Item>
                    </>
                  ) : null}
                </div>
                <div className="book-pay-btn">
                  <Button
                  
                    className="btn btn-lg btn-confirm-book"
                    htmlType="submit"
                  >
                    Confirm Booking Now
                  </Button>
                </div>
              </Form>
            )}
          </Card>
      </div>
    </div>
  );
};

export default BookPayCard;
