import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";
import "./Deals.scss";
import { DownOutlined, TableOutlined, MenuOutlined } from "@ant-design/icons";
import DealsListView from "./ListView/ListView";
import DealsGridView from "./GridView/GridView";
import Subscribe from "../subscribe/Subscribe";
import { Helmet } from "react-helmet";
import APIClient from "../../helpers/ApiClient";
import topFlightNames from "../flight-search/FlightsTopCities.json";
import { AppConstants } from "../../helpers/constants";

const Deals = () => {
  const params = queryString.parse(window.location.search);
  const { Option } = ANTD.Select;
  const [showGridView, setShowGridView] = useState(true);
  const [showListView, setShowListView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [deals, setDeals] = useState([]);
  const [sortDown, setSortDown] = useState(true);
  const showGrid = () => {
    setShowGridView(true);
    setShowListView(false);
  };
  const showList = () => {
    setShowListView(true);
    setShowGridView(false);
  };

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = () => {
    APIClient.get("admin/deals")
      .then((res) => {
        if (res.status == 200 && res.data.length > 0) {
          let dealsData = res.data.map((deal) => {
            deal.FromAirportName = getFlighNameFromCode(deal.FromAirportCode);
            deal.ToAirportName = getFlighNameFromCode(deal.ToAirportCode);
            return deal;
          });
          if (params.country) {
            let filterData = dealsData.filter(
              (deal) =>
                deal.FromCountryID.Country.toLowerCase() ==
                params.country.toLowerCase()
            );
            dealsData = filterData;
          }

          setDeals(dealsData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const iconStyles = {
    color: "#1c2b39",
  };
  const getFlighNameFromCode = (code) => {
    let result = code;
    topFlightNames.forEach((x) => {
      if (x.codeIataAirport == code) {
        result = x.nameAirport;
        return;
      }
    });
    return result;
  };
  const sortBy = ({ key }) => {
    if (deals.length > 0) {
      let copy = deals;
      switch (key) {
        case "0":
          break;
        case "1":
          copy.sort(
            (a, b) => parseFloat(b.FromAmount) - parseFloat(a.FromAmount)
          );

          break;
        case "2":
          copy.sort(
            (a, b) => parseFloat(a.FromAmount) - parseFloat(b.FromAmount)
          );

          break;
        case "3":
          copy.sort((a, b) => (a.ToAirportName < b.ToAirportName ? -1 : 1));

          break;
        case "4":
          copy.sort((a, b) => b.ToAirportName.localeCompare(a.ToAirportName));
          break;

        default:
          break;
      }
      setDeals(copy);
      setSortDown((prev) => !prev);
    }
  };
  const menu = (
    <ANTD.Menu onClick={sortBy}>
      {/* <ANTD.Menu.Item key="0">Most Popular</ANTD.Menu.Item> */}
      <ANTD.Menu.Item key="1">Biggest Price</ANTD.Menu.Item>
      <ANTD.Menu.Item key="2">Lowest Price</ANTD.Menu.Item>
      <ANTD.Menu.Item key="3">Name Ascending</ANTD.Menu.Item>
      <ANTD.Menu.Item key="4">Name Descending</ANTD.Menu.Item>
    </ANTD.Menu>
  );

  return (
    <div className="deals-section">
      <Helmet>
        <title>
          Best Deals on Holiday Packages -{AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content="Book Cheap deals with HoldTrip and find your perfect holiday.
          "
        />
      </Helmet>
      <div className="deals-wrapper">
        <div className="container deals-top-content">
          <div className="bread-crumb-block">
            <ul className="breadcrumb">
              <li>
                <Link to="/">{AppConstants.APP_NAME}</Link>
              </li>
              <li>
                <Link to="/">Deals</Link>
              </li>
            </ul>
          </div>
          <div className="form-section-on-img">
            <div className="imp-text">
              <h1>
                <strong>Plan your perfect travel and enjoy low prices!</strong>
              </h1>
            </div>
            <div className="form-wrapper d-none">
              <ANTD.Card bordered={false}>
                <div className="form-inside-card">
                  <ANTD.Form>
                    <ANTD.Row>
                      <ANTD.Col span={10} className="form-src-dest">
                        <p>From</p>
                        <ANTD.Form.Item className="search-form-item">
                          <ANTD.Select placeholder="Anywhere">
                            <Option>Anywhere</Option>
                            <Option>United states of America</Option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col span={10} className="form-src-dest">
                        <p>To</p>
                        <ANTD.Form.Item className="search-form-item">
                          <ANTD.Select placeholder="Anywhere">
                            <Option>Anywhere</Option>
                            <Option>United states of America</Option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col span={4} className="search-deals">
                        <ANTD.Button>Search deals</ANTD.Button>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </div>
              </ANTD.Card>
            </div>
          </div>
        </div>
      </div>
      <div className="view-deals-wrapper">
        <div className="container">
          <div className="our-inspirations">
            <h2>Our inspirations</h2>
          </div>
          <div className="sorting-wrapper">
            <div className="sort-items">
              <ANTD.Dropdown overlay={menu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Sort By <DownOutlined />
                </a>
              </ANTD.Dropdown>
            </div>
            &nbsp;
            <div className="delas-view-type">
              <div className="view-icon">
                <TableOutlined
                  onClick={showGrid}
                  style={showGridView ? iconStyles : { color: "#bfbfbf" }}
                />
              </div>
              <div className="view-icon list-icon">
                <MenuOutlined
                  onClick={showList}
                  style={showListView ? iconStyles : { color: "#bfbfbf" }}
                />
              </div>
            </div>
          </div>
          <div className="deals">
            {showGridView && (
              <div>
                <DealsGridView dealsData={deals} isLoading={isLoading} />
              </div>
            )}

            {showListView && (
              <div>
                <DealsListView dealsData={deals} />
              </div>
            )}
          </div>
        </div>
      </div>
      <section>
        <Subscribe />
      </section>
    </div>
  );
};

export default Deals;
