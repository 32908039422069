import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Collapse,
  Grid,
  Skeleton,
  Modal,
  Button,
  message,
  Form,
  Card,
} from "antd";
import FlightsList from "./FlightsList";
// import FlightSearch from "../flight-search/FlightSearch";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";

import FlightFilters from "../Flight-Filters/FlightFilters";
import FlightSearch from "../flight-search/FlightSearch";
import FilterSkeletonLayout from "../FilterSkeleton/FilterSkeleton";
import FlightResultsCardSkeleton from "../FlightResultsCardSkeleton/FlightResultsCardSkeleton";
import NoResultFound from "../ErrorPages/NoResultFound";
import { getSearchRequestFromQuery } from "../flight-search/SearchRequestHelper";

import Sort from "./SortBy/Sort";

import "./SearchResults.scss";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import FlightModifySearch from "../FlightModifySearch/FlightModifySearch";

const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const SearchResults = () => {
  const { user } = useAuthContext();
  const {
    setFlightSearchObj,
    resetFlightSelectedData,
    updateFlightAirSearchRespObj,
    updateSelectedFlight,
    updateFlightFares,
    state: { flightAirSearchResp, flightSearchObj, selectedFlight },
  } = useFlightContext();

  const {
    state: { airlineMatrixReset },
    ResetAirlineMatrix,
  } = useContext(GlobalStatesContext);

  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [promodataSource, setPromoDataSource] = useState([]);
  const [showModifyForm,setShowModifyForm]=useState(false)

  const dateFormat = "DD-MM-YYYY";

  const getPromoCodes = () => {
    setPromoDataSource([]);

    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    getPromoCodes();
  }, []);

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  // flightmodifyformfunctiom

  const showflightsearchForm=(newstate)=>{
    setShowModifyForm(newstate)
  }

  const SetUpFlightFromResponse = (resp, requestData) => {
    setFlightSearchObj({
      ...requestData,
      traceId: resp.data.traceId,
      resultsType: resp.data.resultsType,
    });
    updateFlightAirSearchRespObj({
      ...resp.data,
      tripType: 1,
      flightDetails: resp.data.flightDetails.map((flight) => {
        return { ...flight, isVisible: true };
      }),
      ibFlightDetails: resp.data.ibFlightDetails.map((flight) => {
        return { ...flight, isVisible: true };
      }),
    });
  };

  const getFlightResults = () => {
    setIsLoading(true);
    resetFlightSelectedData();
    const requestData = getSearchRequestFromQuery();
    if (
      requestData.originDestinations[0].destination ===
      requestData.originDestinations[0].origin
    ) {
      message.error("Origin and Destination cannot be same", 3);
      setIsLoading(false);
      return false;
    }

    let reqData = {
      ...requestData,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    };

    ApiClient.post("flights/airSearch", reqData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          SetUpFlightFromResponse(resp, reqData);
        }
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  const onFormSubmit = () => {
    getFlightResults();
  };

  useEffect(() => {
    getFlightResults();
  }, []);

  const LoadingSkelTon = () => {
    return (
      <div>
        <div className="flight-results-skeleton-container">
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={6} xs={0}>
              <FilterSkeletonLayout />
            </Col>
            <Col md={18} xs={24}>
              <div className="flights-skeleton-card">
                <div className="nearbydates-skeleton">
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <div className="flights-sort-skeleton">
                  <Row className="flight-sort-skeleton-row">
                    <Col md={3} xs={0} className="sort-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                    <Col md={4} xs={0}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={4} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={3} xs={6} className="airline-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                  </Row>
                </div>
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="modify-search-container">
        <div className="modify-search-container-fluid">
          <Collapse
            activeKey={md ? ["1"] : key}
            showArrow={false}
            onChange={(val) => {
              setKey(val);
            }}
          >
            <Panel
              showArrow={false}
              header={<span className="hotels-hide-search">Modify Search</span>}
              key="1"
            >
             {/* <FlightSearch modify={true} onFormSubmit={onFormSubmit} /> */}
              {/* <FlightModifySearch modify={true} onFormSubmit={onFormSubmit} /> */}
            </Panel>
          </Collapse>
        </div>
      </div>
      <FlightModifySearch  showflightsearchForm={showflightsearchForm} />
      <div className="result_div">
        {isLoading ? (
          <LoadingSkelTon />
        ) : (
          <div>
            {Object.keys(flightAirSearchResp).length === 0 ||
            flightAirSearchResp.flightDetails.length === 0 ? (
              <div className="flight-results-skeleton-container">
                <NoResultFound />
              </div>
            ) : (
              <>
                <div className="result_div-container">
                  <Row gutter={{ xs: 8, md: 16 }}>
                    <Col md={6} xs={0}>
                      <div className="filter-section">
                        {flightAirSearchResp.flightDetails && (
                          <FlightFilters
                            updateFlightAirSearchRespObj={
                              updateFlightAirSearchRespObj
                            }
                            ResetAirlineMatrix={ResetAirlineMatrix}
                            selectedTripType={selectedTripType}
                            flightAirSearchResp={flightAirSearchResp}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md={18} xs={24}>
                      {/* <div className="flights-nearby-dates-container">
                        <FlightNearDates
                          flights_all={flights_all}
                          airSearchData={airSearchData}
                          onFlexiDateClicked={onFlexiDateClicked}
                        />
                      </div> */}
                      { showModifyForm ?
                         <Card className="flsmodifyshow">
                           <FlightSearch  modify={true} onFormSubmit={onFormSubmit}/>
                          </Card> : ""
                     }

                      <div className="flight-sort-container">
                        <Sort
                          updateFlightAirSearchRespObj={
                            updateFlightAirSearchRespObj
                          }
                          flightAirSearchResp={flightAirSearchResp}
                          airlineMatrixReset={airlineMatrixReset}
                          ResetAirlineMatrix={ResetAirlineMatrix}
                          selectedTripType={selectedTripType}
                          showNetFare={showNetFare}
                          setShowNetFare={setShowNetFare}
                        />
                      </div>
                      <div className="flight-search-results-sec">
                        <div>
                          <FlightsList
                            setSelectedTripType={setSelectedTripType}
                            flightAirSearchResp={flightAirSearchResp}
                            updateSelectedFlight={updateSelectedFlight}
                            flightSearchObj={flightSearchObj}
                            updateFlightFares={updateFlightFares}
                            selectedFlight={selectedFlight}
                            showNetFare={showNetFare}
                            promoData={promodataSource}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
        {/* <Col md={8} xs={8} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1">
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Map
          </h5>
        </Col> */}
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Flight Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              {flightAirSearchResp.flightDetails && (
                <FlightFilters
                  updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  flightAirSearchResp={flightAirSearchResp}
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              <div>
                <Sort
                  updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                  flightAirSearchResp={flightAirSearchResp}
                  airlineMatrixReset={airlineMatrixReset}
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  showNetFare={showNetFare}
                  setShowNetFare={setShowNetFare}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SearchResults;
