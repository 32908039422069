import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setSeoDetails, clearSeoDetails } from "../../ReduxContainer/SeoSlice";
import ApiClient from '../../helpers/ApiClient';

const SeoMetaData = ({ path }) => {

  const ReduxContainer = useSelector(state => state);
  const dispatch = useDispatch()

  const getSeoMataData = () => {
    ApiClient.get('admin/seodata').then((res) => {
      if(res.statusCode == 200) {
        if(res.data.length > 0) {
          dispatch(setSeoDetails(res?.data));
        }else{
          dispatch(setSeoDetails([]));
        }
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  useLayoutEffect(() => {
    getSeoMataData();
  }, [])

  const defualtMetaObj = [
    {
      PageTitle: 'Book Flights, Hotels, Bus Tickets & Holidays - holdtrips.co.uk',
      PageMetaDesc: 'Get cheap flight tickets, hotels, bus tickets and holiday packages with a best tour & travel company of India- holdtrips.co.uk.  Avail complete travel solutions for a lifetime journey.',
      PageMetaKeyword: 'airline tickets, cheap airlines, low cost, cheap airline tickets, flights, booking airline tickets, cheap flights, last minute flights, cheapest flights, low cost airlines, deals, flight deals'
    }
  ]
  const seoData = useSelector((state) => state.seoDetails);

  // Initialize state for active SEO details
  const [activeSeoDetails, setActiveSeoDetails] = useState(defualtMetaObj);

  // Function to filter SEO data based on the path
  const filterData = () => {
    if (Array.isArray(seoData) && seoData.length > 0) {
      const data = seoData.filter((item) => item.Path === path);
      if(data.length > 0) {
        setActiveSeoDetails(data);
      }else{
        setActiveSeoDetails(defualtMetaObj);
      }
    }
  };

  // Use useEffect to filter data when path changes
  useLayoutEffect(() => {
    filterData();
  }, [path, seoData]);

  return (
    activeSeoDetails.length > 0 && (
    <Helmet>
          <title>{activeSeoDetails[0]?.PageTitle}</title>
          <meta name="description" content={activeSeoDetails[0]?.PageMetaDesc} />
          <meta name="keywords" content={activeSeoDetails[0]?.PageMetaKeyword} />
    </Helmet>
    )
  );
};

export default SeoMetaData;
