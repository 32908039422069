import { Button, Card } from 'antd';
import './classcard.css'
import Slider from "react-slick";
import { StopOutlined } from '@ant-design/icons';
import luggage from "../../assets/vector-icons/luggage.svg"
import nobaggage from "../../assets/vector-icons/nobaggage.svg"
import timer from "../../assets/vector-icons/timer.svg"


const Classcard = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container classcardslider">
            <Slider {...settings}>
                <div>
                    <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                <Card
                        className='flsclasscard'
                        bodyStyle={
                            {
                                padding: 0
                            }
                        }
                    >
                        <p className='classcardtitle'>Economy Class</p>
                        <div className='classcardbody'>
                            <div className='classcardbodysection'>
                                <h5 className='mb5 classcardbaggageText'>Baggage</h5>
                                <p className='fs12 mb5 dflexcenter'><img src={luggage} className='clascardtimericon' /> Carry-on Baggage : <span className='classcardbaggageweight'>1*8 kg</span></p>
                                <p className='fs12 dflexcenter'><img src={nobaggage} className='clascardtimericon' /> Checked Baggage : Not included</p>
                            </div>
                            <div className='classcardbodysection2'>
                                <h5 className='mb5 classcardfarerulesText'>Fare Rules</h5>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Non-refundable</p>
                                <p className='fs12 mb5 dflexcenter'><StopOutlined className='classcardicon' /> Changes not permitted</p>
                                <p className='fs12 dflexcenter'><img src={timer} className='clascardtimericon' /> Ticketing : Within 12 hours after payment</p>

                            </div>
                            <div className='classcarddoller'>
                                <p><span className='classcardbodyh3'>US$340</span><span className='roundtroptext'>Round Trip</span></p>
                            </div>
                            <div className='clascardbtncont'>
                                <div className="check-details-btn2">
                                    {/* <Link to={"/deals?country=" + deal.FromCountryID.Country}> */}
                                    {/* <Link
                                        to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                                    > */}
                                        <Button className="btn-styles2">BOOK NOW</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Slider>
        </div>
    )
}

export default Classcard