import React, { useState, useEffect } from "react";
import "./FlightsList.scss";
import SeperatedView from "./SeperatedView/SeperatedView";
import CombinedView from "./CombinedView/CombinedView";
import { useHistory } from "react-router-dom";
import Classcard from "../ClassCard/ClassCard";

const FlightsList = ({ setSelectedTripType, flightAirSearchResp, updateSelectedFlight, flightSearchObj, updateFlightFares, selectedFlight, showNetFare, promoData }) => {
  const [resultsType, setResultsType] = useState(null);
  const history = useHistory();
  const [journeyType, setJourneyType] = useState("onward");
  const [combinedOnewayFlightList, setCombinedOneWayFlightList] = useState([]);
  const [combinedTwowayFlightList, setCombinedTwoWayFlightList] = useState([]);

  let selectedFlightData = updateSelectedFlight ? updateSelectedFlight : null;

  const changeFlightResultsView = (flightObj) => {
    if (flightObj.length === 0) {
      selectedFlightData([]);
      return;
    }
    selectedFlightData(flightObj);
    if (flightAirSearchResp.resultsType === "Separate") {
      if (journeyType === "onward") {
        changeReturnFlights();
        let backVisibleData = flightAirSearchResp.ibFlightDetails.filter((item) => item.isVisible);
        setReturnFlightResults(backVisibleData);
      }
      //  else {
      //   history.push("/flight/checkout");
      // }
    } else {
      history.push("/flight/checkout");
    }
  };
  useEffect(() => {
    if (flightSearchObj.airTravelType === "oneWay" || flightSearchObj.airTravelType === "multidestination" || flightSearchObj.resultsType === "Separate") {
      if (journeyType == "onward") {
        let onwardVisibleData = flightAirSearchResp.flightDetails.filter((item) => item.isVisible);
        setCombinedOneWayFlightList(onwardVisibleData);
      } else {
        let backVisibleData = flightAirSearchResp.ibFlightDetails.filter((item) => item.isVisible);
        setCombinedOneWayFlightList(backVisibleData);
        setSelectedTripType(2);
      }
    } else {
      let visibleData = flightAirSearchResp.flightDetails.filter((item) => item.isVisible);
      setCombinedTwoWayFlightList(visibleData);
    }
  }, [flightAirSearchResp]);

  useEffect(() => {
    setResultsType(flightAirSearchResp.resultsType);
  }, []);

  const setOnwardFlights = () => {
    setSelectedTripType(1);
    setJourneyType("onward");
  };

  const changeReturnFlights = () => {
    setSelectedTripType(2);
    setJourneyType("return");
  };
  const setReturnFlightResults = (flights) => {
    setCombinedOneWayFlightList(flights);
  };

  return (
    <div className="result-bar">
      <Classcard />
      {resultsType != null ? (
        resultsType === "Combined" ? (
          <CombinedView
            changeFlightResultsView={changeFlightResultsView}
            selectedFlight={selectedFlight}
            flightSearchObj={flightSearchObj}
            setFlightFareRules={updateFlightFares} 
            combinedOnewayFlightList={combinedOnewayFlightList}
            combinedTwowayFlightList={combinedTwowayFlightList}
            showNetFare={showNetFare}
            promoData={promoData}
          />
        ) : (
          <SeperatedView
            flightSearchObj={flightSearchObj}
            selectedFlight={selectedFlight}
            combinedOnewayFlightList={combinedOnewayFlightList}
            changeFlightResultsView={changeFlightResultsView}
            setOnwardFlights={setOnwardFlights}
            promoData={promoData}
            changeReturnFlights={changeReturnFlights}
            setFlightFareRules={updateFlightFares}
            journeyType={journeyType}
            showNetFare={showNetFare}
          />
        )
      ) : null}
    </div>
  );
};

export default FlightsList;
