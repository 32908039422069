import React from 'react'

import './whyglobaltravels.css'
import { Card, Col,  Row } from 'antd'
const WhyGlobalTravels = () => {
  return (
   <div className='whyglobalSection'>
      <div className='globalheadingscont'>
        <h4 className='headglobal'> Why Global travels</h4>
        <div className='globalsubhead'>
          <span className='descglobal '> We have teamed up with the most experienced holiday provider to meet our customers expectation</span>
        </div>
      </div>
      <div style={{padding:'20px'}}>
     <Row gutter={[16,8]} className='whyglobalrow'>
      
      <Col className="gutter-row whyglobalcol" xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
      <div className='whyGlobalTravelCard'
        >
          <div className='transparentCircle'></div>
          <div className='Homeicon'> <i className="fa fa-thin fa-plane travelPlaneIcon"></i></div>
            <div className='travellogo'>
             
              <p className="card-title traveltitle">Flights</p>
            <p className="card-subtitle  travelsubtitle">Big Choice, Big saving</p>
            <p className="card-text whyglobalcarddesc">This is the description of card This is the description of card</p>

            </div>
        </div>
      </Col>
      <Col className="gutter-row whyglobalcol" xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
        <div className='whyGlobalTravelCard'  >
        <div className='transparentCircle'></div>

          <div className='Homeicon'> <i className="fa fa-building-o travelPlaneIcon" /></div>
            <div className='travellogo'>
            {/* <span class="material-symbols-outlined travelPlaneIcon">apartment</span> */}
              {/* <i className="fa fa-thin fa-plane travelPlaneIcon"></i> */}
             
              <p className="card-title traveltitle">Hotels</p>
            <p className="card-subtitle  travelsubtitle"> Over 230.000 Hotels</p>
            <p className="card-text whyglobalcarddesc">We offer a wide range of Business Hotels, Family Hotels, Honeymoon Hotels, Sport Hotels at low rates</p>

            </div>
        </div>
      </Col>
      <Col className="gutter-row whyglobalcol" xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
      <div className='whyGlobalTravelCard'
        >
                    <div className='transparentCircle'></div>

            <div className='Homeicon'>
            <i className="fa fa-building-o travelPlaneIcon" /> 

            </div>
            <div className='travellogo'>
            {/* <span class="material-symbols-outlined travelPlaneIcon">domain_add</span> */}
              {/* <img  className='travelPlaneIcon' src="./images/why2.png" alt="" /> */}
              {/* <i className="fa fa-thin fa-plane travelPlaneIcon"></i> */}
              <p className="card-title traveltitle">Flight + Hotel</p>
            <p className="card-subtitle  travelsubtitle"> Book together & save</p>
            <p className="card-text whyglobalcarddesc"> Book flight plus hotel togetherwe offer you up to 30% discount on selected products for your next booking</p>

            </div>
        </div>
      </Col>
      <Col className="gutter-row whyglobalcol" xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
      <div className='whyGlobalTravelCard'
        >
                    <div className='transparentCircle'></div>

                      <div className='Homeicon'>
                      <i className="fa fa-th-large travelPlaneIcon"></i>

                      </div>

            <div className='travellogo'>
            {/* <span class="material-symbols-outlined travelPlaneIcon">luggage</span> */}
              <p className="card-title traveltitle">Packages</p>
            <p className="card-subtitle  travelsubtitle"> Buy Together To Save More</p>
            <p className="card-text whyglobalcarddesc"> Book two or more services from us to 
            save up to 10% on your total basket</p>
            </div>
            
        </div>
      </Col>
      </Row>
    </div>
    </div>
  )
}

export default WhyGlobalTravels
