import React from "react";
import * as ANTD from "antd";
import "./CheckInfo.scss";
import { Link } from "react-router-dom";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import Subscribe from "../subscribe/Subscribe";
import CheckInfoContactForm from "./CheckInfo-ContactForm/ContactForm";
import ContactUsForm from "../ContactUsForm/ContactUsForm";

const CheckInformation = () => {
  const { Panel } = ANTD.Collapse;

  return (
    <div className="checkInfoSection collapseCustomWhite">
      {/* <h4>R u in check info pageeeeeeeeeee</h4> */}
      <div className="checkInfoWrapper">
        <section className="check-imp-section">
          <div className="container">
            <div className="bread-crumb-block">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">HoldTrip</Link>
                </li>
                <li>
                  <Link to="/checkinfo">CheckInformation</Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="imp-text">
                <h1>
                  <strong>Important information</strong>{" "}
                </h1>
                <h4>We can help you with your trip</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="checkInfoBody">
          <div className="container">
            <div className="check-booking-status">
              <h2>Check booking status and details</h2>
              <div className="check-cards">
                <ANTD.Card bordered={false}>
                  <div className="check-card-body">
                    <h6>Check your booking details and status</h6>
                    <p>
                      You can check your booking status in the user zone. To da
                      that, log in or register with the e-mail that was used to
                      make the booking and go to the "My Bookings" bookmark
                    </p>
                    <Link to="/booking-reports">
                      <ANTD.Button className="send-confirm-btn">
                        Go to the account
                      </ANTD.Button>
                    </Link>
                  </div>
                </ANTD.Card>
              </div>
            </div>
            <div className="check-booking-status acc-main-head">
              <h2>Find a topic that is interesting for you</h2>
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <InfoCircleOutlined />
                  </div>
                  <div>
                    <h6>Current information (Covid-19):</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="My flight was cancellaed. How does the procedure in HoldTrip look like?"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <p>
                      If the airline has cancelled your flight, we will notify
                      you and keep you updated about the status of your case.
                      What does this process look like?
                    </p>
                    <ol className="numbered-list">
                      <li>
                        The airline confirms the cancellation of your flight.
                      </li>
                      <li>
                        We inform you about the cancellation and about starting
                        the process of checking available refund options.
                      </li>
                      <li>
                        We contact the airline for information about available
                        refund options.
                      </li>
                      <li>
                        After receiving the information, we provide you with a
                        message with the available refund options: refund to the
                        account, voucher, or bonus points. The type of refund
                        depends entirely on the airline.
                      </li>
                      <li>
                        You choose the refund option that is best for you.
                      </li>
                      <li>
                        In case of status changes or extending the waiting
                        period, we will send you an e-mail with the appropriate
                        information.
                      </li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>
                      If your booking consists of several tickets, you will
                      receive information about available refund options in
                      single or separate messages.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                      Remember! The refund for an unused ticket includes the
                      cost of the ticket and airport fees. Other eSky products
                      or services purchased with the ticket may not be
                      refundable.
                    </p>

                    <p>
                      In the current situation, the waiting time for information
                      from the airlines has been extended and is independent of
                      us.
                    </p>
                    <p className="link-label">
                      <strong>
                        Your flight has been cancelled, but you didn’t receive
                        any information from us? Contact us!
                      </strong>
                      <ANTD.Button className="contact-form-btn">
                        Contact form
                      </ANTD.Button>
                    </p>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="I want to change or reschedule my flight"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <div>
                      <p>
                        Change of date or resigning from travel due to the
                        COVID-19 pandemic is possible but depends on whether
                        your flights were cancelled (or airline rescheduled it).
                        Check what can you do in your situation.
                      </p>

                      <ul>
                        <li>
                          The airline cancelled or rescheduled my flight.
                          <br />
                          After confirming by the airline that your flight was
                          cancelled or its schedule was changed, you will
                          receive an email from us with further instructions.
                          Possible refund options or schedule changes depend
                          entirely on the airline.
                        </li>
                      </ul>
                      <p>How does this process look like?</p>
                      <ol className="numbered-list">
                        <li>
                          The airline confirms cancellation of your flight.
                        </li>
                        <li>
                          We inform you about the cancellation and starting the
                          process of checking available refund options.
                        </li>
                        <li>
                          We contact the airline to get information about
                          available refund options.
                        </li>
                        <li>
                          After receiving information about possible refund
                          options, we pass it on to you: cash refund to the
                          account, voucher or bonus points. The refund type
                          depends entirely on the airline.
                        </li>
                        <li>
                          You choose the refund option that is best for you.
                        </li>
                        <li>
                          In case of any status changes or extending the waiting
                          time, we will send you an e-mail with appropriate
                          information.
                        </li>
                      </ol>
                      <p>
                        If your booking consists of a few tickets, you will
                        receive information about possible refund options from
                        us in a single or separate message.
                      </p>

                      <ul>
                        <li>
                          My flight is to take place according to the schedule.
                        </li>
                      </ul>
                      <p>
                        The option of a refund or making changes to your ticket
                        depends entirely on the airline and the conditions of
                        the purchased fare. Most airlines allow to modify the
                        dates for an additional fee (in case of higher fares,
                        those changes may be free of charge).Attention! Airlines
                        usually do not refund the price difference if the ticket
                        price after the change is lower than the original one.
                        In case you want to cancel your flight, most airlines
                        will not allow for a refund if the cancellation is only
                        a passenger’s decision.
                      </p>

                      <p>
                        Therefore, we kindly ask you to wait for the final
                        decision to cancel or reschedule the flight. In the
                        current situation, the information from airlines
                        concerning schedule changes or cancellations are sent
                        about a week in advance.
                      </p>

                      <p>
                        Remember! Due to the COVID-19 pandemic, the waiting time
                        for contact is significantly extended, and in the first
                        instance, we handle the refunds for the canceled
                        flights. Please, contact us if your trip should start no
                        later than in 2 weeks.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="How long do I have to wait for my refund for a cancelled flight"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <div>
                      <p>
                        In case of receiving information from the airline about
                        cancelling the flight, we will start working as soon as
                        possible to obtain a refund for the ticket on your
                        behalf. Remember! The waiting time for a response from
                        most airlines is significantly extended due to a large
                        number of applications they consider related to the
                        COVID-19 pandemic.
                      </p>

                      <p>
                        The refund process may take up to several months from
                        the moment when the airline has received our refund
                        application. Some airlines have announced that they will
                        launch the refund process after the official
                        announcement that the COVID-19 pandemic is over.
                      </p>

                      <p>
                        At the same time, airlines allow receiving a refund in a
                        different form: voucher or bonus points. If you decide
                        on alternative options, the refund process is faster but
                        can still take longer than usual.
                      </p>

                      <p>
                        If you choose any of the available refund options we
                        will keep you updated on the progress of the process.
                      </p>

                      <p>
                        Remember! The availability of refund options depends on
                        the airline, whose ticket you have.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="When and in what form do I receive a refund for cancelled charter flight?"
                    key="4"
                  >
                    <div>
                      <p>
                        In case of receiving information from the tour operator
                        about cancelling the flight, we will start working as
                        soon as possible to obtain a refund for the ticket on
                        your behalf.
                        <br />
                        Remember! The waiting time for a response from most tour
                        operators is significantly extended due to a large
                        number of applications they consider related to the
                        COVID-19 pandemic.
                      </p>
                      <p>
                        After we receive the payment from the tour operator, who
                        is the organizer of your trip, the money will be
                        refunded using the same payment method that was used
                        during the purchase. After we make a refund, we will
                        notify you immediately via e-mail.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="When and in what form do I receive a refund for cancelled charter flight?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        In case of receiving information from the tour operator
                        about cancelling the flight, we will start working as
                        soon as possible to obtain a refund for the ticket on
                        your behalf.
                        <br />
                        Remember! The waiting time for a response from most tour
                        operators is significantly extended due to a large
                        number of applications they consider related to the
                        COVID-19 pandemic.
                      </p>
                      <p>
                        After we receive the payment from the tour operator, who
                        is the organizer of your trip, the money will be
                        refunded using the same payment method that was used
                        during the purchase. After we make a refund, we will
                        notify you immediately via e-mail.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="When and how do I receive a refund for a cancelled Flight+Hotel package?"
                    key="4"
                  >
                    <div>
                      <p>
                        The refund process for cancelled Flight+Hotel packages
                        may take up to 180 days as it is subject to the
                        provisions of the so-called Anti-crisis Shield (Article
                        15k (1) of the Act of 31 March 2020 amending the Act on
                        special solutions related to the prevention,
                        counteraction and combating COVID-19, other infectious
                        diseases and crisis situations caused by those diseases,
                        as well as some other Acts).
                      </p>

                      <p>
                        After we receive payment from partners providing
                        services within your Flight+Hotel package, the money
                        will be refunded using the same payment method that was
                        used during the purchase. After we make a refund, we
                        will notify you immediately via e-mail.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="What is HoldTrip Wallet and what are its benefits"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        eSky Wallet is an electronic wallet that allows you to
                        obtain a refund for unused booking faster and you can
                        get additional benefits. Currently, you can only use
                        eSky Wallet after you receive a message from us with the
                        option to choose this form of refund.
                      </p>
                      <p>Find out more about HoldTrip Wallet .</p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Information for the Lufthansa Group airlines ticket holder – flexible change and 50 euro bonus"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>If you have tickets from Lufthansa Group airline:</p>

                      <ul className="styled-list">
                        <li>Lufthansa,</li>
                        <li>Air Dolomiti,</li>
                        <li>Austrian Airlines,</li>
                        <li>Brussels Airlines,</li>
                        <li>Swiss,</li>
                      </ul>
                      <p>
                        which were{" "}
                        <strong>bought no later than on 19.04.2020</strong>,
                        check the detailed information regarding new
                        possibilities of{" "}
                        <strong>
                          making flexible changes in the travel date or
                          destination
                        </strong>{" "}
                        and getting <strong>a 50 euro bonus</strong> –{" "}
                        <a
                          href="/travel-guide/airline-tickets/check-in-and-passenger-service/how-can-i-change-the-ticket-with-50-euro-bonus-in-lufthansa-group"
                          rel="noreferrer noopener"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Restrictions for travellers. Can I travel?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        Due to the COVID-19 pandemic, many countries have
                        suspended the possibility of free movement, crossing the
                        borders, and entry for foreigners.
                      </p>

                      <p>
                        Because the introduced restrictions and rules regarding
                        travelling change irregularly and may be unclear, we
                        have prepared a special website with information about
                        restrictions that are regularly updated. Check the
                        latest information about the possibility of crossing the
                        borders and travelling around different countries <br />
                        <br />
                        Remember! Before embarking on a journey, read the
                        official announcements of the authorities of the country
                        you are going to visit.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="How can I get in contact with HoldTrip"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        Currently, you can only contact us by using the contact
                        form
                      </p>
                      <p>
                        Due to a large number of inquiries, we have decided to
                        temporarily disable our helpline.
                        <br />
                        Thanks to this, we can speed up handling the inquiries
                        and processing refunds, which is our priority in the
                        current situation.
                      </p>

                      <p>
                        Remember! If your journey is to start later than in 2
                        weeks, please wait before you contact us.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
            <div className="check-booking-status">
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <CheckCircleOutlined />
                  </div>
                  <div>
                    <h6>Online check-in:</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Where can I find a link to the online check-in?"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <div>
                      <p>
                        The link to the online check-in form is in your ticket,
                        in “Prepare for flight check-in” section.
                      </p>

                      <p>
                        Also, 7 days prior to the departure, you will receive
                        from us an e-mail reminder about the need to provide the
                        check-in data, along with a link to the form.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Online check-in step by step"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <div className="text-wrapper">
                      <p>
                        The check-in allows to prepare the boarding passes that
                        authorize you to board the plane. In case of popular
                        low-cost airlines (such as Ryanair, Wizz Air,
                        Laudamotion) it is obligatory, and the lack of printed
                        boarding passes may cause charging an extra fee or
                        refusal to board the plane.
                      </p>

                      <p>
                        The check-in procedure is different in each airline. But
                        you don’t have to worry about that because we will check
                        you in on your behalf. To make it possible, in some
                        cases we will need you to provide us with the personal
                        data of yourself and the people who travel with you.
                      </p>

                      <ul className="styled-list">
                        <li>
                          You will receive a link check-in data form to along
                          with your ticket via e-mail.
                        </li>
                        <li>
                          You can enter the date to check-in from the moment you
                          receive the ticket. Don’t wait up with filling it out.
                          Thanks to this, we will easily check you in and send
                          boarding passes as soon as the airline opens check-in.{" "}
                          <strong>Attention!</strong> Check-in may not be
                          possible in case of providing data later than 48 hours
                          before departure.
                        </li>
                        <li>
                          Boarding passes will be sent to the e-mail address
                          provided during the booking process from 48 to 8 hours
                          before the scheduled departure. To make this procedure
                          go smoothly and correctly, all passengers data must be
                          completed. If you have less than 8 hours to your
                          flight, and you haven’t received any e-mail with
                          boarding passes, check your SPAM folder and make sure
                          that the e-mail address you provided while making the
                          booking is correct.
                        </li>
                        <li>
                          Boarding passes must be printed and shown at the
                          airport along with the ID document used for check-in.
                        </li>
                      </ul>
                      <p>
                        <strong>Remember!</strong> If your travel consists of a
                        few flights, the boarding passes will be sent in
                        separate e-mails - from 48 to 8 hours before departure
                        of each flight.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Do I have to print the boarding pass?"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <div className="text-wrapper">
                      <p>
                        Some of the popular airlines may not accept boarding
                        passes as a PDF file displayed on the screen of a
                        smartphone or other mobile device. Therefore, to be sure
                        of boarding the plane, it’s best to print out all
                        boarding passes that you will receive.
                      </p>

                      <p>
                        Printed boarding pass guarantees that you will board the
                        plane.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="I don’t have boarding passes. Where can I find them?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        We send boarding passes to the e-mail provided during
                        the booking from 48 to 8 hours before the flight. If
                        your travel consists of a couple of flights, boarding
                        passes will be sent in separate e-mails – from 48 to 8
                        hours before each one of them.
                      </p>

                      <p>
                        <strong>Example no. 1</strong>
                      </p>

                      <p>If you fly:</p>

                      <ul className="styled-list">
                        <li>from Warsaw to London on 1.06, 3.00 PM,</li>
                        <li>from London to Warsaw on 7.06, 7:00 PM.</li>
                      </ul>
                      <p>You will receive boarding passes:</p>

                      <ul className="styled-list">
                        <li>
                          for a flight from Warsaw to London on 30.05, 3:00 PM
                          at the earliest (1.06, 7:00 AM at the latest),
                        </li>
                        <li>
                          for a flight from London to Warsaw on 5.06, 7:00 PM at
                          the earliest ( 7.06, 11:00 AM at the latest).
                        </li>
                      </ul>
                      <p>
                        <strong>Example no. 2</strong>
                      </p>

                      <p>If you fly:</p>

                      <ul className="styled-list">
                        <li>
                          from Warsaw to Tenerife on 1.06, 9:00 AM, with a
                          connecting flight in Barcelona on 1.06, 1:00 PM
                          (MultiLine flight),
                        </li>
                        <li>
                          from Tenerife to Warsaw on 7.06, 12:00 AM, with a
                          connecting flight in Frankfurt on 7.06, 6:00 PM
                          (MultiLine flight).
                        </li>
                      </ul>
                      <p>You will receive boarding passes:</p>

                      <ul className="styled-list">
                        <li>
                          for a flight from Warsaw to Barcelona on 30.05, 9:00
                          AM at the earliest (1.06, 1:00 AM at the latest),
                        </li>
                        <li>
                          for a flight from Barcelona to Tenerife on 30.05, 1:00
                          PM at the earliest (1.06, 5:00 AM at the latest),
                        </li>
                        <li>
                          fo a flight from Tenerife to Frankfurt on 5.06, 12:00
                          AM at the earliest (7.06, 4:00 AM at the latest),
                        </li>
                        <li>
                          for a flight from Frankfurt to Warsaw 5.06, 6:00 PM at
                          the earliest (7.06, 10:00 AM at the latest).
                        </li>
                      </ul>
                      <p>
                        If your flight is in less than 8 hours and you do not
                        have the e-mail with the boarding passes, check the SPAM
                        folder and make sure what e-mail was provided during the
                        booking. It is possible that the boarding passes were
                        sent to another inbox you own.
                      </p>

                      <p>
                        Attention! When it comes to flights that require
                        providing additional details in the check-in form,
                        boarding passes will be sent only after the required
                        information is added. If your booking includes
                        additional passengers, make sure that the details were
                        provided for all of them.
                      </p>
                      <div className="form-link">
                        <p className="link-label">
                          <strong>
                            You can’t find the e-mail with the boarding passes
                            or the link to the check-in form? Contact us
                          </strong>
                          <button type="button" className="contact-form-btn">
                            Contact form
                          </button>
                        </p>
                      </div>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="Error during online check-in" key="4">
                    <div>
                      <p>
                        If an error occurred during providing the passenger
                        details in the check-in form or during an independent
                        check-in, you have to immediately inform us about it.
                      </p>
                      <div className="form-link">
                        <p className="link-label">
                          {" "}
                          <strong>
                            Do you want to report a problem with the check-in?
                            Contact us
                          </strong>
                          <button type="button" className="contact-form-btn">
                            Contact form
                          </button>
                        </p>
                      </div>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
            <div className="check-booking-status">
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <i className="fa fa-suitcase"></i>
                  </div>
                  <div>
                    <h6>Luggage</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="How can I buy luggage?"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <div className="text-wrapper">
                      <p>
                        Checked luggage can be bought during booking the ticket
                        or added after, but no later than 48 hours before the
                        flight.
                      </p>

                      <p>Exemplary conditions:</p>

                      <p>Ryanair (price for the flight leg/passenger):</p>

                      <ul className="styled-list">
                        <li>
                          10 kg, 1 piece of luggage 55 x 40 x 20 cm – from 14
                          EUR to 28 EUR*
                        </li>
                        <li>
                          20 kg, 1 piece of luggage 81 x 119 x 119 cm – from 27
                          EUR to 51 EUR*
                        </li>
                      </ul>
                      <p>Wizz Air (price for the flight leg/passenger):</p>

                      <ul className="styled-list">
                        <li>
                          10 kg, 1 piece of luggage – from 10 EUR to 28 EUR*
                        </li>
                        <li>
                          20 kg, 1 piece of luggage – from 16 EUR to 51 EUR*
                        </li>
                        <li>
                          32 kg, 1 piece of luggage – from 24 EUR to 73 EUR*
                        </li>
                      </ul>
                      <p>
                        Other airlines – [<a href="">check here</a>]
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        * Prices are set by the airlines and might change.
                        Provided values are only indicative.
                      </p>
                      <div className="form-link">
                        <p className="link-label">
                          {" "}
                          <strong>
                            Do you want to add checked luggage to your booking?
                            Contact us{" "}
                          </strong>{" "}
                          <button type="button" className="contact-form-btn">
                            Contact form
                          </button>
                        </p>
                      </div>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Checked luggage and hand luggage - most important information"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <div className="text-wrapper">
                      <p>
                        Airlines distinguish two types of luggage - hand luggage
                        (cabin) or checked luggage (checked into the hold of the
                        plane)
                      </p>

                      <p>
                        Hand luggage can be taken to the board of a plane and
                        placed in the storage compartment above the seats free
                        of charge or for an additional fee (depending on the
                        fare and the airline). However, you should remember that
                        due to the safety reason, things that can be carried in
                        the hand luggage are strictly regulated by law. The same
                        law also determines, for example, how much liquid can be
                        taken in the hand luggage. What is more, you have to
                        remember that each airline strictly determines its own
                        rules applying to luggage dimensions and weight.
                      </p>

                      <p>
                        Checked luggage is baggage whose weight and dimensions
                        are larger than hand luggage. In case of low-cost
                        airlines (such as Ryanair, Wizz Air and easyJet) it is
                        always submitted to an additional charge, whereas in
                        regular airlines, checked luggage may be included in the
                        ticket price. Regulations also allow to take into your
                        checked luggage things that cannot be packed into your
                        hand luggage. Checked luggage is recommended for longer
                        trips. As with hand luggage, the dimensions and weight
                        of the checked luggage are strictly determined by
                        airlines. Checked luggage should always be checked at
                        the airport, at the selected airlines’ check-in desk,
                        before you proceed to the security control.
                      </p>

                      <p>
                        Check, what can you pack into the hand and checked
                        luggage!
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Luggage and priority boarding"
                    key="3"
                    className="site-collapse-custom-panel"
                  >
                    <div>
                      <p>
                        Priority boarding is a service that allows the passenger
                        to take place in the priority queue when boarding the
                        plane.
                      </p>

                      <p>
                        Some of the popular low-cost airlines (e.g. Ryanair and
                        Wizz Air) as a part of the priority boarding service,
                        besides faster boarding, also offer the option of taking
                        additional hand luggage. However, this is not a rule, so
                        every time you want to buy priority boarding, check what
                        is included in the service.
                      </p>
                      <div>
                        <p>
                          <strong>
                            Do you want to add priority boarding to your
                            booking? Contact us
                          </strong>
                          <button
                            type="button"
                            className="go-to-contact-form btn ghost small function"
                            data-contact-form-subcategory-id="1180"
                          >
                            Contact form
                          </button>
                        </p>
                      </div>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="What are the baggage limits?" key="4">
                    <div>
                      <p>
                        Baggage limits are set by each airline itself, which
                        means that it’s best to make sure what kind of baggage
                        you can take every time you before you buy the flight
                        ticket.
                      </p>

                      <p>
                        Information about the maximum number of pieces, weight
                        and dimensions of luggage can be found at the stage of
                        the booking process - in the booking form and then, on
                        the ticket received from us.
                      </p>

                      <p>
                        Also, luggage information for each airline can be found.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
            <div className="check-booking-status">
              {/* <h2>Find a topic that is interesting for you</h2> */}
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <i className="fa fa-plane"></i>
                  </div>
                  <div>
                    <h6>My booking:</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="I don't have booking confirmation. How can I check it's status?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        Booking confirmation is sent via e-mail after completing
                        the booking and settling the payment for tickets. If you
                        can’t find information from us in your inbox, check the
                        SPAM folder and make sure that the e-mail provided
                        during the booking process was correct. You can also use
                        a special contact form and resend the booking
                        confirmation to your e-mail address.
                      </p>
                      <br />
                      <p>
                        Also, the current booking status can be found
                        <a href="#!"> in your account in travel User zone </a>
                        in "My Bookings" section, or
                        <a href="#!"> under this link</a>( by providing this
                        booking number and e-mail address used for booking).
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="I don't remember my booking number. What should I do?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        Booking number can be found in the e-mail we send you to
                        the address provided while making the booking. If you
                        lose the number, or you don’t receive it from us, you
                        can use a special contact form on our website and resend
                        the e-mail with booking details.
                      </p>
                      <br />
                      <p>
                        Do you want to receive the booking confirmation e-mail
                        again? Use the
                        <a href="#!"> contact form</a>
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Incorrect e-mail address provided during the booking. What should I do?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        If you are sure that the e-mail address you provided to
                        make booking was incorrect, e.g. there was a typo,
                        please contact us immediately. In your application, send
                        us information about your booking - first name and
                        surname, date and route of flight. It is possible that
                        our consultants will ask for some additional details to
                        verify the application.
                      </p>
                      <br />
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How can I buy priority boarding?" key="4">
                    <div className="text-wrapper">
                      <p>
                        Priority boarding service can be bought during booking
                        the ticket or added after, but no later than 48 hours
                        before the flight.
                      </p>
                      <br />
                      <p>
                        Attention! Priority boarding service is limited and
                        might be unavailable.
                      </p>
                      <br />
                      <p>Exemplary conditions:</p>
                      <br />
                      <p>Ryanair (price for the flight leg/passenger):</p>
                      <ul className="styled-list">
                        <li>
                          boarding the plane through the priority lane, the
                          possibility to take personal bag no bigger than 40 x
                          20 x 25 cm and a hand luggage no bigger than 55 x 40 x
                          20 cm and up to 10 kg – from 7 USD to 20 USD*
                        </li>
                        <br />
                        <p>Wizz Air (price for the flight leg/passenger):</p>
                        <br />
                        <li>
                          boarding the plane through the priority lane, the
                          possibility to take one hand luggage no bigger than 40
                          x 30 x 20 cm and up to 10 kg, second hand luggage no
                          bigger than 50 x 40 x 23 cm and up to 10 kg – from 5.5
                          USD to 34 USD*
                        </li>
                      </ul>
                      <br />
                      <p>
                        * Prices are set by the airlines and might change.
                        Provided values are only indicative.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How can I buy seat selection service?" key="4">
                    <div className="text-wrapper">
                      <p>
                        Airlines booking systems assign seats on the plane
                        randomly. In case of a flight with co-passengers,
                        carriers split up people travelling together (the only
                        exception to this rule is a situation in which a child
                        travels with a guardian). To avoid such inconvenience,
                        it is worth to buy a seat selection service.
                      </p>
                      <br />
                      <p>
                        The service can be bought during booking the ticket
                        (option available only for selected airlines) or by
                        contacting us through the contact form (link below).
                      </p>
                      <br />
                      <p>
                        Booking a selected seat is a paid service and its price
                        depends on the airline and the type of the chosen seat.
                        In response to your message, our consultants will send
                        information about seats availability and prices.
                      </p>
                      <br />
                      <p>
                        Price of the service varies from 5 USD to 28 USD per
                        person (for every flight leg).*
                      </p>
                      <br />
                      <p>
                        Attention! The number of free seats on the plane changes
                        dynamically and the selected seats might be unavailable.
                      </p>
                      <br />
                      <p>
                        * Prices are set by the airlines and might change.
                        Provided values are only indicative.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How to pay for the booking?" key="4">
                    <div className="text-wrapper">
                      <p>
                        You can pay for the reservation in eSky with the credit
                        card. Credit card transactions guarantee instant ticket
                        booking and the highest level of security thanks to PCID
                        SS standards.
                      </p>
                      <br />
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="I want to cancel a booking. How can I do that?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        In most of the low-budget airlines (including Ryanair,
                        Wizz Air, easyJet, Laudamotion) returning the ticket is
                        impossible. The airlines emphasize in the booking
                        conditions that the tickets are non-refundable.
                      </p>
                      <br />
                      <p>
                        When it comes to commercial airlines (e.g. LOT,
                        Lufthansa, Air France), the possibility to return a
                        ticket is specified in the tariff conditions.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How to cancel a hotel booking?" key="4">
                    <div className="text-wrapper">
                      <p>
                        If you want to cancel your hotel booking, you have to
                        first make sure, whether your booking allows for this
                        option and what are the rules of the possible
                        cancellation. Information about terms of accommodation
                        cancellation can be found on our website, in the room’s
                        description, and in the booking confirmation e-mail.
                      </p>
                      <br />
                      <p>
                        <b> Important!</b> You should make sure whether booking
                        conditions from the selected booking allow for a refund.
                      </p>
                      <br />
                      <p>
                        To cancel a hotel booking, you have to
                        <a href="#!"> log into</a>
                        your account and choose the right hotel booking or
                        <a href="#!"> check your travel status</a>
                        and choose the option: <b> Cancel booking</b>
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Information regarding change of flight time by the airline"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        If an airline changes the time of the flight, we will
                        inform you about it. Information about the change will
                        be sent to the email provided during the booking.
                      </p>
                      <br />
                      <p>
                        Message from us will be titled: <br />
                        IMPORTANT! Schedule change notification, confirmation
                        code [booking number]
                      </p>
                      <br />
                      <p>
                        Attention! We will send the new ticket with a changed
                        time of flight to the e-mail provided during the
                        booking.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
            <div className="check-booking-status">
              {/* <h2>Find a topic that is interesting for you</h2> */}
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <EditOutlined />
                  </div>
                  <div>
                    <h6>Changes in the booking:</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How can I change the flight date?" key="4">
                    <div className="text-wrapper">
                      <p>
                        The possibility to change the travel date depends on the
                        airline. Some of the low-budget airlines allow to change
                        the date for an additional fee (depending on the carrier
                        it might be as high as 200 USD), while some of the
                        commercial airlines provide such service only for
                        selected tariffs.
                      </p>
                      <br />
                      <p>
                        The fee for changing the date is charged for each
                        passenger and for each flight leg (single flight).
                        Additionally, the difference between the prices of an
                        original and a new ticket might be added to the amount.
                        Airlines do not return a difference if the price of the
                        new ticket is lower than the original one.
                      </p>
                      <p>
                        In some cases eSky charges an agency fee in the amount
                        of 18 USD for making a change on the ticket, which is
                        included in the total price for the change. This fee is
                        charged for each passenger that needs a change made.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Invalid passenger details in the booking. What should I do?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        In most of the airlines changing the details on the
                        ticket is not permitted. It is possible to correct the
                        incorrect data, however, the scope and the price of the
                        changes depends on the policy of a specific airline.
                      </p>
                      <br />
                      <p>All incorrect information on the ticket, such as:</p>
                      <br />
                      <ul>
                        <li>misspelling in the first name or last name,</li>
                        <li>wrong date of birth,</li>
                        <li>wrong passenger title (Mr./Mrs.),</li>
                        <li>wrong passenger type (adult/child)</li>
                      </ul>
                      <br />
                      <p>
                        should be immediately reported. Our consultants will
                        check the possibility to correct the mistakes mentioned
                        above, inform about the possible charges for the change
                        and contact the airlines to correct the details on the
                        ticket.
                      </p>
                      <br />
                      <p>
                        Attention! Lack of the special characters and accents
                        (e.g.. č, é, ö), as well spaces and diacritics in the
                        multipart last names on the ticket, is not an error.
                        Airlines do not use them on purpose.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
            <div className="check-booking-status">
              {/* <h2>Find a topic that is interesting for you</h2> */}
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div className="info-icon">
                    <FileDoneOutlined />
                  </div>
                  <div>
                    <h6>Invoice:</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="Sending invoice" key="4">
                    <div className="text-wrapper">
                      <p>
                        Vat invoice will be issued and sent to the e-mail
                        address provided at the booking process, up to the 15th
                        day of the following month after purchasing the ticket
                      </p>
                      <br />
                      <p>
                        Example: If the ticket was purchased in june, the
                        invoice will be sent by july 15 at the latest.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="How can I change the buyer's details on the invoice?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        The change of the buyer's details (not the passenger!)
                        is possible up to 30 days after purchasing he ticket.
                        Travel allows to change the details:
                      </p>
                      <br />
                      <ul>
                        <li>
                          from a natural person to a different natural person,
                        </li>
                        <li>from a natural person to a company.</li>
                      </ul>
                      <br />
                      <p>
                        It is not possible to change the details from the
                        company to another company or from the company to a
                        natural person.
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel header="How can I check the payment status?" key="4">
                    <div className="text-wrapper">
                      <p>
                        Payment status is sent in the e-mail with booking
                        confirmation. If you pay with a credit card or online
                        bank transfer, we send the e-mail with the booking
                        confirmation and information about its successful
                        completion. Passengers that chose traditional bank
                        transfer will receive information about the initial
                        ticket booking and pending payment. We will send the
                        confirmation of booking completion only after the
                        payment is settled on our account
                      </p>
                      <p>
                        Do you want to check whether your transaction was
                        successful? Check the status of your payment
                        <a href="#!"> in your account in User zone</a> in the
                        "My Bookings" section or by
                        <a href="#!">clicking this link.</a>
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="How can I get the an invoice duplicate?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>Duplicate of the invoice is issued on request.</p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
              <div className="check-accordions">
                <ANTD.Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header="Why there are two amounts on an invoice, both in different currencies?"
                    key="4"
                  >
                    <div className="text-wrapper">
                      <p>
                        Because the purchase is processed by a polish company,
                        the legal regulations require introducing two amounts on
                        the invoice. One of them is the amount in which the
                        service was purchased, and the other one is its
                        equivalent in PLN (Polish Zloty).
                      </p>
                    </div>
                  </Panel>
                </ANTD.Collapse>
              </div>
            </div>
          </div>
        </section>
        <section>
          {/* <h2>Contact us</h2> */}
          {/* <CheckInfoContactForm /> */}
          {<ContactUsForm />}
        </section>
        <section>
          <Subscribe />
        </section>
      </div>
    </div>
  );
};

export default CheckInformation;
