import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Row } from "antd";
import Table from "react-bootstrap/Table";
import "../Busticketsuccess/Busticketsuccess.scss";
import moment from "moment";
const { Content } = Layout;

const BuildPackageTicketSuccess = ({ buildTicketDetails }) => {
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <h4 className="ticket-lti-bus">Build Package ltinerary</h4>
            {/* sub--row--start */}

            {buildTicketDetails.message === "FAILED" && <p>Failed</p>}

            {buildTicketDetails?.bookingRefNo ? (
              <>
                <div className="paymet-booking-tic mt-4">
                  <h4 className="ml-0">
                    Build Package Ticket Booking Payment Successful
                  </h4>
                  <p className="ml-0">
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p className="ml-0">
                    Ref-No : {buildTicketDetails?.bookingRefNo}
                  </p>
                  <p className="ml-0">
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={8} md={12} sm={18} xs={24}>
                    <Button className="tic-retrive-book ml-0" htmlType="submit">
                      <Link
                        to={`buildpackage/ticket?ref=${buildTicketDetails?.bookingRefNo}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
                {/* <Row className="mt-4">
                  <Col md={24} xs={24}>
                    <ul className="timeline timeline-left">
                      <Row className="tours-book-method">
                        <Col md={24} xs={24}>
                          <h4 className="hotel-room-info">Hotel</h4>
                        </Col>
                      </Row>
                      {buildTicketDetails.hotelsPackageDetails.length > 0
                        ? buildTicketDetails.hotelsPackageDetails.map(
                            (hotel) => {
                              return (
                                <li className="timeline-inverted timeline-item">
                                  <div className="timeline-badge warning">
                                    <span className="font-12">
                                      <i
                                        className="fa fa-hospital-o"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                  <div className="timeline-panel">
                                    <div className="timeline-body">
                                      <div className="info-hotel-main p-2">
                                        <Row>
                                          <Col md={24} xs={24}>
                                            <h5 className="review-name-hotel">
                                              {hotel.HotelName}
                                            </h5>

                                            <ul className="d-flex align-item-center justify-content-between flex-wrap">
                                              <li className="fn-bold">
                                                Check In Date:{" "}
                                                {moment(
                                                  hotel.CheckInDate
                                                ).format("DD-MM-YYYY")}
                                              </li>

                                              <li className="fn-bold">
                                                Check Out Date :{" "}
                                                {moment(
                                                  hotel.CheckOutDate
                                                ).format("DD-MM-YYYY")}
                                              </li>
                                              <li className="fn-bold">
                                                No Of Night:{" "}
                                                {hotel.Rooms[0].noOfNight}
                                              </li>
                                              <li className="fn-bold">
                                                Total: ₹ {hotel.Fare}
                                              </li>
                                            </ul>
                                          </Col>
                                        </Row>

                                        <Table
                                          className="mt-2"
                                          responsive="lg"
                                          striped
                                          bordered
                                          hover
                                        >
                                          <thead>
                                            <tr>
                                              <th>Room Type</th>
                                              <th>Unit</th>
                                              <th>Room Cost </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {hotel.Rooms.map((room) => {
                                              return (
                                                <tr>
                                                  <td>{room.RoomName}</td>
                                                  <td>{room.noOfNight}</td>
                                                  <td>₹ {room.pricePerRoom}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )
                        : null}

                      <Row className="tours-book-method">
                        <Col md={24} xs={24}>
                          <h4 className="hotel-room-info">Tours</h4>
                        </Col>
                      </Row>
                      {buildTicketDetails.tourDetails.length > 0
                        ? buildTicketDetails.tourDetails.map(
                            (tour, index) =>
                              tour &&
                              tour?.length > 0 &&
                              tour.map((optionData) => (
                                <li className="timeline-inverted timeline-item">
                                  <div className="timeline-badge warning">
                                    <p className="mb-0">Day {index + 1}</p>
                                  </div>
                                  <div className="timeline-panel ">
                                    <div className="timeline-body">
                                      <h6 className="font-weight-bold">
                                        {optionData.tourName}
                                      </h6>

                                      <p className="font-weight-bold mb-3">
                                        Tour Date :{" "}
                                        {moment(
                                          optionData.tourDate,
                                          "YYYY-MM-DD"
                                        ).format("DD-MM-YYYY")}
                                      </p>

                                      <ul className="mt-4">
                                        <li>
                                          <span className="font-weight-bold">
                                            Tour Option :{" "}
                                            {optionData.optionName}
                                          </span>
                                        </li>

                                        <li>
                                          <span className="font-weight-bold">
                                            Transfer Option :{" "}
                                          </span>
                                          <span>{optionData.transferName}</span>
                                        </li>
                                        <li>
                                          <span className="font-weight-bold">
                                            Duration :{" "}
                                          </span>
                                          <span>{optionData.duration}</span>
                                        </li>
                                        <li>
                                          <span className="font-weight-bold">
                                            Duration :{" "}
                                          </span>
                                          <span>{optionData.duration}</span>
                                        </li>
                                        <li>
                                          <span className="font-weight-bold">
                                            Start Time :{" "}
                                          </span>
                                          <span>{optionData.startTime}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              ))
                          )
                        : null}
                    </ul>
                  </Col>
                </Row> */}
              </>
            ) : 
              // null}
              <Col lg={24} md={24} sm={24} xs={24}>
                <p className="mb-0">
                  <strong>
                    Message :  Booking is on hold please don't try to book the ticket as our backend team is working on this ticket, please allow us 30 minutes to confirm the same, if you don't receive any confirmation within 30 minutes please call us on this number
                    <table style={{ 'color': 'red' }}>
                      <tr>
                        <td> 011 4951 13 13</td>
                        <td> ( 10 am - 7:00 pm ) </td>
                      </tr>
                      <tr>
                        <td> + 91 9667766204</td>
                        <td>  ( 07:00 pm - 12:00 am )  </td>
                      </tr>
                      <tr>
                        <td> +91 916686 883 7 </td>
                        <td>( 07:00 pm - 12:00 am )  </td>
                      </tr>
                      <tr>
                        <td> +91 9958690805</td>
                        <td>  ( 07:00 pm - 12:00 am )  </td>
                      </tr>
                    </table>
                    Email us at - activities@holdtrips.co.uk
                  </strong>
                </p>
              </Col>
            }
            </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default BuildPackageTicketSuccess;
