import React, { useState } from "react";
import PaymentSelection from "../../common/PaymentSelection/PaymentSelection"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
} from "antd";

import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import APIClient from "../../helpers/ApiClient";

import "./Visa.scss";
import { AppConstants } from "../../helpers/constants";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const Visa = () => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { Option } = Select;
  const [form] = Form.useForm();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const validateMessages = {
    required: "",
  };
  const history = useHistory();
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (value) => {
    let req = {
      ...value,
      VisaEnquiry: 1,
    };

    APIClient.post("admin/addVisaEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          history.push("/Thankyou");
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  return (
    <>
    <SeoMetaData path={window.location.pathname}/>
      <Helmet>
        <title>Visa Information with - {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="Check completes Visa information with holdtrips.co.uk. Explore Visa process, fees, rules & regulation."
        />
      </Helmet>
      <div className="manage-markup-section flight-enquiry-24">
        <div className="container">
          <h5 className="text-center"> Visa Enquiry</h5>
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            initialValues={{ ISDCode: "+91" }}
            validateMessages={validateMessages}
          >
            <Card className="cardRounded">
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Name"
                    name="Name"
                    rules={[
                      { required: true, message: "Please Specify The Name" },
                    ]}
                  >
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: "Please Specify The Email" },
                      { type: "email", message: "Please Enter Valid Email" },
                    ]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Contact Number"
                    name="ContactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify The Contact Number",
                      },
                      {
                        minLength: 10,
                        maxLength: 10,
                        pattern: "^[0-9]{10}$",
                        message: "Must be 10 digits",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Mobile No." />
                  </Form.Item>
                </Col>

                <Col md={12} xs={24}>
                  <Form.Item
                    label="Nationality"
                    name="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify The Nationality",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Nationality" />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Type of Visa"
                    name="TypeofVisa"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify The Type of Visa",
                      },
                    ]}
                  >
                    <Select placeholder="Please select Type of Visa">
                      <Option value={1}>Business</Option>
                      {agent ? (
                        <Option value={2}>Leisure </Option>
                      ) : (
                        <Option value={3}>Vacation </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Purpose of Travel"
                    name="VisitPurpose"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify The Purpose of Travel",
                      },
                    ]}
                  >
                    <Select placeholder="Please select Purpose of Travel">
                      <Option value={1}>Business</Option>

                      {agent ? (
                        <Option value={2}>Leisure </Option>
                      ) : (
                        <Option value={3}>Vacation </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Travel Date"
                    name="TravelDate"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify The Travel Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select Travel Date"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Number of Travellers"
                    name="NumberOfPassengers"
                    rules={[
                      {
                        required: true,
                        message: "Please Specify Number of Travellers",
                      },
                    ]}
                  >
                    <InputNumber
                      className="contacts-value-de"
                      min={1}
                      style={{ width: "100%" }}
                      placeholder="Enter Number of Travellers"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <PaymentSelection/> */}
              <Row justify="center" className="my-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
              </Row>

              <Row justify="center">
                <Space size="middle">
                  <Button
                    disabled={buttonDisabled}
                    type="primary"
                    // onClick={() => form.submit()}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button type="primary" danger>
                    Cancel
                  </Button>
                </Space>
              </Row>
            </Card>
            <PaymentSelection />
            {/* <Row justify="center" className="my-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
              </Row>

              <Row justify="center">
                <Space size="middle">
                  <Button
                    disabled={buttonDisabled}
                    type="primary"
                    // onClick={() => form.submit()}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button type="primary" danger>
                    Cancel
                  </Button>
                </Space>
              </Row> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default Visa;
