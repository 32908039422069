import React from "react";
import "./FlightsCheckout.scss";
import { Card, Col, Row } from "antd";

import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";

import { FlightTimings } from "../SearchResults/Flight/Flight";
import { useAuthContext } from "../../common/providers/AuthProvider";
const DetailsCard = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  let travelType = localStorage.getItem("airTravelType");
  let history = useHistory();

  const goTo = () => {
    history.goBack();
  };

  const FlightCard = ({ flightInfo }) => {
    let fareid = sessionStorage.getItem('FareId');
      let roundtripfareid = sessionStorage.getItem('FareId1');
      let faretype=''
      if(fareid !=undefined && roundtripfareid !=undefined)
      {
      if(flightInfo.isRoundTrip){
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==roundtripfareid)[0].coupanType;
      }
      else{
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==fareid)[0].coupanType;
      }
    }
    return (
      <div className="flight-checkout-card-content">
        <Card>
          <div className="airline-details">
            <Row gutter={[16, 16]}>
              <Col xl={16} lg={14} xs={24}>
                <div className="airline-details-left">
                  <Row gutter={[16, 16]}>
                    <Col lg={12} xs={24}>
                      <div className="airline-img">
                        <img src={flightInfo.airLineLogo} alt="" />
                        <div className="token-number-flight">
                          <p>
                            {flightInfo.airLineName}
                          </p>
                          <p>
                            ({flightInfo.flightSegments[0].marketingAirline}-
                            {flightInfo.flightSegments[0].flightNumber})
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} xs={24}>
                      <div className="airline-src-dest">
                        <p>{flightInfo.origin}</p>
                        <span className="airline-line"></span>
                        {flightInfo.flightSegments.map((segment, index) =>
                          index !== 0 ? <><span className="airline-line"></span><p>{segment.origin}</p></> : null
                        )}
                        <span className="airline-line"></span>
                        <p>
                          {
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].destination
                          }
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={8} lg={10} xs={24}>
                <div className="airline-details-right">
                  <p>
                    <b>Journey Date:</b>{" "}
                    {dateFormat(
                      flightInfo.flightSegments[0].departureDateTime,
                      "dd mmm (ddd)"
                    )}{" "}
                  </p>
                  <p> <b>Stop:</b>{" "}
                    {flightInfo.flightSegments.length === 1 ? (
                      "Direct"
                    ) : (
                      <>
                        {flightInfo.flightSegments.length - 1}
                        stop(s)
                      </>
                    )}
                  </p>
                  {agent ? (
                    <p>
                      Fare Type:{" "}
                      {faretype}
                    </p>
                  ) : null}
                </div>
              </Col>
            </Row>
            {agent ? (    <Row className="airline-row-mobile">
            <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType =="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Either Be Booked OR Hold </p>} </Col>
            </Row>   ) : null}
            {agent ? (    <Row className="airline-row-mobile">
            <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType !="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Only Be Booked But Can't Be Hold </p>} </Col>
            </Row>   ) : null}
          </div>
          <div className="trip-details">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <FlightTimings
                  flightInfo={flightInfo}
                  airSearchData={props.flightSearchObj}
                  fareFamilies={flightInfo.fareFamilies}
                  isSearch ={true}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <div className="flight-checkout-card-head">
      <h3 className="fcc-head-title">Flight Details</h3>
        <p className="fcc-head-subtitle"
          onClick={() => {
            goTo();
          }}
        >
          Change Flights
        </p>
      </div>

      {props.selectedFlight.length > 0 ? (
        travelType === "oneWay" ? (
          <FlightCard flightInfo={props.selectedFlight[0]} />
        ) : (
          props.selectedFlight.map((flight) => (
            <FlightCard flightInfo={flight} />
          ))
        )
      ) : null}
    </div>
  );
};

export default DetailsCard;
