import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Form, Row, message, Radio, Space } from "antd";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { CloseOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import PayMentSelection from "../../common/PaymentSelection/PaymentSelection"

import { getFlightGrandTotalPrice } from "./flightPriceHelper";

const FlightGrandTotalCard = ({
  airPriceResp,
  PromoNotVisible = false,
  flightSearchObj,
  isInternational,
  isPaymentPage = false,
  IsPaymentSelociton
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [promoform] = Form.useForm();

  const {
    state: {
      otherData: { ConvFee,transactionFee, promoData, selectedInsuranceData, redeemAmount,SelectedoutbondBagges,selectedoutMeal,SelectedInbondBagges,SelectedInbondMeal },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
    AddTransFee
  } = useContext(GlobalStatesContext);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  const [redeemData, setRedeemData] = useState({});
  const [value, setValue] = useState();

  useEffect(() => {
    if (user) {
      getRedeemCoupon(user);
    }
  }, [user]);
  const getRedeemCoupon = (userData) => {
    setRedeemData({});
    let obj = {
      userId: userData?.UserID,
      roleType: userData?.Role?.RoleId,
      membershipId: userData?.Membership,
    };
    APIClient.post("admin/userAmtWithReedemPer", obj)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };
  useEffect(() => {
    getInsuranceByServiceType(1);
  }, []);

  useEffect(() => {
    if (!agent) {
      if(flightSearchObj.airTravelType=="roundTrip")
      {
      AddConvFee(1,1,isInternational);
      }else{
        AddConvFee(1,0,isInternational);
      }
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let flightPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(flightPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    promoform.setFieldsValue({
      promo: code,
    });

    promoform.submit();
  };

  const {
    grandTotal,
    convamount,
    discount,
    insuranceTotal,
    commission,
    redeemTotal,
    tranxFree
  } = getFlightGrandTotalPrice(
    airPriceResp,
    ConvFee,
    promoData,
    flightSearchObj,
    selectedInsuranceData,
    redeemAmount,
    SelectedoutbondBagges,
    selectedoutMeal,SelectedInbondBagges,SelectedInbondMeal,transactionFee,isInternational,
    IsPaymentSelociton
  );
  let Tds =((commission /100)*5).toFixed(2)
  return (
    <>
      <Card>
        {!agent && user?.Role?.RoleLevel !== 3 && (
          <>
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Convenience Fee</p>
              </Col>
              <Col>
                <p>
                  {activeCurrency} {currencyValue(convamount)}
                </p>
              </Col>
            </Row>
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Transaction Fee</p>
              </Col>
              <Col>
                <p>
                  {activeCurrency} {currencyValue(tranxFree)}
                </p>
              </Col>
            </Row>
            {promoData.status && (
              <Row justify={"space-between"} className="grand_total_card_row">
                <Col>
                  <p>Discount</p>
                </Col>
                <Col>
                  <p>
                    - {activeCurrency} {currencyValue(discount)}
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}

        {selectedInsuranceData.status === 1 &&
        selectedInsuranceData?.serviceType === 1 ? (
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Insurance Amount</p>
            </Col>
            <Col>
              <p>
                {activeCurrency} {currencyValue(insuranceTotal)}
              </p>
            </Col>
          </Row>
        ) : null}
       
        <div className="pax-total-price">
          <div className="pax-type">
            <p>Grand Total:</p>
          </div>
          <div className="total">
            <p>{activeCurrency} </p>
            <p className="amount ml-1"> {currencyValue(parseFloat(grandTotal))}</p>
          </div>
        </div>
        {agent ? (
          <>
          <div className="pax-total-price">
            <div className="pax-type">
              <p className="pax-comm">Commission Earned: -</p>
            </div>
            <div className="total">
              <p className="pax-comm">{activeCurrency} </p>
              <p className="amount ml-1 pax-comm">
                {" "}
                {currencyValue(commission)}
              </p>
            </div>
          </div>
          <div className="pax-total-price">
          <div className="pax-type">
            <p className="pax-comm">TDS on Commission: +</p>
          </div>
          <div className="total">
            <p className="pax-comm">{activeCurrency} </p>
            <p className="amount ml-1 pax-comm">
              {" "}
              {currencyValue(((commission /100)*5).toFixed(2))}
            </p>
          </div>
        </div></>
        ) : null}
      </Card>
      {!agent && user?.Role?.RoleLevel !== 3 && !PromoNotVisible ? (
        <div className="promo-card">
          {promoData.status == false ? (
            <Card>
              <div className="promo-card-header">
                <p className="name">Add Promotion Code</p>
              </div>
              <div className="promo-input">
                <Form
                  name="customized_form_controls"
                  layout="inline"
                  form={promoform}
                  onFinish={(d) => {
                    validatePromoCode({
                      ServiceType: 1,
                      Amount: Number(grandTotal),
                      PromoCode: d.promo,
                      UserId: user?.UserID ?? 1,
                    });
                  }}
                >
                  <Form.Item name="promo" className="promo-input-item">
                    <Input
                      className="contacts-value-de"
                      placeholder="Enter Your Promo code"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item className="promo-input-btn">
                    <Button
                      className="apply-promo-value-code"
                      type="primary"
                      htmlType="submit"
                    >
                      Apply
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <div className="promo-cp-coupons mt-3" key={key}>
                      <div className="inline-cp-promo">
                        <Form>
                          <Form.Item>
                            <Radio
                              onClick={() => handleApply(item.Code)}
                            ></Radio>
                          </Form.Item>
                        </Form>
                        <p className="promo-key-cp">{item.Code}</p>
                        {item.DiscountType === 1 ? (
                          <p className="save-cp-offer">
                            Save {item.Discount + " %"}
                          </p>
                        ) : (
                          <p className="save-cp-offer">
                            Save {activeCurrency}&nbsp;
                            {currencyValue(item.Discount)}
                          </p>
                        )}
                      </div>

                      <div className="promo-percentage-cp">
                        <p>{item.Description}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="promo-cp-coupons mt-3">
                  <div className="inline-cp-promo">
                    <p className="promo-key-cp">No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      promoform.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
{ IsPaymentSelociton && isPaymentPage &&  /// payment selection page visible for all user types/ 11-07-2023 Nitin
 
  <PayMentSelection setValue={setValue} value={value} />
        }
      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="flight-cards-details mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {PromoNotVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : PromoNotVisible ? null : (
          <Card className="flight-cards-details mt-3">
            <p className="font-weight-bold mb-0">
              Coupon Wallet: {/* {activeCurrency} */}
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>
            {/* <p className="mb-2">
              You can use {redeemData?.reedemPercentage}% of the Total Amount             
            </p> */}

            <div className="book-pay-btn">
              {" "}
              <Button
                type="primary"
                className=" redeem_btn"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
    </>
  );
};

export default FlightGrandTotalCard;
